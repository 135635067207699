import React, { memo, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { MultimediaWidgetForm, MultimediaWidgetFormData } from './MultimediaBlock';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';
import { FormInput } from '@core/components/form';
import { Button } from '@core/components/Button';
import { useFormConfig } from '@core/utils/form';
import { useModalInstance } from '@core/components/ModalStack';
export interface MultimediaBlockSettingsProps {
  form: MultimediaWidgetForm;
}

export const MultimediaBlockSettings = memo((props: MultimediaBlockSettingsProps) => {
  const { form } = props;
  const formValue = useNonNilObservable(form.value$);
  const modal = useModalInstance();
  const onSubmit = useCallback(
    (data: MultimediaWidgetFormData) => {
      return UploadAPI.video(data.url).pipe(
        tap(res => {
          form.patchValue({ config: res });
          modal.close();
        }),
      );
    },
    [form, modal],
  );

  useFormConfig(form, { onSubmit });

  return (
    <div>
      <FormInput control={form.controls.url} />
      <Button extraClassNames="mb-2 mt-2" onClick={() => form.submit()}>
        Save
      </Button>
    </div>
  );
});
