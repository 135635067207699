import { FormArray, FormControl, FormGroup, Control, FormGroupData } from '@core/utils/form';
import { useNonNilObservable, useObservable } from '@core/utils/hooks/rxjs';
import { R } from '@core/utils/r';
import { useLazyEffect } from '@core/utils/react';
import { SortableList } from '@modules/common/Sortable';
import React, { Fragment, useCallback, useState } from 'react';
import { SortableElement, SortableHandle, SortEnd } from 'react-sortable-hoc';

interface FormArrayItemProps {
  onRemove(): void;
  onSubmit?(): void;
  render: (isEdit: boolean) => React.ReactNode;
  initialEdit: boolean;
}

function FormArrayItem<T>(props: FormArrayItemProps) {
  const DragHanle = SortableHandle(() => <i className="fas fa-grip-vertical mr-2" />);
  const [isEdit, setIsEdit] = useState(props.initialEdit);

  return (
    <div className="d-flex flex-direction-row justify-items-center align-items-center mb-2">
      <DragHanle />
      <div style={{ flex: 1 }}>{props.render(isEdit)}</div>
      {isEdit && props.onSubmit && (
        <a
          className="ml-2 font-size-16"
          onClick={() => {
            setIsEdit(value => !value);
            props.onSubmit!();
          }}
        >
          <i className="bx bx-save"></i>
        </a>
      )}
      {!isEdit && (
        <a className="ml-2 font-size-16" onClick={() => setIsEdit(value => !value)}>
          <i className="bx bx-edit"></i>
        </a>
      )}

      <a className="text-danger ml-2 font-size-16" onClick={props.onRemove}>
        <i className="bx bx-x"></i>
      </a>
    </div>
  );
}

const SortableFormArrayItem = SortableElement(FormArrayItem);

export interface FormArrayInputBaseProps {
  label?: string;
  onSubmit?(): void;
  alwaysEditable?: boolean;
}
export interface FormArrayInputProps<T> extends FormArrayInputBaseProps {
  form: FormArray<FormControl<T>>;
  onControl(control: FormControl<T>, index: number): React.ReactNode;
  onLabel(value: T | null, index: number): React.ReactNode;
}

export function FormArrayInput<T>(props: FormArrayInputProps<T>) {
  const { form, onControl, onLabel, onSubmit, alwaysEditable } = props;
  const items = useNonNilObservable(form.items$);
  const values = useObservable(form.value$, []);

  const onAdd = useCallback(() => {
    form.push();
  }, [form]);

  const onRemove = useCallback(
    (index: number) => {
      form.removeAt(index);
      onSubmit && onSubmit();
    },
    [form, onSubmit],
  );

  const onSortEnd = useCallback(
    (sort: SortEnd) => {
      const newArray = R.move(values, sort.oldIndex, sort.newIndex);
      form.setValue(newArray);
      onSubmit && onSubmit();
    },
    [form, values, onSubmit],
  );

  return (
    <Fragment>
      <div className="d-flex" style={{ flex: 1, flexDirection: 'column' }}>
        <SortableList lockAxis="y" distance={2} onSortEnd={sort => onSortEnd(sort)} useDragHandle={true} helperClass="z5000">
          {items.map((item, index) => {
            return (
              <SortableFormArrayItem
                index={index}
                key={index}
                onRemove={() => onRemove(index)}
                initialEdit={alwaysEditable || R.isEmpty(item.value)}
                onSubmit={onSubmit}
                render={isEdit => (isEdit || R.isEmpty(item.value) ? onControl(item, index) : onLabel(item.value, index))}
              />
            );
          })}
        </SortableList>
        <div className="d-flex align-items-center mt-2" style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <a className="btn btn-sm btn-outline-primary" onClick={onAdd}>
            <i className="bx bx-plus"></i> {props.label}
          </a>
        </div>
      </div>
    </Fragment>
  );
}

export interface FormArrayInputGroupProps<T extends Record<string, Control<any>>> extends FormArrayInputBaseProps {
  form: FormArray<FormGroup<T>>;
  onControl(control: FormGroup<T>, index: number): React.ReactNode;
  onLabel(value: FormGroupData<T> | null, index: number): React.ReactNode;
}

export function FormArrayInputGroup<T extends Record<string, Control<any>>>(props: FormArrayInputGroupProps<T>) {
  const { form, onControl, onLabel, onSubmit, alwaysEditable } = props;
  const items = useNonNilObservable(form.items$);
  const values = useObservable(form.value$, []);

  const onAdd = useCallback(() => {
    form.push();
  }, [form]);

  const onRemove = useCallback(
    (index: number) => {
      form.removeAt(index);
      onSubmit && onSubmit();
    },
    [form, onSubmit],
  );

  const onSortEnd = useCallback(
    (sort: SortEnd) => {
      const newArray = R.move(values, sort.oldIndex, sort.newIndex);
      form.setValue(newArray);
      onSubmit && onSubmit();
    },
    [form, values, onSubmit],
  );

  return (
    <Fragment>
      <div className="d-flex noselect" style={{ flex: 1, flexDirection: 'column' }}>
        <SortableList lockAxis="y" distance={2} onSortEnd={sort => onSortEnd(sort)} useDragHandle={true} helperClass="z5000">
          {items.map((item, index) => {
            return (
              <SortableFormArrayItem
                index={index}
                key={index}
                onRemove={() => onRemove(index)}
                initialEdit={alwaysEditable || R.isEmpty(item.value)}
                onSubmit={onSubmit}
                render={isEdit => (isEdit || R.isEmpty(item.value) ? onControl(item, index) : onLabel(item.value, index))}
              />
            );
          })}
        </SortableList>
        <div className="d-flex align-items-center mt-2" style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <a className="btn btn-sm btn-outline-primary" onClick={onAdd}>
            <i className="bx bx-plus"></i> {props.label}
          </a>
        </div>
      </div>
    </Fragment>
  );
}
