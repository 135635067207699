import React, { memo, createContext, PropsWithChildren, useContext, Suspense } from 'react';
import { History, createBrowserHistory } from 'history';
import { Router as ReactRouter } from 'react-router-dom';
import { useDidMount, useWillUnmount } from '@core/utils/hooks/react';
import { Spinner } from '../../boot/Spinner';

export const createHistory = () => createBrowserHistory();

const HistoryContext = createContext<History | null>(null);

export const HistoryProvider = memo((props: PropsWithChildren<{ history: History }>) => {
  return <HistoryContext.Provider value={props.history}>{props.children}</HistoryContext.Provider>;
});

export function useHistory() {
  const history = useContext(HistoryContext);

  if (!history) throw new Error('[useHistory] no history provided');
  return history;
}

export const Router = memo((props: PropsWithChildren<{}>) => {
  const history = useHistory();

  return (
    <Suspense fallback={<SpinnerComponent />}>
      <ReactRouter history={history as any}>{props.children}</ReactRouter>
    </Suspense>
  );
});

const SpinnerComponent = memo(() => {
  useDidMount(Spinner.show);
  useWillUnmount(Spinner.hide);

  return null;
});
