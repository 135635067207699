import React, { memo } from 'react';
import { TextBlockForm } from './TextBlock';
import { FormInput, FormColorPicker } from '@core/components/form';

export interface TextBlockSettingsProps {
  form: TextBlockForm;
}

export const TextBlockSettings = memo((props: TextBlockSettingsProps) => {
  const { form } = props;
  return (
    <div>
      <FormInput control={form.controls.columnsWidths} />
      <FormColorPicker control={form.controls.column1Background} />
      <FormColorPicker control={form.controls.column2Background} />
      <FormColorPicker control={form.controls.column3Background} />
      <FormColorPicker control={form.controls.column4Background} />
    </div>
  );
});
