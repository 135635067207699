export enum ActivityModule {
  ACCOUNT = 'account',
  PROJECTS = 'projects',
  CONTACT = 'contacts',
  BILLING = 'billing',
  TICKETING = 'ticketing',
  SUBSCRIPTIONS = 'subscriptions',
  ORDER = 'order',
}

export interface LogLinkResponse {
  text: string;
  link: string;
}

export type LogId = string;
export interface LogResponse {
  logId: LogId;
  createdAt: number;
  createdBy: {
    id: string;
    name: string;
  };

  action: 'create' | 'delete' | 'update';
  type: string;

  title: string;
  details: string;
  bolds: string[];
  links: LogLinkResponse[];

  module: ActivityModule;

  xEntry: any;
}
