import React, { memo, ReactNode } from 'react';
import { css, ClassNames } from '@emotion/react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CssProp, Theme } from '@core/utils/css';
import { useModalInstance } from '@core/components/ModalStack';
import SvgIcon from '@mui/icons-material/Close';
import { Icon } from '@mui/material';
import { DialogTitle } from '@mui/material';

const styles = {
  container: css`
    // padding: 0 !important;
    border-bottom: 2px solid #556ee625;
    // margin-bottom: 16px;
    height: 65px;
    background-color: #fff;
    padding: 0px 10px 0px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: inherit;

    .modal_title {
      flex: 1;
      font-family: 'Poppins', sans-serif;
      color: #495057;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: inherit;
      top: auto;
    }
  `,

  noBorder: css`
    border-bottom: none;
  `,

  customContent: css`
    .MuiTypography-root {
      height: auto;
      align-items: flex-start;
      position: relative;
    }
  `,
};

export interface ModalHeaderProps {
  customCss?: CssProp;
  closeButton?: boolean;
  titleCss?: CssProp;
  title?: ReactNode;
  children?: ReactNode;
  noBorder?: boolean;
  onClose?(): void;
  onMinimize?(): void;
  noClose?: boolean;
  rightContent?: ReactNode;
}

export const ModalHeader = memo((props: ModalHeaderProps) => {
  const modal = useModalInstance();

  const closeButton = props.closeButton !== false && (
    <IconButton
      className="close_button"
      onClick={() => {
        modal.close();
        props.onClose && props.onClose();
      }}
    >
      <CloseIcon />
    </IconButton>
  );

  const minimizeButton = props.closeButton !== false && (
    <IconButton className="close_button" onClick={props.onMinimize}>
      <Icon className="fa fa-minus" />
    </IconButton>
  );

  return (
    <ClassNames>
      {({ css }) => (
        <DialogTitle
          classes={{
            root: css([styles.container, !!props.children && styles.customContent, props.noBorder && styles.noBorder, props.customCss]),
          }}
        >
          <span className="modal_title" css={props.titleCss}>
            {props.title || props.children}
          </span>

          {!!props.rightContent && <div style={{ fontSize: 'initial', fontWeight: 'initial' }}>{props.rightContent}</div>}
          {!props.noClose && closeButton}
          {!!props.onMinimize && minimizeButton}
        </DialogTitle>
      )}
    </ClassNames>
  );
});
