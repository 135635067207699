import { createContext, useContext } from 'react';
import { ProposalForm, ProposalBlockForm } from './ProposalForm';
import { ProposalBuilderPageBloc } from './ProposalBuilderPageBloc';
import { ProposalPreviewPageBloc } from '../ProposalPreviewPage/ProposalPreviewPageBloc';
import { UserResponse } from '@business/entities/account';
import { ContactResponse } from '@business/entities/contact';
import { BrandResponse } from '@business/entities/brand';
import { CompanyResponse } from '@business/entities/companies';

interface ProposalContextType {
  form: ProposalForm;
  currentSection: number;
  bloc: ProposalBuilderPageBloc | ProposalPreviewPageBloc;
  scale: number;
  setScale: (scale: number) => void;
  sidebar: boolean;
  toggleSidebar: () => void;
  //   history: ProposalFormData[];
}

export const ProposalContext = createContext<ProposalContextType | null>(null);

export function useProposalContext() {
  return useContext(ProposalContext);
}

interface ProposalBlockContextType {
  blockForm: ProposalBlockForm;
  blockIndex: number;
}

export const ProposalBlockContext = createContext<ProposalBlockContextType | null>(null);

export function useProposalBlockContext() {
  return useContext(ProposalBlockContext);
}

export interface ProposalDataType {
  user?: UserResponse | null;
  contact?: ContactResponse;
  company?: CompanyResponse;
  brand?: BrandResponse;
  link: string;
  dateSumitted: number;
  dateAccepted?: number;
  total: number;
  signee?: any;
  senders?: any[];
}

export const ProposalDataContext = createContext<ProposalDataType | null>(null);

export function useProposalDataContext() {
  return useContext(ProposalDataContext);
}
