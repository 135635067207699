import { R } from '@core/utils/r';
import { Endpoint } from '@business/api/endpoint';
import {
  HelpArticleResponse,
  HelpArticleId,
  HelpArticleRequest,
  HelpArticleCategoryRequest,
  HelpArticleCategoryResponse,
  HelpArticleTagResponse,
  ArticleCategoryId,
  HACategoryResponse,
  SearchHelpArticleResponse,
} from '@business/entities/helpArticle';

export function fetchHelpArticles(id: string, options: { search?: string } = {}) {
  const params = R.objectCompact({ search: options.search });
  return Endpoint.get<HelpArticleResponse[]>(`@gc/help-articles/${id}`, { params });
}

export function createHelpArticle(data: HelpArticleRequest) {
  return Endpoint.post<HelpArticleResponse>(`@gc/help-articles`, { data });
}

export function updateHelpArticle(helpArticleId: HelpArticleId, data: HelpArticleRequest) {
  return Endpoint.patch<HelpArticleResponse>(`@gc/help-articles/${helpArticleId}`, { data });
}

export function deleteHelpArticle(helpArticleId: HelpArticleId) {
  return Endpoint.delete<void>(`@gc/help-articles/${helpArticleId}`);
}

export function fetchHelpArticle(helpArticleId: HelpArticleId) {
  return Endpoint.get<HelpArticleResponse>(`@gc/help-articles/help-article/${helpArticleId}`);
}

// Categories

export function fetchHelpArticleCategories(categoryId: ArticleCategoryId, options: { search?: string } = {}) {
  const params = R.objectCompact({ categoryId, search: options.search });
  return Endpoint.get<HelpArticleCategoryResponse>(`@gc/help-articles/categories`, { params });
}

export function createHelpArticleCategory(data: HelpArticleCategoryRequest) {
  return Endpoint.post<HACategoryResponse>(`@gc/help-articles/category`, { data });
}

export function updateHelpArticleCategory(id: ArticleCategoryId, data: HelpArticleCategoryRequest) {
  return Endpoint.patch<HACategoryResponse>(`@gc/help-articles/category/${id}`, { data });
}

export function deleteHelpArticleCategory(id: ArticleCategoryId) {
  return Endpoint.delete<void>(`@gc/help-articles/category/${id}`);
}

export function fetchHelpArticleCategory(id: ArticleCategoryId) {
  return Endpoint.get<HACategoryResponse>(`@gc/help-articles/category/${id}`);
}

// Tags

export function fetchHelpArticleTags(options: { search?: string } = {}) {
  return Endpoint.get<HelpArticleTagResponse[]>(`@gc/help-articles/tags`);
}

export function createHelpArticleTag(data: Partial<HelpArticleTagResponse>) {
  return Endpoint.post<HelpArticleTagResponse>(`@gc/help-articles/tag`, { data });
}

export function searchHelpArticles(search: string) {
  return Endpoint.get<SearchHelpArticleResponse[]>(`@gc/help-articles/search-articles`, { params: { search } });
}

export function search(search: string) {
  return Endpoint.get<HelpArticleResponse[]>(`@gc/help-articles/search`, { params: { search } });
}
