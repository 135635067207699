import { openModal } from '@core/components/ModalStack';
import { ProposalProductsOverlayProps } from './ProposalProductsOverlay';

export async function openProposalProductsOverlay(props: ProposalProductsOverlayProps) {
  const { ProposalProductsOverlay } = await import('./ProposalProductsOverlay');

  openModal(ProposalProductsOverlay, props);
}

export * from './ProposalProductsForm';
