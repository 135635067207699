import { CustomerAPI } from '@business/api/customer_api';
import { PageQuery } from '@business/entities/common';
import { CreateCustomerClientRequest, CustomerClientResponse, CustomerId, CustomerResponse } from '@business/entities/customer';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { switchMap } from 'rxjs/operators';
export class CustomerClientsBloc extends BaseBloc {
  private readonly customerClientRepo = new Repository<CustomerClientResponse>({
    getItemId: item => item.id,
    paginated: true,
  });

  readonly items$ = this.customerClientRepo.items$;
  readonly selectItem = this.customerClientRepo.selectItem;

  onReset() {
    this.customerClientRepo.reset();
  }

  fetchCustomerClients = (query: PageQuery, customerId: CustomerId) => {
    return CustomerAPI.fetchCustomerClients(query, customerId);
  };

  fetchAvailableClients = (customerId: CustomerId) => {
    return CustomerAPI.fetchAvailableCustomers(customerId)
  };

  createCustomerClients = (data: CreateCustomerClientRequest, customerId: CustomerId) => {
    return CustomerAPI.createCustomerClients(data, customerId).pipe(
      this.customerClientRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_clients'),
      })
    );
  };

  deleteCustomerClient = (id: CustomerId) => {
    return showDeleteConfirmation('Delete Client', 'Do you really want to delete this client?', { btnLabel: 'Yes, Delete' }).pipe(
      switchMap(() => CustomerAPI.deleteCustomerClient(id)),
      this.customerClientRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_client'),
      })
    );
  };
}
