import React, { memo, useCallback, useState, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { GalleryWidgetForm } from './GalleryBlock';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';
import { Card, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import classnames from 'classnames';
import { openGenericInputModal } from '@modules/common/GenericInputModal';
import { LoadingIndicator } from '@core/components/LoadingIndicator';
import { SortableList } from '@modules/common/Sortable';
import { SortableElement, SortEnd } from 'react-sortable-hoc';
import { R } from '@core/utils/r';
import { CommonService } from '@business/services';
import { MoreMenu, MoreMenuItem } from '@modules/common/MoreMenu';
import { FormCheckbox } from '@core/components/form';
import { ImagePickerSettings } from '@modules/proposal/ImagePickerSettings';
import { openImageFinderModal } from '@modules/ImageFinder';
export interface GalleryBlockSettingsProps {
  form: GalleryWidgetForm;
}

export const GalleryBlockSettings = memo((props: GalleryBlockSettingsProps) => {
  const { form } = props;
  const formValue = useNonNilObservable(form.value$);
  const [hovered, setHovered] = useState(-1);
  const itemValues = useNonNilObservable(form.controls.items.items$);
  const [replacing, setReplacing] = useState<boolean>(false);
  const [replaceFileIndex, setReplaceFileIndex] = useState<number>(0);

  const removeImage = useCallback(
    (index: number) => {
      const filteredFiles = formValue.items.filter((_, i) => i !== index);
      form.patchValue({ items: filteredFiles });
    },
    [form, formValue.items],
  );

  const replaceImage = useCallback(
    (files: FileList, index: number) => {
      if (files.length == 0) return null;
      setReplacing(true);
      setReplaceFileIndex(index);
      const file = files[0];
      UploadAPI.upload('proposal/images', file)
        .pipe(
          tap(res => {
            return form.patchValue({
              items: itemValues.map((q, i) => {
                if (index === i)
                  return {
                    ...q.value,
                    image: res.fileUrl,
                  };
                else return q.value;
              }),
            });
          }),
          tap(() => {
            setReplacing(false);
          }),
        )
        .subscribe();
    },
    [form, itemValues],
  );

  const replaceImageFromUrl = useCallback(
    (url: string, index: number) => {
      setReplacing(true);
      setReplaceFileIndex(index);
      form.patchValue({
        items: itemValues.map((q, i) => {
          if (index === i)
            return {
              ...q.value,
              image: url,
            };
          else return q.value;
        }),
      });
      setReplacing(false);
    },
    [form, itemValues],
  );

  const updateAltTag = useCallback(
    (index: number, text: string) => {
      form.patchValue({
        items: itemValues.map((q, i) => {
          if (index === i)
            return {
              ...q.value,
              text: text,
            };
          else return q.value;
        }),
      });
    },
    [form, itemValues],
  );

  const editAltTag = useCallback(
    (index: number, text: string) => {
      openGenericInputModal({
        title: 'Image Alt text',
        label: 'Alt text',
        button: 'OK',
        initialValue: text,
        onSubmit: text => updateAltTag(index, text),
      });
    },
    [updateAltTag],
  );

  const onSortEnd = useCallback(
    (sort: SortEnd) => {
      const oldIndex = sort.oldIndex;
      const newIndex = sort.newIndex;
      const ids = itemValues.map(s => s);
      const sorted = R.move(ids, oldIndex, newIndex);
      form.patchValue({
        items: sorted.map((q, i) => {
          return {
            ...q.value,
            menuOrder: i,
          };
        }),
      });
    },
    [form, itemValues],
  );

  return (
    <div>
      <FormCheckbox control={form.controls.captions} />
      <ImagePickerSettings onSelect={image => form.controls.items.push().patchValue({ image })} />

      <SortableList lockAxis="y" distance={2} onSortEnd={sort => onSortEnd(sort)}>
        {formValue.items.map((obj, i) => (
          <SortableGalleryItem
            key={i}
            index={i}
            ind={i}
            item={obj}
            isHover={i === hovered}
            removeImage={removeImage}
            replaceImage={replaceImage}
            replaceImageFromUrl={replaceImageFromUrl}
            editAltTag={editAltTag}
            replacing={replacing}
            replaceFileIndex={replaceFileIndex}
          />
        ))}
      </SortableList>
    </div>
  );
});

interface MediaFileLineItemProps {
  item: any;
  ind: number;
  isHover?: boolean;
  removeImage(index: number): void;
  replaceImage(files: FileList, index: number): void;
  replaceImageFromUrl(url: string, index: number): void;
  editAltTag(index: number, text: string): void;
  replacing: boolean;
  replaceFileIndex: number;
}

export const MediaFileLineItem = memo((props: MediaFileLineItemProps) => {
  const { ind, item, removeImage, replaceImage, replaceImageFromUrl, editAltTag, replacing, replaceFileIndex } = props;
  const [isHover, setHover] = useState(false);

  const menuItems: MoreMenuItem[] = useMemo(
    () => [
      {
        icon: 'fa fa-cloud-upload-alt',
        title: 'Replace',
        onClick: () => {
          CommonService.chooseImage()
            .pipe(tap(images => replaceImage(images, ind)))
            .subscribe();
        },
      },
      {
        icon: 'fa fa-image',
        title: 'Pick from Library',
        onClick: () => {
          openImageFinderModal({ onSelect: image => replaceImageFromUrl(image, ind) });
        },
      },
      { title: '', divider: true },
      { icon: 'fa fa-pencil-alt', title: 'Edit Alt Tag', onClick: () => editAltTag(ind, item.text) },
      { icon: 'fa fa-trash-alt', className: 'text-danger', title: 'Remove', onClick: () => removeImage(ind) },
    ],
    [editAltTag, ind, item.text, removeImage, replaceImage, replaceImageFromUrl],
  );
  const isReplacingImage = useMemo(() => replacing && ind === replaceFileIndex, [ind, replaceFileIndex, replacing]);

  return (
    <>
      <div className="mt-1" key={'file'} style={{ cursor: 'move' }}>
        <Card
          className={classnames(isHover || props.isHover ? 'search-item-hover' : '')}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className="d-flex flex-row align-items-center">
            <div className="d-flex flex-row flex-fill" style={{ padding: '0.25rem' }}>
              <div className="mr-4 align-items-center text-muted justify-content-center d-flex">
                {isHover && <i className="fa fa-ellipsis-v hover-pointer" style={{ fontSize: 22 }}></i>}
              </div>
              <div style={{ width: '95%', marginRight: 10 }}>
                {!isReplacingImage && <img src={item.image} style={{ width: 100, height: 100, objectFit: 'contain' }} />}
                {isReplacingImage && (
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img src={item.image} style={{ width: 100, height: 100, objectFit: 'contain', opacity: '0.2' }} />
                    {isReplacingImage && <LoadingIndicator style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '12', top: 0 }} />}
                  </div>
                )}

                <div className="text-muted">{item.text}</div>
              </div>
            </div>
            <div className="d-flex">
              <MoreMenu items={menuItems} vertical />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
});

export const SortableGalleryItem = SortableElement(MediaFileLineItem);
