import React, { memo, useMemo, useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';

export interface MoreMenuItem {
  title: string;
  divider?: boolean;
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
  visible?: boolean;
  className?: string;
}

export interface MoreMenuProps extends React.HTMLProps<HTMLDivElement> {
  vertical?: boolean;
  items: MoreMenuItem[];
}

export const MoreMenu = memo((props: MoreMenuProps) => {
  const { items, vertical = false, ...rest } = props;
  const filtertedItems = useMemo(() => items.filter(i => i.visible !== false), [items]);
  const [isOpen, setOpen] = useState(false);
  return (
    <div {...rest}>
      <Dropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
        <DropdownToggle tag="span" className="font-size-16 d-flex justify-content-center align-items-center" onClick={e => e.stopPropagation()}>
          {!vertical && <i className="bx bx-dots-horizontal-rounded"></i>}
          {vertical && <i className="bx bx-dots-vertical-rounded"></i>}
        </DropdownToggle>

        <DropdownMenu container="body" className="dd-menu_outside">
          {filtertedItems.map((item, index) => {
            if (item.divider) return <div key={index} className="dropdown-divider"></div>;
            return (
              <a
                key={index}
                onClick={() => {
                  setOpen(false);
                  item.onClick && item.onClick();
                }}
                className={`dropdown-item ${item.disabled ? 'disabled' : ''} ${item.className}`}
              >
                {item.icon && <i className={`${item.icon} mr-2`}></i>}
                {item.title}
              </a>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
});
