import { keyframes } from '@emotion/react';

export const Animations = {
  fadein: keyframes`
    from {
      opacity: 0.05;
    }
    to {
      opacity: 1;
    }
  `,
};
