import { ContactResponse } from '@business/entities/contact';
import { DealResponse } from '@business/entities/deal';
import { OrgResponse } from '@business/entities/organization';
import { ProjectResponse } from '@business/entities/project';
import { R } from '@core/utils/r';
import React, { createContext, DependencyList, memo, useContext, useEffect, useRef, useState } from 'react';

export enum PCType {
  organization,
  ticket,
  contact,
  project,
  deal,
  marketingBoard,
}

export interface PageContextInfo {
  type: PCType;
  object: OrgResponse | ContactResponse | ProjectResponse | DealResponse;
}

interface PageContextType {
  set(info: PageContextInfo | undefined): void;
  current?: PageContextInfo;
}

const PageContextIn = createContext<PageContextType>({ set: () => R.noop() });

export function usePageInfoContext() {
  return useContext(PageContextIn);
}

export function usePageContext(fn: () => PageContextInfo | undefined, deps: DependencyList = []) {
  const fnRef = useRef(fn);
  const context = usePageInfoContext();
  fnRef.current = fn;

  useEffect(() => {
    if (fnRef.current) {
      context.set(fnRef.current());
    }
    return () => context.set(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export const PageContext = memo(({ children }: any) => {
  const [info, setInfo] = useState<PageContextInfo | undefined>(undefined);

  return <PageContextIn.Provider value={{ current: info, set: setInfo }}>{children}</PageContextIn.Provider>;
});
