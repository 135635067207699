import React, { memo, useMemo, useCallback, DragEvent } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import classNames from 'classnames';
import { formGroup, formControl, FormDataType, formArray } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable, useObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { GalleryBlockSettings } from './GalleryBlockSettings';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';
import { Container } from 'reactstrap';
import { FormText } from '@core/components/form/FormText';
import { useDidMount } from '@core/utils/hooks/react';
import { DropableImage } from '@modules/common/DropableImage';

function defineGalleryBlockItemForm() {
  return formGroup({
    image: formControl<string>({
      initialValue: 'https://startuppakistan.com.pk/wp-content/uploads/2022/02/best-bicycle-696x392.jpg',
    }),
    text: formControl<string>({
      initialValue: 'Add Caption',
    }),
    menuOrder: formControl<number>({
      initialValue: 0,
    }),
  });
}

function defineGalleryBlockForm() {
  return formGroup({
    items: formArray(() => defineGalleryBlockItemForm()),
    captions: formControl<boolean>({
      initialValue: true,
      label: 'Image Captions',
    }),
    advance: defineBlockAdvanceForm(),
  });
}

export type GalleryWidgetFormData = FormDataType<typeof defineGalleryBlockForm>;
export type GalleryWidgetForm = ReturnType<typeof defineGalleryBlockForm>;

export enum GalleryBlockVariation {
  carousel = 'carousel',
  twoColumnGrid = 'twoColumnGrid',
  threeColumnGrid = 'threeColumnGrid',
  fourColumnGrid = 'fourColumnGrid',
}

const columnMap: { [key in GalleryBlockVariation]: number } = {
  [GalleryBlockVariation.carousel]: 1,
  [GalleryBlockVariation.twoColumnGrid]: 2,
  [GalleryBlockVariation.threeColumnGrid]: 3,
  [GalleryBlockVariation.fourColumnGrid]: 4,
};

export const GalleryBlock = memo((props: Proposal.BlockProps<GalleryBlockVariation>) => {
  const { variation, preview, editable } = props;
  const columns = columnMap[variation];
  const form = useMemo(defineGalleryBlockForm, []);
  const formValue = useNonNilObservable(form.value$);
  const itemValues = useObservable(form.controls.items.items$, []);

  useDidMount(() => {
    for (let i = 0; i < columns; i++) {
      form.controls.items.push().patchValue({ menuOrder: i });
    }
  });
  useConfigureBlock({
    props,
    form,
    contentComponent: GalleryBlockSettings,
    contentProps: { form },
  });

  const onDropImage = useCallback(
    (file: File) => {
      UploadAPI.upload('proposal/images', file)
        .pipe(tap(res => form.controls.items.push().patchValue({ image: res.fileUrl })))
        .subscribe();
    },
    [form],
  );

  return (
    <BlockContainer className={classNames('block-image', variation, preview)} advanceForm={formValue.advance}>
      <Container>
        <div
          className={`cols-${columns}`}
          style={{ display: 'grid ', gridTemplateColumns: Array(columns + 1).join('1fr '), columnGap: '1em', rowGap: '2em' }}
        >
          {itemValues.map((ctrl, i) => (
            <div className="image-centered" key={i}>
              <DropableImage key={i} onDropImage={onDropImage} src={ctrl.value.image} />
              {formValue.captions && <FormText control={ctrl.controls.text} editable={editable} />}
            </div>
          ))}
        </div>
      </Container>
    </BlockContainer>
  );
});
