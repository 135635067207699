import React, { memo, useMemo, useCallback, DragEvent } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import classNames from 'classnames';
import { formGroup, formControl, FormDataType } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { FormText } from '@core/components/form/FormText';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { QuoteBlockSettings } from './QuoteBlockSettings';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';
import { FormHTMLInline } from '@core/components/form';
import { DropableImage } from '@modules/common/DropableImage';
import { useProposalDataContext } from '@modules/proposal/ProposalBuilderPage';
import { ProposalService } from '@business/services';

function defineQuoteWidgetForm() {
  return formGroup({
    image: formControl<string>({
      initialValue: 'https://unsplash.com/photos/iEEBWgY_6lA/download?ixid=Mnw0MTYxMTh8MHwxfHNlYXJjaHwxfHx1c2VyfGVufDB8fHx8MTY3ODk5NzU4MQ',
    }),
    paragraph: formControl<string>({
      initialValue:
        'Quam nisi convallis imperdiet porta venenatis ultricies litora netus in pellentesque vulputate laoreet, integer varius metus velit conubia leo purus egestas curabitur cubilia.',
    }),
    background: formControl<string>({
      initialValue: 'https://startuppakistan.com.pk/wp-content/uploads/2022/02/best-bicycle-696x392.jpg',
    }),
    size: formControl<'contain' | 'cover' | 'fill'>({ label: 'Image Size', initialValue: 'cover' }),
    advance: defineBlockAdvanceForm(),
  });
}

export type QuoteBlockFormData = ReturnType<typeof defineQuoteWidgetForm>;
export type QuoteBlockForm = ReturnType<typeof defineQuoteWidgetForm>;

export enum QuoteBlockVariation {
  quoteA = 'quoteA',
  quoteB = 'quoteB',
  quoteC = 'quoteC',
  quoteD = 'quoteD',
  quoteOnImage = 'quoteOnImage',
}

export const QuoteBlock = memo((props: Proposal.BlockProps<QuoteBlockVariation>) => {
  const { variation, editable, preview } = props;
  const form = useMemo(defineQuoteWidgetForm, []);
  const formValue = useNonNilObservable(form.value$);
  const data = useProposalDataContext();

  useConfigureBlock({
    props,
    form,
    contentComponent: QuoteBlockSettings,
    contentProps: { form, variation },
  });

  const onDropImage = useCallback(
    (file: File) => {
      UploadAPI.upload('proposal/images', file)
        .pipe(tap(res => form.patchValue({ image: res.fileUrl })))
        .subscribe();
    },
    [form],
  );
  return (
    <BlockContainer className={`block-container d-flex flex-fill block-quote block-${variation}`} advanceForm={formValue.advance}>
      {variation == QuoteBlockVariation.quoteOnImage && (
        <div className="background-image">
          <DropableImage onDropImage={onDropImage} style={{ objectFit: formValue.size }} src={formValue.background} />
        </div>
      )}
      <div
        className={`${variation === QuoteBlockVariation.quoteD ? variation : ' container'}`}
        style={{ backgroundColor: formValue.advance.backgroundColor }}
      >
        <div className={`${variation === QuoteBlockVariation.quoteD ? 'container d-flex align-item-center' : variation}`}>
          <div className={`image-container-${variation}`}>
            <DropableImage onDropImage={onDropImage} style={{ objectFit: formValue.size }} src={formValue.image} />
          </div>
          <div className={`text-container-${variation}`}>
            <FormHTMLInline
              control={form.controls.paragraph}
              readonly={!editable}
              className={`para-${variation}`}
              transformedFieldsValue={ProposalService.getTransformedValues(data)}
            />
          </div>
        </div>
      </div>
    </BlockContainer>
  );
});
