import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';
import { handleMessage, requestMessage } from '@business/messages';
import { showDeleteConfirmation } from '@modules/common';
import { map, switchMap } from 'rxjs/operators';
import { HelpArticleId, HelpArticleCategoryRequest, ArticleCategoryId, BreadcrumbResponse, HACategoryResponse } from '@business/entities/helpArticle';
import { HelpArticleAPI } from '@business/api/helpArticle';
import { BehaviorSubject } from 'rxjs';

export class HelpArticleCategoryBloc extends BaseBloc {
  private readonly helpArticleCategoryRepo = new Repository<HACategoryResponse>({
    getItemId: c => c.id,
  });

  readonly helpArticleCategories$ = this.helpArticleCategoryRepo.items$;
  readonly selectHelpArticleCategory = this.helpArticleCategoryRepo.selectItem;

  onReset() {
    this.helpArticleCategoryRepo.reset();
  }

  readonly breadcrumb$ = new BehaviorSubject<BreadcrumbResponse[]>([]);

  fetchHelpArticleCategories = (categoryId: ArticleCategoryId) => {
    return HelpArticleAPI.fetchHelpArticleCategories(categoryId).pipe(
      map(data => {
        this.breadcrumb$.next([...data.breadcrumbs]);
        return data.categories;
      }),
      this.helpArticleCategoryRepo.ops.reset(),
      handleMessage({ error: requestMessage('fetch_help_article_categories_error') }),
    );
  };

  // fetchHelpArticleCategories = (categoryId: ArticleCategoryId) => {
  //   return HelpArticleAPI.fetchHelpArticleCategories(categoryId).pipe(
  //     this.helpArticleCategoryRepo.ops.reset(),
  //     handleMessage({ error: requestMessage('fetch_help_article_categories_error') }),
  //   );
  // };

  fetchHelpArticleCategory = (id: ArticleCategoryId) => {
    return HelpArticleAPI.fetchHelpArticleCategory(id).pipe(
      this.helpArticleCategoryRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_help_article_categories_error') }),
    );
  };

  createHelpArticleCategory = (helpArticle: HelpArticleCategoryRequest) => {
    return HelpArticleAPI.createHelpArticleCategory(helpArticle).pipe(
      this.helpArticleCategoryRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_help_article_category'),
      }),
    );
  };

  updateHelpArticleCategory = (id: HelpArticleId, helpArticle: HelpArticleCategoryRequest) => {
    return HelpArticleAPI.updateHelpArticleCategory(id, helpArticle).pipe(
      this.helpArticleCategoryRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_help_article_category'),
      }),
    );
  };

  deleteHelpArticleCategory = (id: HelpArticleId) => {
    return showDeleteConfirmation('Delete Category', 'Do you really want to remove this help category?').pipe(
      switchMap(() => HelpArticleAPI.deleteHelpArticleCategory(id)),
      this.helpArticleCategoryRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_help_article_category'),
      }),
    );
  };
}
