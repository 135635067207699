import { ApexOptions } from 'apexcharts';
import { EChartsReactProps } from 'echarts-for-react';
import { Formatter } from '@core/utils/formatter';

const dummaryData = {
  labels: ['USA', 'France', 'UK', 'Spain'],
  values: [9, 12, 15, 18],
};

function getValues(values: number[]) {
  return values.length ? values : dummaryData.values;
}

function getLabels(labels: string[]) {
  return labels.length ? labels : dummaryData.labels;
}

function getBoth(labels: string[], values: number[]) {
  return labels.map((name, index) => ({ name, value: values[index] }));
}

export function getBarChartProps(
  labels: string[],
  values: number[],
  primaryColor: string,
  heading?: string,
): { series: ApexOptions['series']; options: ApexOptions } {
  return {
    series: [{ data: getValues(values) }],
    options: {
      title: {
        text: heading || '',
        align: 'left',
      },
      chart: {
        toolbar: {
          show: false,
        },
        height: 450,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: getLabels(labels),
      xaxis: {
        categories: getLabels(labels),
      },
      fill: {
        colors: [primaryColor],
      },
    },
  };
}

export function getLineChartProps(labels: string[], values: number[], primaryColor: string, heading?: string): EChartsReactProps['option'] {
  return {
    title: {
      text: heading || '',
    },

    xAxis: {
      type: 'category',
      data: getLabels(labels),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: getValues(values),
        type: 'line',
        smooth: false,
        lineStyle: {
          normal: {
            color: primaryColor,
          },
        },
        label: {
          show: true,
          position: 'top',
        },
      },
    ],
  };
}

export function getPieChartProps(labels: string[], values: number[], primaryColor: string, heading?: string): EChartsReactProps['option'] {
  return {
    title: {
      text: heading || '',
    },
    tooltip: {
      trigger: 'item',
      formatter: (data: any) => data.name + ': ' + Formatter.formatInteger(data.value) + ' (' + data.percent + '%)',
    },
    series: [
      {
        label: {
          formatter: (data: any) => `${data.name} (${data.percent}%)`,
        },
        type: 'pie',
        avoidLabelOverlap: false,
        selectedMode: 'multiple',
        labelLine: {
          normal: {
            show: true,
            smooth: false,
            length: 10,
            length2: 10,
          },
        },
        data: getBoth(labels, values),
      },
    ],
  };
}
