import React, { memo, useMemo } from 'react';
import { PopperAnchor, Popper } from '@core/components/Popper';
import { BlockPreview } from '../BlockPreview';
import { Proposal } from '../../types';
import { classnames } from '@core/utils/css';
import { R } from '@core/utils/r';
import { ProposalBlockContext, defineProposalBlockForm } from '@modules/proposal/ProposalBuilderPage';

export interface VariationSelectorProps {
  anchor: PopperAnchor<HTMLElement>;
  config: Proposal.BlockConfig;
  variation: string;
  onVariationChange: (variation: string) => void;
}

export const VariationSelector = memo((props: VariationSelectorProps) => {
  const { anchor, config, onVariationChange } = props;
  const otherVariations = useMemo(() => config.variations.filter(v => v !== props.variation), [config, props.variation]);
  const [selected, setSelected] = React.useState<string | null>(otherVariations[0]);
  return (
    <Popper anchor={anchor} placement="bottom" autoClose="none" aboveModal offset={'60,5'}>
      <div className="poper-modal no-drag" style={{ width: 500, padding: 0, backgroundColor: 'transparent' }}>
        <div className="awo-container">
          <div className="awo-categories" style={{ width: 150 }}>
            {otherVariations.map(v => (
              <div
                key={v}
                onMouseOver={() => setSelected(v)}
                className={classnames('awo-category-item', 'small', { selected: selected === v })}
                onClick={() => {
                  onVariationChange(v);
                  anchor.onClose();
                }}
              >
                <a>{R.startCase(v)}</a>
              </div>
            ))}
          </div>
          <div className="awo-widgets" style={{ width: 350 }}>
            {!!selected && (
              <ProposalBlockContext.Provider value={{ blockForm: defineProposalBlockForm(), blockIndex: 0 }}>
                <BlockPreview
                  //   scale={0.8}
                  withoutLabel
                  config={config}
                  variation={selected}
                  onClick={() => {
                    onVariationChange(selected);
                    anchor.onClose();
                  }}
                />
              </ProposalBlockContext.Provider>
            )}
          </div>
        </div>
      </div>
    </Popper>
  );
});
