import { FieldProps, FormControl, useFormField } from '@core/utils/form';
import { R } from '@core/utils/r';
import { TimeInput, toMoment } from '@core/utils/time';
import FormHelperText from '@mui/material/FormHelperText';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { SingleDatePickerShape } from 'react-dates';
import { FormGroup, InputGroup, InputGroupText, Label } from 'reactstrap';

export interface FormDateRangeExtraInputProps extends FieldProps<FormControl<string> | FormControl<string | null>> {
  options?: SingleDatePickerShape;
  minDate?: TimeInput;
  maxDate?: TimeInput;
  onChange?(range: string): void;
  appendToBody?: boolean;
  anchorDirection?: 'left' | 'right';
  withoutGroup?: boolean;
  small?: boolean;
}

export function FormDateRangeExtra(props: FormDateRangeExtraInputProps) {
  const { formControl, fieldState, fieldConfig } = useFormField(props);

  const fieldValue = fieldState.value;
  const sStart = R.toNumber(R.toString(fieldValue).split('-')[0]);
  const sEnd = R.toNumber(R.toString(fieldValue).split('-')[1]);

  const dateRangeRef = useRef<DateRangePicker>();

  const startDate = useMemo(() => {
    if (sStart) {
      return toMoment(sStart).toDate();
    } else {
      return toMoment()
        .subtract(30, 'day')
        .toDate();
    }
  }, [sStart]);
  const endDate = useMemo(() => {
    if (sEnd) {
      return toMoment(sEnd).toDate();
    } else {
      return toMoment().toDate();
    }
  }, [sEnd]);

  useEffect(() => {
    if (dateRangeRef && startDate && dateRangeRef.current) {
      dateRangeRef.current.setStartDate(startDate);
    }
    if (dateRangeRef && endDate && dateRangeRef.current) {
      dateRangeRef.current.setEndDate(endDate);
    }
  }, [dateRangeRef, startDate, endDate]);

  const fieldTouched = (fieldState && fieldState.touched) || formControl.inheritedSubmitted || false;

  const fieldError = (fieldTouched && fieldState.errorMessage) || null;

  const fieldPlaceholder = fieldConfig.placeholder || 'mm/dd/yyyy';

  const handleChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      //   if (startDate) {
      //     setStartDate(startDate.startOf('day'));
      //   }
      //   if (endDate) {
      //     setEndDate(endDate.endOf('day'));
      //   }
      const fieldValue = `${moment(startDate)
        ?.startOf('day')
        .unix()}-${moment(endDate)
        ?.endOf('day')
        .unix()}`;
      formControl.setValue(fieldValue);
      props.onChange && props.onChange(fieldValue);
    },
    [formControl, props],
  );

  const content = useMemo(
    () => (
      <Fragment>
        {fieldConfig.label && (
          <>
            <Label for={formControl.uid}>{fieldConfig.label}</Label> <br />
          </>
        )}
        <InputGroup>
          <InputGroupText>
            <i className="bx bx-calendar font-size-16"></i>
          </InputGroupText>
          <DateRangePicker
            ref={dateRangeRef as any}
            initialSettings={{
              startDate: startDate,
              endDate: endDate,
              ranges: {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                  moment()
                    .subtract(1, 'days')
                    .toDate(),
                  moment()
                    .subtract(1, 'days')
                    .toDate(),
                ],
                'Last 7 Days': [
                  moment()
                    .subtract(6, 'days')
                    .toDate(),
                  moment().toDate(),
                ],
                'Last 30 Days': [
                  moment()
                    .subtract(29, 'days')
                    .toDate(),
                  moment().toDate(),
                ],
                'This Month': [
                  moment()
                    .startOf('month')
                    .toDate(),
                  moment()
                    .endOf('month')
                    .toDate(),
                ],
                'Last Month': [
                  moment()
                    .subtract(1, 'month')
                    .startOf('month')
                    .toDate(),
                  moment()
                    .subtract(1, 'month')
                    .endOf('month')
                    .toDate(),
                ],
                'This year': [
                  moment()
                    .startOf('year')
                    .toDate(),
                  moment()
                    .endOf('year')
                    .toDate(),
                ],
                'Last year': [
                  moment()
                    .subtract(1, 'year')
                    .startOf('year')
                    .toDate(),
                  moment()
                    .subtract(1, 'year')
                    .endOf('year')
                    .toDate(),
                ],
              },
            }}
            onCallback={(start, end) => handleChange(start, end)}
            onApply={(event, picker) => handleChange(picker.startDate, picker.endDate)}
            //onEvent={(event, picker) => handleChange(picker.startDate, picker.endDate)}
          >
            <input type="text" className="form-control font-size-14" />
          </DateRangePicker>
        </InputGroup>
        {!!fieldError && <FormHelperText style={{ color: '#f44336' }}>{fieldError}</FormHelperText>}
      </Fragment>
    ),
    [fieldConfig.label, formControl.uid, startDate, endDate, fieldError, handleChange],
  );

  if (props.withoutGroup) {
    return <div className="theme_date-picker">{content}</div>;
  }

  return <FormGroup className="theme_date-picker">{content}</FormGroup>;
}
