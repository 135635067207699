import React, { memo } from 'react';
import { EmbedWidgetForm } from './EmbedBlock';
import { FormInput, FormCheckbox } from '@core/components/form';
export interface EmbedBlockSettingsProps {
  form: EmbedWidgetForm;
}

export const EmbedBlockSettings = memo((props: EmbedBlockSettingsProps) => {
  const { form } = props;

  return (
    <div>
      <FormInput control={form.controls.embedCode} type="textarea" style={{ minHeight: 200 }} />
      <FormCheckbox control={form.controls.fullWdith} />
    </div>
  );
});
