import React, { memo, useMemo, useImperativeHandle, useEffect } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import { formGroup, formControl } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { openBlockSettingsOverlay } from '../Common/BlockBaseConfig';
import { StatementBlockSettings } from './StatementBlockSettings';
import { FormText } from '@core/components/form/FormText';
import { useLazyEffect } from '@core/utils/react';
import { R } from '@core/utils/r';
import { useProposalBlockContext, useProposalContext } from '@modules/proposal/ProposalBuilderPage';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { Container } from 'reactstrap';

function defineStatementWidgetForm() {
  return formGroup({
    paragraph: formControl<string>({
      initialValue:
        'Quam nisi convallis imperdiet porta venenatis ultricies litora netus in pellentesque vulputate laoreet, integer varius metus velit conubia leo purus egestas curabitur cubilia.',
    }),
    advance: defineBlockAdvanceForm(),
  });
}

export type StatementBlockFormData = ReturnType<typeof defineStatementWidgetForm>;
export type StatementBlockForm = ReturnType<typeof defineStatementWidgetForm>;

export enum StatementBlockVariation {
  statementA = 'statementA',
  statementB = 'statementB',
  statementC = 'statementC',
  statementD = 'statementD',
  note = 'note',
}

export const StatementBlock = memo((props: Proposal.BlockProps<StatementBlockVariation>) => {
  const { variation, editable } = props;
  const form = useMemo(defineStatementWidgetForm, []);
  const formValue = useNonNilObservable(form.value$);
  const proposal = useProposalContext();
  const primaryColor = useMemo(() => proposal?.form.value.primaryColor, [proposal]);

  useConfigureBlock({
    props,
    form,
    contentComponent: StatementBlockSettings,
    contentProps: { form },
  });

  const noteStyles = useMemo(() => {
    if (variation == StatementBlockVariation.note && primaryColor) {
      return {
        borderColor: primaryColor,
        backgroundColor: primaryColor + '30',
      };
    }
    return {};
  }, [variation, primaryColor]);

  return (
    <BlockContainer className={`block-container d-flex flex-fill block-statement ${variation}`} advanceForm={formValue.advance}>
      <Container>
        {variation == StatementBlockVariation.note && (
          <div style={noteStyles}>
            <i className="bx bx-info-circle align-middle" />
            <FormText control={form.controls.paragraph} multipleLines editable={editable} />
          </div>
        )}
        {[StatementBlockVariation.statementA, StatementBlockVariation.statementB].includes(variation) && (
          <div className="statement-border" style={{ borderColor: primaryColor }} />
        )}
        {variation != StatementBlockVariation.note && <FormText control={form.controls.paragraph} multipleLines editable={editable} />}
        {[StatementBlockVariation.statementA].includes(variation) && <div className="statement-border" style={{ borderColor: primaryColor }} />}
      </Container>
    </BlockContainer>
  );
});
