import React, { memo, PropsWithChildren, ButtonHTMLAttributes, Fragment, ReactNode, useMemo } from 'react';
import { CssProp, classnames } from '@core/utils/css';

export type ButtonIcon = 'next' | 'prev' | 'add' | 'save' | (ReactNode & {});
export type ButtonIntent = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark' | 'light' | 'link';
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  customCss?: CssProp;
  intent?: ButtonIntent;
  loading?: boolean;
  icon?: string;
  leftIcon?: string;
  block?: boolean;
  extraClassNames?: string;
  outline?: boolean;
  iconExtraClasses?: string;
}

export const Button = memo((props: PropsWithChildren<ButtonProps>) => {
  const {
    children,
    customCss,
    intent = 'primary',
    loading,
    leftIcon,
    icon,
    block,
    extraClassNames = '',
    iconExtraClasses = '',
    outline,
    ...rest
  } = props;

  const leftContent = useMemo(() => getIcon(props.leftIcon, iconExtraClasses), [props.leftIcon]);
  const rightContent = useMemo(() => getIcon(props.icon, iconExtraClasses), [props.icon]);

  const disabled = loading || rest.disabled;

  const content = loading ? (
    <Fragment>
      <span className="content">
        <span className="contentOriginal">{children}</span> <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}
      </span>
    </Fragment>
  ) : (
    <Fragment>
      {leftContent} {children} {rightContent}
    </Fragment>
  );

  const btnClasses = classnames(
    'btn',
    `btn-${outline ? 'outline-' : ''}${intent}`,
    'waves-effect',
    'waves-light',
    !!block && 'btn-block',
    extraClassNames,
  );

  return (
    <button {...rest} disabled={disabled} className={btnClasses} type="submit">
      {content}
    </button>
  );
});

function getIcon(icon?: string, iconExtraClasses?: string) {
  if (!icon) return null;
  return <i className={`font-size-16 align-middle mr-2 ${iconExtraClasses} ${icon}`}></i>;
}
