import React, { memo, useMemo, useState, Fragment } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import { formGroup, formControl, FormDataType } from '@core/utils/form';
import classNames from 'classnames';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { MultimediaBlockSettings } from './MultimediaBlockSettings';
import { HTMLService } from '@business/services';
import { Container } from 'reactstrap';

function defineMultimediaBlockForm() {
  return formGroup({
    url: formControl<string>({
      initialValue: '',
    }),
    config: formGroup({
      embed: formControl<string>({
        initialValue:
          '<iframe src="//www.youtube.com/embed/G1hKzCkywM8?wmode=transparent&amp;modestbranding=1&amp;rel=0&amp;autoplay=1" type="text/html" width="100%" height="100%" frameborder="0" allowfullscreen class="iframe-video"></iframe>',
      }),
      image: formControl<string>({ initialValue: 'https://img.youtube.com/vi/G1hKzCkywM8/maxresdefault.jpg' }),
    }),
    advance: defineBlockAdvanceForm(),
  });
}

export type MultimediaWidgetFormData = FormDataType<typeof defineMultimediaBlockForm>;
export type MultimediaWidgetForm = ReturnType<typeof defineMultimediaBlockForm>;

export enum MultimediaBlockVariation {
  video = 'video',
}

export const MultimediaBlock = memo((props: Proposal.BlockProps<MultimediaBlockVariation>) => {
  const { variation } = props;
  const form = useMemo(defineMultimediaBlockForm, []);
  const formValue = useNonNilObservable(form.value$);
  const [isClicked, setClicked] = useState(false);

  useConfigureBlock({
    props,
    form,
    contentComponent: MultimediaBlockSettings,
    contentProps: { form },
  });

  return (
    <BlockContainer className={classNames('d-flex flex-fill', 'block-multimedia', variation)} advanceForm={formValue.advance} >
      <Container>
        <div className="video">
          <div className="video-thumbnail" style={{ width: props.preview ? '70%' : '50%' }}>
            {isClicked && HTMLService.toReact(formValue.config.embed)}
            {!isClicked && (
              <Fragment>
                <img src={formValue.config.image} />
                <a className="video-play-button" onClick={() => setClicked(true)}>
                  <i className="fa fa-play"></i>
                </a>
              </Fragment>
            )}
          </div>
        </div>
      </Container>
    </BlockContainer>
  );
});
