import React, { memo, PropsWithChildren, RefObject } from 'react';
import { css, ClassNames } from '@emotion/react';
import { Theme, CssProp, Styles } from '@core/utils/css';
import { FormContainerProps, FormContainer } from '../form';
import { DialogContent } from '@mui/material';

const styles = {
  container: css`
    line-height: 1.5;
    position: relative;

    color: #20455e;
  `,

  fullHeight: css`
    height: 60vh;
  `,

  noPadding: css`
    padding: 0;
  `,

  tabContent: css`
    padding: 0;
  `,

  tabContentFullHeight: css`
    overflow: hidden;

    > div,
    form {
      ${Styles.absoluteFill};
    }
  `,
};

export interface ModalContentProps {
  customCss?: CssProp;
  fullHeight?: boolean;
  noPadding?: boolean;
  tabContent?: boolean;
  formContainer?: FormContainerProps;
  id?: string;
}

export const ModalContent = memo((props: PropsWithChildren<ModalContentProps>) => {
  let children = props.children;

  if (props.formContainer) {
    children = <FormContainer {...props.formContainer}>{children}</FormContainer>;
  }

  return (
    <ClassNames>
      {({ css }) => (
        <DialogContent
          id={props.id}
          classes={{
            root: css(
              styles.container,
              props.fullHeight && styles.fullHeight,
              props.noPadding && styles.noPadding,
              props.tabContent && styles.tabContent,
              props.tabContent && props.fullHeight && styles.tabContentFullHeight,
              props.customCss,
            ),
          }}
        >
          {children}
        </DialogContent>
      )}
    </ClassNames>
  );
});
