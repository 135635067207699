import { useHistory, useRouteParam } from '@core/router';
import { classnames } from '@core/utils/css';
import React, { Component, ExoticComponent, memo, Suspense, useEffect, useMemo, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

export interface TabsMapping {
  title: string;
  leftIcon?: string;
  rightIcon?: string;
  leftHTML?: React.ReactNode;
  rightHTML?: React.ReactNode;
  component: Component<any> | ExoticComponent<any>;
  props?: any;
  route?: string;
}

export interface TabsProps {
  vertical?: boolean;
  tabs: TabsMapping[];
  initialIndex?: number;
  pills?: boolean;
  simple?: boolean;
  routeParam?: string;
  route?(tab: string): string;
  isModal?: boolean;
  isLazy?: boolean;
  onChange?(index: number): void;
}

export const Tabs = memo((props: TabsProps) => {
  const { tabs, vertical = false, pills = false, simple = false, isModal = false, isLazy = false } = props;
  let routeTabParam = '';
  if (!isModal && props.routeParam) {
    routeTabParam = useRouteParam(props.routeParam || 'randomRouteParam', true);
  }
  const history = useHistory();

  const initialIndex = useMemo(() => {
    if (props.initialIndex) {
      return props.initialIndex;
    } else if (props.routeParam && routeTabParam) {
      return tabs.findIndex(t => t.route === routeTabParam) || 0;
    }
    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialIndex]);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(initialIndex);

  useEffect(() => {
    const tab = tabs[activeTabIndex];
    if (props.route && tab && tab.route && props.routeParam) {
      const url = props.route(tab.route);
      history.replace(url);
    }
    props.onChange && props.onChange(activeTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex, props.onChange]);

  const navClasses = useMemo(() => {
    const items = [];
    if (pills) {
      items.push('navtab-bg');
    } else {
      if (!simple) {
        items.push('nav-tabs-custom');
      }
    }
    if (vertical) {
      items.push('flex-column');
    }
    return items;
  }, [pills, simple, vertical]);

  const navBar = (
    <Nav pills={pills} tabs={!pills} className={classnames(navClasses)}>
      {tabs.map((tab, index) => (
        <NavItem key={`tab-${index}`}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({
              active: activeTabIndex === index,
            })}
            onClick={() => setActiveTabIndex(index)}
          >
            {tab.leftIcon && <i className={tab.leftIcon}></i>}
            {tab.leftHTML}
            <span className={`${tab.leftIcon || tab.rightIcon ? 'd-none d-sm-block' : ''} `}>{tab.title}</span>
            {tab.rightHTML}
            {tab.rightIcon && <i className={tab.rightIcon}></i>}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  const panes = useMemo(
    () => (
      <TabContent activeTab={activeTabIndex}>
        {tabs.map((tab, index) => {
          const TabComponent = tab.component as any;
          return (
            <TabPane tabId={index} className="py-3" key={`pane-${index}`}>
              <TabComponent key={index} {...tab.props} />
            </TabPane>
          );
        })}
      </TabContent>
    ),
    [tabs, activeTabIndex],
  );

  if (vertical) {
    return (
      <Row>
        <Col lg={2}>{navBar}</Col>
        <Col lg={10}>{panes}</Col>
      </Row>
    );
  } else if (isLazy) {
    return (
      <React.Fragment>
        {navBar}
        <TabContent activeTab={activeTabIndex}>
          {tabs.map((tab, index) => {
            const TabComponent = tab.component as any;
            return (
              <TabPane tabId={index} className="py-3" key={`pane-${index}`}>
                <Suspense fallback={<div>Loading Component..</div>}>
                  <TabComponent key={index} {...tab.props} />
                </Suspense>
              </TabPane>
            );
          })}
        </TabContent>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {navBar}
        {panes}
      </React.Fragment>
    );
  }
});
