export const Theme = {
  fontFamily: `'Source Sans Pro', sans-serif`,

  accentColor: '#ff7e28',

  darkColor: '#014b84',
  darkerColor: '#014171',

  lightColor: '#ecf6fe',

  ligherColor: '#f1f7fc',
  lighterBorderColor: '#d6e8f6',

  textDarkColor: '#20455e',
  textPrimarycolor: '#004c85',

  linkColor: '#2873ac',

  dangerColor: '#dc3545',
};
