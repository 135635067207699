import { ProposalTemplateAPI } from '@business/api/proposal_template_api';
import {
  CompactProposalTemplateItemResponse,
  FolderId,
  ItemId,
  ProposalTemplateFolderBreadcrumbResponse,
  ProposalTemplateItemResponse,
  ProposalTemplateItemType,
  ProposalTemplateResponse,
} from '@business/entities/proposal';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { R } from '@core/utils/r';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ProposalFormData } from '@modules/proposal/ProposalBuilderPage';

export class ProposalTemplateBloc extends BaseBloc {
  private readonly itemsRepo = new Repository<ProposalTemplateItemResponse | ProposalTemplateResponse | CompactProposalTemplateItemResponse>({
    getItemId: item => `${item.type}-${item.id}`,
  });

  readonly items$ = this.itemsRepo.items$;

  readonly selectItem = this.itemsRepo.selectItem;
  readonly breadcrumb$ = new BehaviorSubject<ProposalTemplateFolderBreadcrumbResponse[]>([]);

  readonly getFolderItems = (folderId: FolderId) => {
    folderId = !folderId ? '0' : folderId;
    return this.items$.pipe(
      map(items =>
        R.sortBy(
          R.filter(items, i => parseInt(i.parentFolderId) === parseInt(folderId)),
          [item => (item.type === ProposalTemplateItemType.folder ? 0 : 1), 'name'],
          ['asc', 'asc'],
        ),
      ),
    );
  };

  readonly folders$ = this.items$.pipe(
    map(items =>
      R.sortBy(
        R.filter(items, i => i.type === 'folder'),
        i => i.name.toLowerCase(),
      ),
    ),
  );

  onReset() {
    this.itemsRepo.reset();
  }

  getItems = (parentId?: FolderId, search?: string) => {
    return ProposalTemplateAPI.getItems(parentId, search).pipe(
      map(data => {
        this.breadcrumb$.next([...data.breadcrumbs]);
        return data.items;
      }),
      this.itemsRepo.ops.reset(),
      handleMessage({ error: requestMessage('fetch_files_error') }),
    );
  };

  createFolder = (name: string, parentId?: FolderId) => {
    return ProposalTemplateAPI.createFolder(name, parentId).pipe(
      this.itemsRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_folder'),
      }),
    );
  };

  renameFolder = (id: FolderId, name: string) => {
    return ProposalTemplateAPI.renameFolder(id, name).pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('rename_folder'),
      }),
    );
  };

  deleteFolder = (id: FolderId) => {
    return showDeleteConfirmation('Delete Folder', 'Do you really want to remove this folder?').pipe(
      switchMap(() => ProposalTemplateAPI.deleteFolder(id)),
      this.itemsRepo.ops.removeOne(() => `folder-${id}`),
      handleMessage({
        type: requestMessage('delete_folder'),
      }),
    );
  };

  getItem = (id: ItemId) => {
    return ProposalTemplateAPI.getItem(id, '').pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_proposal_template_error') }),
    );
  };

  getItemPublic = (id: ItemId) => {
    return ProposalTemplateAPI.getItemPublic(id).pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_proposal_template_error') }),
    );
  };

  createItem = (item: Partial<ProposalTemplateItemResponse>, parentId?: FolderId) => {
    return ProposalTemplateAPI.createItem(item, parentId).pipe(
      //   this.itemsRepo.ops.addOne(item => item),
      this.itemsRepo.ops.upsertMany(item => ({ item })),
      handleMessage({
        type: requestMessage('create_folder_item'),
      }),
    );
  };

  duplicateItem = (id: ItemId, title: string) => {
    return ProposalTemplateAPI.duplicateItem(id, title).pipe(
      this.itemsRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('duplicate_proposal_template_item'),
      }),
    );
  };

  updateItem = (id: ItemId, item: Partial<ProposalTemplateItemResponse>) => {
    return ProposalTemplateAPI.updateItem(item, id).pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_folder_item'),
      }),
    );
  };

  updateFormData = (id: ItemId, data: string) => {
    return ProposalTemplateAPI.updateFormData(id, data).pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        error: requestMessage('update_proposal_template_error'),
      }),
    );
  };

  deleteItem = (id: ItemId, type: string) => {
    return showDeleteConfirmation('Delete Folder', 'Do you really want to remove this item?').pipe(
      switchMap(() => ProposalTemplateAPI.deleteItem(id)),
      this.itemsRepo.ops.removeOne(() => `${type}-${id}`),
      handleMessage({
        type: requestMessage('delete_folder_item'),
      }),
    );
  };

  folderStructure = (id: FolderId) => {
    return ProposalTemplateAPI.folderStructure(id).pipe(
      handleMessage({
        error: requestMessage('fetch_general_error'),
      }),
    );
  };

  move = (id: string, type: ProposalTemplateItemType, destinationId: string) => {
    return ProposalTemplateAPI.move(id, type, destinationId).pipe(
      handleMessage({
        error: requestMessage('move_folder_error'),
      }),
    );
  };
}
