import { CompactUserResponse } from '../account/GCUserResponse';
import { CompactContactResponse } from '../contact';
import { CompactOrgResponse, OrgExtra, OrgId } from '../organization';
import { CompactDealBoardResponse } from './DealBoardResponse';
import { CompactDealStageResponse, DealStageId } from './DealStageResponse';

export enum DealIntervalResponse {
  onetime = 'one time',
  monthly = 'monthly',
  quarterly = 'quarterly',
  yearly = 'yearly',
}

export enum DealStatus {
  inProgress = 'in_progress',
  won = 'won',
  lost = 'lost',
  deleted = 'deleted',
  reopen = 'reopen',
}

export enum DealAlertStatus {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
}

export type DealId = string;

export interface CompactDealResponse {
  id: DealId;
  name: string;
  value: number;
  valueDefault: number;
  interval: string;
  status: DealStatus;
  lostExplain: string;
  expectedClosed: number;
  lastActivity: number;
  menuOrder: number;
  orgId: OrgId;
  orgName: string;
  owner: CompactUserResponse;
  isWarning: boolean;
  alertStatus: DealAlertStatus;
  alertMessage: string;
  extra: OrgExtra;
  stageId: DealStageId;
  stageTitle: string;
  orgCurrency: string;
}

export enum HasDeal {
  all = 'all',
  yes = 'yes',
  no = 'no',
}

export interface DealResponse extends CompactDealResponse {
  stage: CompactDealStageResponse;
  lostReason: string;
  lostExplain: string;
  contact: CompactContactResponse;
  organization: CompactOrgResponse;

  board: CompactDealBoardResponse;
  logs: DealLogResponse[];
  nextId: DealId;

  customFields: { [key: string]: string | number };
}

export interface DealLogResponse {
  id: string;
  value: string;
  note: string;
  user: CompactUserResponse;
  createdAt: number;
}
