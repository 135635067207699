import { lodash } from './lodash';
// import * as he from 'he';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const isValidDomain = require('is-valid-domain');

export function addDot(text: string | null | undefined) {
  text = lodash.trim(text || '');

  if (!text.length) return text;

  return !text.endsWith('.') && !text.endsWith('?') && !text.endsWith('!') ? `${text}.` : text;
}

export function capitalizeWords(text: string) {
  return lodash.trim(text).split(/\s+/).map(lodash.capitalize).join(' ');
}

export function startCaseHyphen(text: string) {
  if (!text) return '';

  let parts = (text + '').split(/\s*-\s*/);

  parts = parts.map((part, index) => {
    const firstChart = part[0] || '';
    part = lodash.startCase(part);

    if (index === 0) return part;

    return firstChart + part.slice(1);
  });

  return parts.join(' - ').trim();
}

// export function decodeHtmlEntities(text: string | null | undefined) {
//   return text ? he.decode(text) : null;
// }

export function localeCompare(a: string, b: string, locales?: string | string[], options?: Intl.CollatorOptions) {
  return lodash.toString(a).localeCompare(lodash.toString(b), locales, { numeric: true, sensitivity: 'base', ...options });
}

export function isDomain(text: string) {
  return isValidDomain(text);
}

export function slug(text: string) {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

export function plural(count: number, text: string, pluralText: string) {
  return count === 1 ? text : pluralText;
}

export function replaceAll(text: string, search: string, replacement: string) {
  return text.split(search).join(replacement);
}

export function replaceString(str: string, match: string, fn: any) {
  let curCharStart = 0;
  let curCharLen = 0;

  if (str === '') {
    return str;
  } else if (!str || !lodash.isString(str)) {
    throw new TypeError('First argument to react-string-replace#replaceString must be a string');
  }

  let re: string | RegExp = match;

  if (!lodash.isRegExp(re)) {
    re = new RegExp('(' + lodash.escapeRegExp(re) + ')', 'gi');
  }

  const result = str.split(re);

  // Apply fn to all odd elements
  for (let i = 1, length = result.length; i < length; i += 2) {
    curCharLen = result[i].length;
    curCharStart += result[i - 1].length;
    result[i] = fn(result[i], i, curCharStart);
    curCharStart += curCharLen;
  }

  return result;
}
