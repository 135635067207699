import { CommonService } from '@business/services';
import { Endpoint } from '../endpoint';
import { UploadResponse } from '@business/entities/common';

export function upload(path: string, file: File) {
  const formData = CommonService.toFormData({ file, path });

  return Endpoint.post<UploadResponse>(`@gc/upload`, { formData, hasFiles: true });
}

export function store(path: string, url: string) {
  return Endpoint.post<UploadResponse>(`@gc/upload/store`, { data: { path, url } });
}

export function video(url: string) {
  return Endpoint.post<{ embed: string; image: string }>(`@gc/upload/video`, { data: { url } });
}
