import { Endpoint } from '../endpoint';
import { ReportCategoryId, DynamicReportResponse, DynamicReportId } from '@business/entities/ReportCategory/ReportCategoryResponse';

/**
 * Support Channel API
 */
export function fetchReports(categoryId: ReportCategoryId) {
  return Endpoint.get<DynamicReportResponse[]>(`@gc/report-category/reports/${categoryId}`);
}
export function fetchReport(id: string) {
  return Endpoint.get<DynamicReportResponse>(`@gc/report-category/report/${id}`);
}
export function createReport(categoryId: ReportCategoryId, data: Partial<DynamicReportResponse>) {
  return Endpoint.post<DynamicReportResponse>(`@gc/report-category/report/${categoryId}`, { data });
}
export function copyReport(id: DynamicReportId, title: string) {
  return Endpoint.post<DynamicReportResponse>(`@gc/report-category/copy/${id}`, { data: { title } });
}
export function updateReport(id: DynamicReportId, data: Partial<DynamicReportResponse>) {
  return Endpoint.patch<DynamicReportResponse>(`@gc/report-category/report/${id}`, { data });
}
export function updateReportStatus(id: DynamicReportId, data: { published: boolean }) {
  return Endpoint.patch<DynamicReportResponse>(`@gc/report-category/report-status/${id}`, { data });
}
export function deleteReport(id: ReportCategoryId) {
  return Endpoint.delete<void>(`@gc/report-category/report/${id}`);
}
export function sortReports(ids: ReportCategoryId[]) {
  return Endpoint.post<DynamicReportResponse[]>(`@gc/report-category/report-sort`, { data: { ids } });
}
