import React, { memo } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

export interface CBreadcrumbItem {
  title: string;
  link?: string;
  onClick?(): void;
}

export interface CBreadcrumbProps {
  items: CBreadcrumbItem[];
  listClassName?: string;
  noLink?: boolean;
}

export const CBreadcrumb = memo((props: CBreadcrumbProps) => {
  const { items, listClassName, noLink = false } = props;
  if (!items.length) return null;
  return (
    <Breadcrumb tag="nav" listTag="div" listClassName={`pl-0 bg-transparent ${listClassName}`}>
      {items.map((item, index) => (
        <BreadcrumbItem key={index} active={items.length === index + 1 || noLink} tag="a" href={item.link} onClick={item.onClick}>
          {item.title}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
});
