import React, { memo, useMemo, useCallback, Fragment, useEffect, useImperativeHandle } from 'react';
import './index.scss';
import { ProposalBlockContext, useProposalContext } from '../../ProposalContext';
import { useNonNilObservable, useObservable } from '@core/utils/hooks/rxjs';
import { BlockBase, BlockBaseHandle } from '@modules/proposal/Blocks/BlockBase/BlockBase';
import { R } from '@core/utils/r';
import { PageBottomToolbar } from './PageBottomToolbar';
import { Proposal } from '@modules/proposal/Blocks/types';
import { ProposalBlockFormData } from '../../ProposalForm';
import { BlockBaseReadonly } from '@modules/proposal/Blocks/BlockBase/BlockBaseReadonly';
import { css } from '@emotion/react';

export interface ProposalBlocsHandle {
  openSettings(index: number): void;
}

export interface ProposalBlcoksProps {
  blockRefs: React.RefObject<HTMLDivElement>[];
  moveTo(index: number): void;
  editable?: boolean;
  partiallyEditable?: boolean;
  handlesRef?: React.RefObject<ProposalBlocsHandle>;
}

export const ProposalBlocs = memo((props: ProposalBlcoksProps) => {
  const { blockRefs, moveTo, editable = true, partiallyEditable = false } = props;
  const { form } = useProposalContext()!;
  const proposal = useProposalContext();
  const primaryColor = useMemo(() => proposal?.form.value.primaryColor, [proposal]);
  const formValue = useNonNilObservable(form.value$);
  const blocks = useMemo(() => R.sortBy(formValue.blocks, b => b.menuOrder.toString()), [formValue.blocks]);
  const blockItems = useObservable(form.controls.blocks.items$, []);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const handleRefs = useMemo(() => blockItems.map(() => React.createRef<BlockBaseHandle>()), [blockItems]);

  const listCss = useMemo(() => {
    if (primaryColor) {
      return css`
        li::marker {
          color: ${primaryColor};
        }
      `;
    } else {
      return css`
        li::marker {
          color: #45a8f7;
        }
      `;
    }
  }, [primaryColor]);

  const openSettings = useCallback(
    (blockIndex: number) => {
      console.log(blockIndex);
      if (handleRefs[blockIndex] && handleRefs[blockIndex].current) {
        handleRefs[blockIndex].current!.openSettings();
      }
    },
    [handleRefs],
  );

  useImperativeHandle(props.handlesRef, () => ({ openSettings }));

  const onDelete = useCallback(
    (id: string) => {
      const newBlock = R.pipe(
        formValue.blocks,
        R.ops.filter(b => b.id !== id),
        R.ops.sortBy(b => b.menuOrder),
      ).map((b, i) => ({ ...b, menuOrder: i }));

      form.patchValue({ blocks: newBlock });
    },
    [form, formValue.blocks],
  );

  const onAdd = useCallback(
    (type: Proposal.BlockType, variation: string, at?: number) => {
      const menuOrder = at || blockItems.length;
      const newBlock: ProposalBlockFormData = {
        id: `widget-${type}-${variation}` + R.random(1, 9999),
        type,
        variation,
        menuOrder,
        locked: false,
        data: null,
        isSection: false,
        title: undefined,
      };

      let newBlocks = [...form.value.blocks].map(b => {
        return {
          ...b,
          menuOrder: b.menuOrder >= menuOrder ? b.menuOrder + 1 : b.menuOrder,
        };
      });
      newBlocks.push(newBlock);
      newBlocks = R.sortBy(newBlocks, 'menuOrder').map((b, i) => ({ ...b, menuOrder: i }));
      console.log(newBlocks);
      form.patchValue({ blocks: newBlocks });
      moveTo(at || newBlocks.length - 1);
    },
    [form, blockItems, moveTo],
  );

  const onMove = useCallback(
    (id: string, direction: number) => {
      const blockIndex = formValue.blocks.findIndex(b => b.id === id);
      const blockA = formValue.blocks[blockIndex];
      const blockB = formValue.blocks[blockIndex + direction];
      const newBlockA = { ...blockA, menuOrder: blockA.menuOrder + direction };
      const newBlockB = { ...blockB, menuOrder: blockB.menuOrder - direction };
      let newBlocks = formValue.blocks.map(b => {
        if (b.id === id) return newBlockA;
        if (b.id === blockB.id) return newBlockB;
        return b;
      });
      newBlocks = R.sortBy(newBlocks, 'menuOrder').map((b, i) => ({ ...b, menuOrder: i }));
      form.patchValue({ blocks: newBlocks });
    },

    [form, formValue.blocks],
  );

  const onCopy = useCallback(
    (id: string) => {
      const newBlock = { ...formValue.blocks.find(b => b.id === id)! };
      newBlock.id = newBlock.id + R.random(1, 1000);
      newBlock.menuOrder += 1;

      let newBlocks = formValue.blocks.map(b => {
        return {
          ...b,
          menuOrder: b.menuOrder >= newBlock.menuOrder ? b.menuOrder + 1 : b.menuOrder,
        };
      });
      newBlocks.push(newBlock);
      newBlocks = R.sortBy(newBlocks, 'menuOrder').map((b, i) => ({ ...b, menuOrder: i }));
      form.patchValue({ blocks: newBlocks });
    },
    [form, formValue.blocks],
  );

  const blocksContent = useMemo(() => {
    return blockItems.map((blockForm, index) => {
      // Workaround to avoid error when block is deleted or page is moved
      if (!blockForm) return <div key={R.random(1, 9999).toString()}></div>;
      if (!blockForm.value.type) return <div key={blockForm.value.id.toString()}></div>;
      // if (blockForm.value.id !== block.id) return <div key={block.id.toString()}></div>;
      return (
        <div key={blockForm.value.id}>
          <ProposalBlockContext.Provider value={{ blockForm, blockIndex: index }}>
            {editable && (
              <BlockBase
                ref={blockRefs[index]}
                isLast={index === blockItems.length - 1}
                isFirst={index === 0}
                onAdd={onAdd}
                onDelete={onDelete}
                onMove={onMove}
                onCopy={onCopy}
                partiallyEditable={partiallyEditable}
                handleRef={handleRefs[index]}
              />
            )}
            {!editable && <BlockBaseReadonly />}
          </ProposalBlockContext.Provider>
        </div>
      );
    });
  }, [blockItems, onAdd, onCopy, onDelete, onMove, editable, blockRefs, partiallyEditable, handleRefs]);

  return (
    <div className="proposal-page builder" css={listCss}>
      <div ref={contentRef}>{blocksContent}</div>
      {editable && !partiallyEditable && <PageBottomToolbar onAdd={onAdd} size={blocks.length} />}
    </div>
  );
});
