import React, { memo, useMemo, CSSProperties } from 'react';
import { FormControl, useFormField, FieldProps } from '@core/utils/form';
import { R } from '@core/utils/r';
import './form_html.scss';

export type FormToggleSwitchProps = FieldProps<FormControl<boolean>> & {
  onChange?(value: boolean): void;
  noPadding?: boolean;
  container?: string;
  toggleOnLabel?: string;
  toggleOffLabel?: string;
  extraStyles?: CSSProperties;
};

export const FormToggleSwitch = memo((props: FormToggleSwitchProps) => {
  const { formControl, fieldState, fieldConfig } = useFormField(props);
  const { toggleOnLabel = 'On', toggleOffLabel = 'Off', extraStyles } = props;

  const fieldValue = fieldState.value || false;
  const fieldReadonly = fieldConfig.readonly || false;
  const fieldDisabled = fieldConfig.disabled || false;
  const uid = useMemo(() => R.random(), []);

  return (
    <React.Fragment>
      <div
        className={`custom-control custom-checkbox custom-checkbox-primary d-flex ${props.noPadding ? '' : 'mb-3'}`}
        style={{ ...(extraStyles || {}) }}
      >
        <div className="square-switch">
          <input
            id={formControl.uid}
            type="checkbox"
            className="custom-control-input"
            checked={fieldValue}
            onChange={event => {
              formControl.setValue(event.target.checked);
              props.onChange && props.onChange(event.target.checked);
            }}
            onFocus={() => formControl.onFocus()}
            onBlur={() => formControl.onBlur()}
            disabled={fieldDisabled}
            readOnly={fieldReadonly}
            switch="none"
          />
          <label htmlFor={formControl.uid} data-on-label={toggleOnLabel} data-off-label={toggleOffLabel}></label>
        </div>
        <span className="mt-1">{fieldConfig.label}</span>
      </div>
    </React.Fragment>
  );
});
