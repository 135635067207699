import { FieldProps, FormControl, useFormField } from '@core/utils/form';
import { R } from '@core/utils/r';
import MFormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React, { ChangeEvent, Fragment, useCallback, useMemo } from 'react';
import { FormGroup, FormGroupProps, Input, InputGroup, InputGroupText, InputProps, Label, UncontrolledTooltip } from 'reactstrap';
import './form_input.scss';

export interface FormStringInputProps<C extends FormControl<any>> extends FieldProps<C> {
  type?: 'text' | 'password' | 'textarea' | 'color' | 'search';
  multiline?: boolean;
  rows?: number;
  autoFocus?: boolean;
  groupProps?: FormGroupProps;
  withoutGroup?: boolean;
  description?: string;
  container?: string;
  onlyNumbers?: boolean;
  parseToFloat?: boolean;
  onText?(text: string): void;
  onFocusChange?(focused: boolean): void;
  flex?: number[];
  icon?: string;
  iconStyles?: React.CSSProperties;
  inputParentStyles?: React.CSSProperties;
  asterisk?: boolean;
}

export interface FormNumberInputProps<C extends FormControl<any>> extends FieldProps<C> {
  type?: 'number';
  step?: number;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  groupProps?: FormGroupProps;
  withoutGroup?: boolean;
  onText?(text: string): void;
  flex?: number[];
}

export type FormInputProps<C extends FormControl<any>> = FormStringInputProps<C> | FormNumberInputProps<C>;

export function FormInput<C extends FormControl<any>>(props: FormInputProps<C> & InputProps) {
  const {
    type = 'text',
    readonly,
    withoutGroup = false,
    isInputGroup = false,
    addonText = null,
    onText,
    onlyNumbers = false,
    parseToFloat = false,
    onFocusChange,
    icon,
    inputParentStyles,
    ...rest
  } = props;

  const { formControl, fieldState, fieldConfig } = useFormField(props);

  const fieldTouched = (fieldState && fieldState.touched) || formControl.inheritedSubmitted || false;
  const fieldValue = R.toString(fieldState.value) || '';
  const fieldError = (fieldTouched && fieldState.errorMessage) || null;

  const fieldReadonly = fieldConfig.readonly || false;
  const fieldDisabled = fieldConfig.disabled || false;

  const fieldPlaceholder = fieldConfig.placeholder || '';

  const showIcon = useMemo(() => icon && !R.isEmpty(icon), [icon]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let value: any = event.target.value;

      if (type === 'number') {
        if (value) value = +value;
        if (R.isNaN(value)) value = null;
      }

      formControl.setValue(value);
      onText && onText(value);
    },
    [formControl, onText, type],
  );

  const handleFocus = useCallback(() => {
    formControl.onFocus();
    onFocusChange?.(true);
  }, [formControl, onFocusChange]);

  const handleBlur = useCallback(() => {
    if (onlyNumbers) {
      formControl.setValue(fieldValue.replace(/\D/g, ''));
    }
    if (parseToFloat) {
      formControl.setValue(fieldValue.replace(/\,/g, ''));
    }
    onFocusChange?.(false);
    formControl.onBlur();
  }, [fieldValue, formControl, onFocusChange, onlyNumbers, parseToFloat]);

  const tooltip = useMemo(() => {
    if (!props.description) return null;
    const uid = formControl.uid.replace('form:', '');
    return (
      <Fragment>
        <i className="fas fa-question-circle ml-2" id={`control-description-${uid}`}></i>
        <UncontrolledTooltip container={props.container} placement="top" target={`control-description-${uid}`}>
          {props.description}
        </UncontrolledTooltip>
      </Fragment>
    );
  }, [formControl.uid, props.container, props.description]);

  let inputContent = (
    <div className="form-input-field" style={{ ...inputParentStyles }}>
      {showIcon && <i className={`${icon} icon`} style={{ ...props.iconStyles }}></i>}
      <Input
        {...rest}
        onBlur={handleBlur}
        onFocus={handleFocus}
        autoFocus={props.autoFocus}
        className={`form-control ${showIcon && 'pr-4'}`}
        id={formControl.uid}
        value={fieldValue}
        onChange={handleChange}
        type={props.type}
        required={fieldConfig.requiredMask}
        disabled={fieldDisabled}
        placeholder={fieldPlaceholder}
        readOnly={fieldReadonly}
      />
    </div>
  );
  if (isInputGroup && addonText && addonText !== '') {
    inputContent = (
      <InputGroup>
        <InputGroup addonType="prepend">
          <InputGroupText>{addonText}</InputGroupText>
        </InputGroup>
        {inputContent}
      </InputGroup>
    );
  }

  const content = (
    <Fragment>
      {fieldConfig.label && (
        <Label for={formControl.uid} style={!!props.flex ? { display: 'flex', flex: props.flex?.[0] || 1 } : {}}>
          {fieldConfig.label}
          {fieldConfig.required && !R.isEmpty(fieldConfig.label) && (
            <span className="text-danger font-size-14" style={{ marginTop: -5, marginLeft: 2 }}>
              *
            </span>
          )}
          {tooltip}
        </Label>
      )}
      {!!props.flex && (
        <div style={{ flex: props.flex?.[1] || 1, flexDirection: 'row' }}>
          <MFormControl error={!!fieldError} fullWidth>
            {inputContent}
          </MFormControl>
          {!!fieldError && <FormHelperText style={{ color: '#f44336' }}>{fieldError}</FormHelperText>}
        </div>
      )}
      {!props.flex && (
        <MFormControl error={!!fieldError} fullWidth>
          {inputContent}
        </MFormControl>
      )}
      {!props.flex && !!fieldError && <FormHelperText style={{ color: '#f44336' }}>{fieldError}</FormHelperText>}
    </Fragment>
  );

  if (withoutGroup) return content;
  return (
    <FormGroup className={props.flex ? 'd-flex align-items-center' : ''} {...props.groupProps}>
      {content}
    </FormGroup>
  );
}
