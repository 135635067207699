import { Endpoint } from '../endpoint';
import { ReportCategoryId, ReportCategoryResponse } from '@business/entities/ReportCategory/ReportCategoryResponse';

/**
 * Support Channel API
 */
export function fetchCategories() {
  return Endpoint.get<ReportCategoryResponse[]>(`@gc/report-category`);
}
export function fetchDetailCategories() {
  return Endpoint.get<ReportCategoryResponse[]>(`@gc/report-category?isDetail=true`);
}
export function fetchCategory(id: ReportCategoryId) {
  return Endpoint.get<ReportCategoryResponse>(`@gc/report-category/${id}`);
}
export function createCategory(data: Partial<ReportCategoryResponse>) {
  return Endpoint.post<ReportCategoryResponse>(`@gc/report-category`, { data: { ...data } });
}
export function updateCategory(id: ReportCategoryId, data: Partial<ReportCategoryResponse>) {
  return Endpoint.patch<ReportCategoryResponse>(`@gc/report-category/${id}`, { data: { ...data } });
}
export function deleteCategory(id: ReportCategoryId) {
  return Endpoint.delete<void>(`@gc/report-category/${id}`);
}

export function sortCategories(ids: ReportCategoryId[]) {
  return Endpoint.post<ReportCategoryResponse[]>(`@gc/report-category/sort`, { data: { ids } });
}
