import { Endpoint } from '@business/api/endpoint';
import {
  CompactProposalTemplateFolderResponse,
  CompactProposalTemplateItemResponse,
  FolderId,
  ItemId,
  ProposalTemplateFolderStructureResponse,
  ProposalTemplateItemResponse,
  ProposalTemplateItemsResponse,
  ProposalTemplateItemType,
  ProposalTemplateResponse,
} from '@business/entities/proposal';
import { R } from '@core/utils/r';
import { ProposalFormData } from '@modules/proposal/ProposalBuilderPage';
import { CommonService } from '@business/services';

export function getItems(parentId?: FolderId, search?: string) {
  return Endpoint.get<ProposalTemplateItemsResponse>(`@gc/proposal-template`, { params: R.objectCompact({ parentId, search }) });
}

export function getFolders() {
  return Endpoint.get<CompactProposalTemplateFolderResponse[]>(`@gc/proposal-template/folders`);
}

/** Folders **/
export function createFolder(name: string, parentId?: FolderId) {
  return Endpoint.post<ProposalTemplateResponse>(`@gc/proposal-template`, { data: { name, parentId } });
}

export function renameFolder(id: FolderId, name: string) {
  return Endpoint.patch<ProposalTemplateResponse>(`@gc/proposal-template/${id}`, { data: { name } });
}

export function deleteFolder(id: FolderId) {
  return Endpoint.delete<void>(`@gc/proposal-template/${id}`);
}

/** Items **/

export function getItem(id: ItemId, type: string, isPublic = false) {
  if (isPublic) {
    return Endpoint.get<ProposalTemplateItemResponse>(`@gc/public/proposal-template/item/${id}`);
  }
  return Endpoint.get<ProposalTemplateItemResponse>(`@gc/proposal-template/item/${id}`);
}

export function getItemPublic(id: ItemId) {
  return Endpoint.get<ProposalTemplateItemResponse>(`@gc/public/proposal-template/item/${id}`);
}

export function createItem(data: Partial<ProposalTemplateItemResponse>, parentId?: FolderId) {
  data.parentFolderId = parentId;
  return Endpoint.post<ProposalTemplateItemResponse[]>(`@gc/proposal-template/item`, { formData: CommonService.toFormData(data), hasFiles: true });
}

export function duplicateItem(id: ItemId, name: string) {
  return Endpoint.post<ProposalTemplateItemResponse>(`@gc/proposal-template/duplicate/${id}`, { data: { name } });
}

export function updateItem(data: Partial<ProposalTemplateItemResponse>, itemId: ItemId) {
  return Endpoint.post<ProposalTemplateItemResponse>(`@gc/proposal-template/update-item/${itemId}`, {
    formData: CommonService.toFormData(data),
    hasFiles: true,
  });
}

export function deleteItem(id: ItemId) {
  return Endpoint.delete<void>(`@gc/proposal-template/item/${id}`);
}

export function getProposalTemplateItems(type: string) {
  return Endpoint.get<CompactProposalTemplateItemResponse[]>(`@gc/proposal-template/items`, { params: { type } });
}

export function folderStructure(folderId: FolderId) {
  return Endpoint.get<ProposalTemplateFolderStructureResponse>(`@gc/proposal-template/folder-structure`, { params: { folderId } });
}

export function move(id: string, type: ProposalTemplateItemType, destinationId: string) {
  return Endpoint.post<void>(`@gc/proposal-template/move`, { data: { id, type, destinationId } });
}

export function updateFormData(id: ItemId, data: string) {
  return Endpoint.post<ProposalTemplateItemResponse>(`@gc/proposal-template/update-form-data/${id}`, { data: { data } });
}
