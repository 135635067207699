import { useHistory } from '@core/router';
import { R } from '@core/utils/r';
import { Breadcrumbs } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useReactRouterBreadcrumbs from './ReactRouterBreadcrumbs';
import './index.scss';

export const Breadcrumb = memo(() => {
  const rawbreadcrumbs = useReactRouterBreadcrumbs(undefined, undefined, location);
  const breadcrumbs = useMemo(() => {
    const last = R.last(rawbreadcrumbs);
    if (last) {
      const lastVal = R.last(R.split(last.key.toString(), '/'));

      if (R.toNumber(lastVal) > 0) {
        return R.initial(rawbreadcrumbs);
      }
    }
    return rawbreadcrumbs;
  }, [rawbreadcrumbs]);

  const links = useMemo(() => R.initial(breadcrumbs), [breadcrumbs]);
  const last = useMemo(() => R.last(breadcrumbs), [breadcrumbs]);

  if (breadcrumbs.length < 2) return null;
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className="breadcrumb-inheret"
      classes={{
        root: 'breadcrumb-classes',
        ol: 'breadcrumb m-0',
        li: 'breadcrumb-item',
      }}
    >
      {links.map(({ match, breadcrumb }) => (
        <Link key={match.url} color="inherit" to={match.url}>
          {breadcrumb}
        </Link>
      ))}
      {!!last && <div>{last.breadcrumb}</div>}
    </Breadcrumbs>
  );
});
