import { Endpoint } from '../endpoint';
import { AssistantId, AssistantResponse } from '@business/entities/assistant';

export function fetchAssistants(search?: string) {
  return Endpoint.get<AssistantResponse[]>(`@gc/assistants`, { params: { search: '' } });
}

export function fetchAssistant(id: AssistantId) {
  return Endpoint.get<AssistantResponse>(`@gc/assistants/assistant/${id}`);
}

export function createAssistant(data: Partial<AssistantResponse>) {
  return Endpoint.post<AssistantResponse>(`@gc/assistants`, { data });
}

export function updateAssistant(id: AssistantId, data: Partial<AssistantResponse>) {
  return Endpoint.patch<AssistantResponse>(`@gc/assistants/${id}`, { data });
}

export function deleteAssistant(id: AssistantId) {
  return Endpoint.delete<void>(`@gc/assistants/${id}`);
}
