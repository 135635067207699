import { useModalInstance } from '@core/components/ModalStack';
import { Theme } from '@core/utils/css';
import { R } from '@core/utils/r';
import { ClassNames, css, Global } from '@emotion/react';
import { Dialog, DialogProps } from '@mui/material';
import React, { CSSProperties, Fragment, memo, PropsWithChildren, useMemo } from 'react';

const styles = {
  global: css`
    .MuiPopover-root {
      .MuiMenuItem-root {
        font-size: 15px;
        font-family: ${Theme.fontFamily};
      }
    }
  `,

  container: css`
    &,
    .MuiButton-label,
    .MuiTab-wrapper {
      font-family: ${Theme.fontFamily};
    }
  `,
};

export interface ModalProps {
  backdropClose?: boolean;
  fullWidth?: DialogProps['fullWidth'];
  maxWidth?: DialogProps['maxWidth'] | number | (string & {});
  scroll?: DialogProps['scroll'];
  borderRadius?: number;
  className?: string;
  hideBackdrop?: boolean;
  disableEnforceFocus?: boolean;
  disableScrollLock?: boolean;
  paperStyle?: CSSProperties;
  onClose?(): void;
  fullScreen?: boolean;
}

export const Modal = memo((props: PropsWithChildren<ModalProps>) => {
  const modal = useModalInstance();

  const maxWidth = useMemo(() => {
    let maxWidth: string | undefined = undefined;

    if (!props.maxWidth) {
      maxWidth = '790px';
    } else if (R.isNumber(props.maxWidth)) {
      maxWidth = props.maxWidth + 'px';
    } else if (R.isString(props.maxWidth) && (props.maxWidth.endsWith('px') || props.maxWidth.endsWith('%'))) {
      maxWidth = props.maxWidth;
    }

    return maxWidth;
  }, [props.maxWidth]);

  return (
    <Fragment>
      <Global styles={styles.global} />

      <ClassNames>
        {({ css }) => (
          <Dialog
            open
            //onClose={props.backdropClose === false ? undefined : modal.close}
            onClose={
              props.backdropClose === false
                ? undefined
                : () => {
                    modal.close();
                    props.onClose && props.onClose();
                  }
            }
            fullScreen={props.fullScreen}
            fullWidth={props.fullWidth !== false}
            maxWidth={maxWidth ? false : (props.maxWidth as any)}
            scroll={props.scroll}
            classes={{
              container: css(styles.container),
              paper: css({
                maxWidth: `${maxWidth} !important`,
                borderRadius: props.borderRadius && `${props.borderRadius}px !important`,
                ...(props.paperStyle || ({} as any)),
              }),
            }}
            className={props.className}
            hideBackdrop={props.hideBackdrop}
            disablePortal
            disableAutoFocus
            disableRestoreFocus
            disableEnforceFocus
          >
            {props.children}
          </Dialog>
        )}
      </ClassNames>
    </Fragment>
  );
});
