import { Proposal } from '../Blocks/types';
import { useImperativeHandle, useEffect } from 'react';
import { openBlockSettingsOverlay } from '../Blocks/Common/BlockBaseConfig';
import { useLazyEffect } from '@core/utils/react';
import { FormGroup } from '@core/utils/form';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { useProposalBlockContext } from '../ProposalBuilderPage';
import { of } from 'rxjs';

export interface ConfigureBlockHook<P extends any> {
  props: Proposal.BlockProps<any>;
  form?: FormGroup<any>;
  contentComponent?: React.MemoExoticComponent<React.ComponentType<P>>;
  contentProps?: P;
  onEdit?: () => void;
  onlyAdvance?: boolean;
}

export function useConfigureBlock<P extends any>(props: ConfigureBlockHook<P>) {
  const { props: bProps, form, contentComponent, contentProps, onlyAdvance } = props;
  const { editable } = bProps;
  const formValue = useNonNilObservable(form ? form.value$ : of(null));
  const { blockForm } = useProposalBlockContext()!;
  const blockFormData = useNonNilObservable(blockForm.value$);

  useImperativeHandle(bProps.handlesRef, () => ({
    openSettings: () => {
      if (props.onEdit) {
        return props.onEdit();
      }
      if (form) {
        openBlockSettingsOverlay({ advanceForm: form.controls.advance, contentComponent, props: contentProps, blockForm });
      }
    },
  }));

  // Proposal CRUD operations
  useLazyEffect(() => {
    if (!!formValue && editable && blockForm.value.id === bProps.id) {
      blockForm.patchValue({ data: formValue });
    }
  }, [editable, blockForm, formValue]);

  useEffect(() => {
    if (form && blockFormData.data) {
      form.patchValue(blockFormData.data);
    }
  }, [blockFormData.data, form]);
}
