import React, { memo, useState, Fragment, useCallback, useMemo, useImperativeHandle } from 'react';
import { Proposal } from '../types';
import { useProposalBlockContext, ProposalBlockFormData, useProposalContext, ProposalEvent } from '@modules/proposal/ProposalBuilderPage';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { classnames } from '@core/utils/css';
import './base.scss';
import { Transition, CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';
import { openAddProposalWidgetOverlay } from '@modules/proposal/ProposalBuilderPage/Components/AddWidgetOverlay';
import { R } from '@core/utils/r';
import { VariationSelector } from './VariationSelector';
import { usePopperAnchor } from '@core/components/Popper';
import { Config } from '@config';
import { WithTooltip } from '@modules/common';

export interface BlockToolbarProps {
  menuOrder: number;
  isLast: boolean;
  onDelete?: () => void;
  onCopy?: () => void;
  onEdit?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onSection?: () => void;
  onLock?: () => void;
  block: ProposalBlockFormData;
  onVariationChange: (variation: string) => void;
  partiallyEditable?: boolean;
}

export const BlockToolbar = memo((props: BlockToolbarProps) => {
  const {
    menuOrder,
    isLast,
    onDelete,
    onCopy,
    onEdit,
    onMoveUp,
    onMoveDown,
    block,
    onVariationChange,
    onSection,
    onLock,
    partiallyEditable = false,
  } = props;
  const config = Proposal.BLOCK_CONFIGS[block.type];
  const hasVariations = config.variations.length > 1;
  const anchor = usePopperAnchor();

  return (
    <div className="block-toolbar" style={{ fontFamily: 'Poppins' }}>
      <div className="block-toolbar__left">
        <div className="block-toolbar__item" onClick={anchor.onClick}>
          {R.startCase(block.variation)}
          {hasVariations && <i className="ml-2 fa fa-chevron-down"></i>}
        </div>
        {hasVariations && <VariationSelector anchor={anchor} config={config} variation={block.variation} onVariationChange={onVariationChange} />}
        <div className="block-toolbar__item" onClick={onEdit}>
          Edit
        </div>
        <div className="block-toolbar__item" onClick={onSection}>
          {block.isSection ? (
            <div>
              <i className="fa fa-check"></i> Section
            </div>
          ) : (
            'Make Section'
          )}
        </div>
        {!Config.IS_ADMIN && !partiallyEditable && (
          <div className="block-toolbar__item" onClick={onLock}>
            {!block.locked && (
              <WithTooltip tooltip="Click this button to lock this section so that when someone sends it for signing, they will not be able to edit this section.">
                <div>
                  <i className="fa fa-lock mr-2"></i> Lock
                </div>
              </WithTooltip>
            )}
            {block.locked && (
              <WithTooltip tooltip="Click this button to unlock this section that when someone sends the it for signing, they are able to modify this block of content.">
                <div>
                  <i className="fa fa-unlock mr-2"></i> Unlock
                </div>
              </WithTooltip>
            )}
          </div>
        )}
      </div>
      <div className="block-toolbar__right">
        {menuOrder > 0 && (
          <div className="block-toolbar__item" onClick={onMoveUp}>
            <i className="fa fa-arrow-up"></i>
          </div>
        )}
        {!isLast && (
          <div className="block-toolbar__item" onClick={onMoveDown}>
            <i className="fa fa-arrow-down"></i>
          </div>
        )}
        <div className="block-toolbar__item" onClick={onCopy}>
          <i className="fa fa-copy"></i>
        </div>
        <div className="block-toolbar__item" onClick={onDelete}>
          <i className="fa fa-trash-alt"></i>
        </div>
      </div>
    </div>
  );
});

export interface AddButtonProps {
  onAdd: (type: Proposal.BlockType, variation: string) => void;
}

export const AddButton = memo((props: AddButtonProps) => {
  const { onAdd } = props;

  return (
    <div className="pb-add" onClick={() => openAddProposalWidgetOverlay({ onAdd })}>
      <i className="fa fa-plus"></i>
    </div>
  );
});

export interface BlockBaseHandle {
  openSettings(): void;
}

export interface BlockBaseProps {
  isLast: boolean;
  isFirst: boolean;
  onAdd(type: Proposal.BlockType, variation: string, at?: number): void;
  onDelete(id: string): void;
  onMove(id: string, direction: number): void;
  onCopy(id: string): void;
  partiallyEditable?: boolean;
  handleRef?: React.Ref<BlockBaseHandle>;
}

export const BlockBase = React.forwardRef<HTMLDivElement, BlockBaseProps>((props, forwardRef) => {
  const { isLast, isFirst, onAdd, onDelete, onMove, onCopy, partiallyEditable = false } = props;
  const { form } = useProposalContext()!;
  const { blockForm, blockIndex } = useProposalBlockContext()!;
  const [hover, setHover] = useState(false);
  const formValue = useNonNilObservable(blockForm.value$);
  const config = Proposal.BLOCK_CONFIGS[formValue.type];
  const handleRef = React.useRef<Proposal.BlockHandle>(null);

  const openSettings = useCallback(() => handleRef.current?.openSettings(), [handleRef]);
  useImperativeHandle(props.handleRef, () => ({ openSettings }), [openSettings]);

  const onSection = useCallback(() => {
    blockForm.patchValue({ isSection: !formValue.isSection });
  }, [blockForm, formValue]);

  const onLock = useCallback(() => {
    blockForm.patchValue({ locked: !formValue.locked });
  }, [blockForm, formValue]);

  const canEdit = useMemo(() => (partiallyEditable ? !formValue.locked : true), [partiallyEditable, formValue.locked]);
  if (!config) return null;

  const { component: BlockComponent } = config;

  return (
    <div
      id={formValue.id}
      ref={forwardRef}
      className={classnames('base-block-container', { hover, isLast })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <BlockComponent id={formValue.id} variation={formValue.variation} editable={canEdit} handlesRef={handleRef} />
      {canEdit && (
        <CSSTransition in={hover} timeout={300} classNames="block-toolbar-animate" unmountOnExit>
          <BlockToolbar
            menuOrder={formValue.menuOrder}
            isLast={form.value.blocks.length === blockIndex + 1}
            onDelete={() => onDelete(formValue.id)}
            onMoveUp={() => onMove(formValue.id, -1)}
            onMoveDown={() => onMove(formValue.id, 1)}
            onCopy={() => onCopy(formValue.id)}
            onSection={() => onSection()}
            onLock={() => onLock()}
            block={formValue}
            onVariationChange={variation => blockForm.patchValue({ variation })}
            onEdit={() => handleRef?.current?.openSettings()}
            partiallyEditable={partiallyEditable}
          />
        </CSSTransition>
      )}
      {!isLast && canEdit && (
        <CSSTransition in={hover} timeout={300} classNames="block-toolbar-add-animate" unmountOnExit>
          <AddButton onAdd={(type, variation) => onAdd(type, variation, formValue.menuOrder + 1)} />
        </CSSTransition>
      )}
    </div>
  );
});
