import { ModalContent, ModalDrawer, ModalHeader } from '@core/components/Modal';
import React, { memo, useMemo, useState, useCallback } from 'react';
import { useNonNilObservable, useObservable } from '@core/utils/hooks/rxjs';

import { ProposalForm } from '../../ProposalForm';
import { FormInput, FormColorPicker, FormSelect, FormAjaxSelect } from '@core/components/form';
import { useBloc } from '@core/utils/bloc';
import { CommonService, ProposalService } from '@business/services';
import { GoogleAPI } from '@business/api/google_api';
import { FontResponse } from '@business/entities/google';
import { map } from 'rxjs/operators';
import { Col, Row } from 'reactstrap';
import { css } from '@emotion/react';

const styles = {
  content: css`
    padding: 25px !important;
    background-color: #f8f8fb;
  `,
};

export interface ProposalSettingsOverlayProps {
  form: ProposalForm;
}

export const ProposalSettingsOverlay = memo((props: ProposalSettingsOverlayProps) => {
  const { form } = props;
  const formValue = useNonNilObservable(form.value$);

  const onSearch = useCallback((search: string) => {
    return GoogleAPI.fonts(search).pipe(map(fonts => CommonService.selectItems(fonts, 'family')));
  }, []);

  return (
    <ModalDrawer maxWidth="lg">
      <ModalHeader title="Settings" />
      <ModalContent customCss={styles.content}>
        <FormInput control={form.controls.title} />
        <Row>
          <Col>
            <FormAjaxSelect
              control={form.controls.font}
              callback={search => onSearch(search)}
              initalValue={formValue.font ? { label: formValue.font.family, value: formValue.font } : undefined}
            />
          </Col>
          <Col>
            <FormSelect control={form.controls.fontWeight} items={ProposalService.getAllFontWeight()} isClearable />
          </Col>
        </Row>
        <FormInput control={form.controls.fontSize} />
        <FormInput control={form.controls.headingFontSize} />
        <FormColorPicker control={form.controls.primaryColor} />
      </ModalContent>
    </ModalDrawer>
  );
});
