import React, { memo, useCallback } from 'react';
import './toolbar.scss';
import { ProposalService } from '@business/services';
import { Proposal } from '@modules/proposal/Blocks/types';
import { TextBlockVariation } from '@modules/proposal/Blocks/TextBlock';
import { ImageBlockVariation } from '@modules/proposal/Blocks/ImageBlock';
import { MultimediaBlockVariation } from '@modules/proposal/Blocks/MultimediaBlock';
import { DividerBlockVariation } from '@modules/proposal/Blocks/DividerBlock';
import { openAddProposalWidgetOverlay } from '../AddWidgetOverlay';
import { ListBlockVariation } from '@modules/proposal/Blocks/ListBlock';
import { PricingBlockVariation } from '@modules/proposal/Blocks/PricingBlock';

export interface PageBottomToolbarProps {
  onAdd(type: Proposal.BlockType, variation: string, at?: number): void;
  size: number;
  at?: number;
}

export const PageBottomToolbar = memo((props: PageBottomToolbarProps) => {
  const { size, at } = props;

  const onAdd = useCallback(
    (category: Proposal.BlockCategory, variation?: string) => {
      switch (category) {
        case Proposal.BlockCategory.text:
          return props.onAdd(Proposal.BlockType.text, TextBlockVariation.paragraphWithHeading, at);
        case Proposal.BlockCategory.image:
          return props.onAdd(Proposal.BlockType.image, ImageBlockVariation.imageCentered, at);
        case Proposal.BlockCategory.multimedia:
          return props.onAdd(Proposal.BlockType.multimedia, MultimediaBlockVariation.video, at);
        case Proposal.BlockCategory.list:
          return props.onAdd(Proposal.BlockType.list, ListBlockVariation.numberedList, at);
        case Proposal.BlockCategory.divider:
          return props.onAdd(Proposal.BlockType.divider, DividerBlockVariation.divider, at);
        case Proposal.BlockCategory.pricing:
          return props.onAdd(Proposal.BlockType.pricing, PricingBlockVariation.pricing, at);
      }
    },
    [at, props],
  );

  return (
    <div className="proposal-bottom-toolbar" style={{ paddingTop: 30, paddingBottom: 30 }}>
      <div className="bottom-all-blocks" onClick={() => openAddProposalWidgetOverlay({ onAdd: props.onAdd, at })}>
        <i className="fa fa-th"></i>
        <span>All Blocks</span>
      </div>
      <div className="bottom-block-list">
        {ProposalService.allBlockCategories(true).map(category => (
          <div key={category} className="block-tookbar-item" onClick={() => onAdd(category)}>
            <i className={Proposal.CategoryIcon[category]}></i>
            <span>{category === Proposal.BlockCategory.multimedia ? 'Video' : category}</span>
          </div>
        ))}
      </div>
    </div>
  );
});
