import { formGroup, formControl, Validators, FormDataType } from '@core/utils/form';
import { UserId } from '@business/entities/account';
import { DealIntervalResponse } from '@business/entities/deal';

export const defineProposalLineItemForm = (classIdRequired?: boolean) => {
  return formGroup({
    service: formControl<string>({ required: true, placeholder: 'Product/Service' }),
    description: formControl<string>({ required: true, placeholder: 'Description' }),
    qty: formControl<number>({ placeholder: '0', initialValue: 1, required: true, validators: [Validators.min(0)] }),
    rate: formControl<number>({ placeholder: '0.00', required: true }),
    classId: formControl<string>({ placeholder: 'Select Class', required: classIdRequired }),
    salesReps: formControl<UserId[]>({}),
    taxable: formControl<boolean>({ initialValue: false }),
    isOptional: formControl<boolean>({ label: 'Optional Item', initialValue: false }),
    frequency: formControl<DealIntervalResponse>({ label: 'Interval', initialValue: DealIntervalResponse.onetime }),
    flexibleQty: formControl<boolean>({ label: 'Multiple quantities', initialValue: false }),
    selected: formControl<boolean>({ initialValue: true }),

    id: formControl<number>(),
  });
};

export type LineItemForm = ReturnType<typeof defineProposalLineItemForm>;
export type LineItemFormData = FormDataType<typeof defineProposalLineItemForm>;
