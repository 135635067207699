import React, { ComponentType } from 'react';
import { MemoExoticComponent, RefObject } from 'react';
import { TextBlock, TextBlockVariation } from './TextBlock';
import { ImageBlock, ImageBlockVariation } from './ImageBlock';
import { MultimediaBlock, MultimediaBlockVariation } from './MultimediaBlock';
import { DividerBlock, DividerBlockVariation } from './DividerBlock';
import { StatementBlock, StatementBlockVariation } from './StatementBlock';
import { ListBlock, ListBlockVariation } from './ListBlock';
import { PricingBlock, PricingBlockVariation } from './PricingBlock';
import { QuoteBlock, QuoteBlockVariation } from './QuoteBlock';
import { GalleryBlock, GalleryBlockVariation } from './GalleryBlock';
import { ChartBlock, ChartBlockVariation } from './ChartBlock';
import { EmbedBlock, EmbedBlockVariation } from './EmbedBlock';
import { SignerBlock, SignerBlockVariation } from './SignerBlock';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Proposal {
  export enum PageType {
    general = 'general',
    pricing = 'pricing',
  }
  export enum BlockType {
    text = 'text',
    image = 'image',
    multimedia = 'multimedia',
    divider = 'divider',
    statement = 'statement',
    list = 'list',
    pricing = 'pricing',
    quote = 'quote',
    gallery = 'gallery',
    chart = 'chart',
    embed = 'embed',
    signer = 'signer',
  }

  export enum BlockCategory {
    text = 'Text',
    image = 'Image',
    list = 'List',
    multimedia = 'Multimedia',
    divider = 'Divider',
    statement = 'Statement',
    pricing = 'Pricing',
    quote = 'Quote',
    gallery = 'Gallery',
    chart = 'Chart',
    signer = 'Signer',
  }

  export const CategoryIcon: Record<BlockCategory, string> = {
    [BlockCategory.text]: 'fa fa-font',
    [BlockCategory.image]: 'fa fa-image',
    [BlockCategory.list]: 'fa fa-list',
    [BlockCategory.multimedia]: 'fa fa-video',
    [BlockCategory.divider]: 'fa fa-minus',
    [BlockCategory.pricing]: 'fa fa-shopping-cart',
    [BlockCategory.statement]: 'fa fa-paragraph',
    [BlockCategory.quote]: 'fa fa-quote-left',
    [BlockCategory.gallery]: 'fa fa-images',
    [BlockCategory.chart]: 'fa fa-chart-line',
    [BlockCategory.signer]: 'fa fa-signature',
  };

  export interface BlockHandle {
    openSettings: () => void;
  }

  export interface BlockProps<V> {
    id: string;
    handlesRef?: RefObject<BlockHandle>;
    variation: V;
    editable?: boolean;
    preview?: boolean;
  }

  export interface BlockConfig<V = any, C = MemoExoticComponent<ComponentType<BlockProps<V>>>> {
    type: BlockType;
    category: BlockCategory;
    component: C;
    variations: V[];
  }

  export const BLOCK_CONFIGS: Record<BlockType, BlockConfig> = {
    text: {
      type: BlockType.text,
      category: BlockCategory.text,
      component: TextBlock,
      variations: [
        TextBlockVariation.heading,
        TextBlockVariation.paragraphWithHeading,
        TextBlockVariation.paragraphWithSubheading,
        TextBlockVariation.paragraph,
        TextBlockVariation.subheading,
        TextBlockVariation.twoColumn,
        TextBlockVariation.threeColumn,
        TextBlockVariation.fourColumn,
      ],
    },
    image: {
      type: BlockType.image,
      category: BlockCategory.image,
      component: ImageBlock,
      variations: [
        ImageBlockVariation.imageCentered,
        ImageBlockVariation.imageFullWidth,
        ImageBlockVariation.imageWithText,
        ImageBlockVariation.textOnImage,
      ],
    },
    multimedia: {
      type: BlockType.multimedia,
      category: BlockCategory.multimedia,
      component: MultimediaBlock,
      variations: [MultimediaBlockVariation.video],
    },
    divider: {
      type: BlockType.divider,
      category: BlockCategory.divider,
      component: DividerBlock,
      variations: [DividerBlockVariation.divider, DividerBlockVariation.numbersDivider],
    },
    statement: {
      type: BlockType.statement,
      category: BlockCategory.statement,
      component: StatementBlock,
      variations: [
        StatementBlockVariation.statementA,
        StatementBlockVariation.statementB,
        StatementBlockVariation.statementC,
        StatementBlockVariation.statementD,
        StatementBlockVariation.note,
      ],
    },
    list: {
      type: BlockType.list,
      category: BlockCategory.list,
      component: ListBlock,
      variations: [ListBlockVariation.numberedList, ListBlockVariation.bulletedList],
    },
    pricing: {
      type: BlockType.pricing,
      category: BlockCategory.pricing,
      component: PricingBlock,
      variations: [PricingBlockVariation.pricing],
    },
    quote: {
      type: BlockType.quote,
      category: BlockCategory.quote,
      component: QuoteBlock,
      variations: [
        QuoteBlockVariation.quoteA,
        QuoteBlockVariation.quoteB,
        QuoteBlockVariation.quoteC,
        QuoteBlockVariation.quoteD,
        QuoteBlockVariation.quoteOnImage,
      ],
    },
    gallery: {
      type: BlockType.gallery,
      category: BlockCategory.gallery,
      component: GalleryBlock,
      variations: [
        GalleryBlockVariation.carousel,
        GalleryBlockVariation.twoColumnGrid,
        GalleryBlockVariation.threeColumnGrid,
        GalleryBlockVariation.fourColumnGrid,
      ],
    },
    chart: {
      type: BlockType.chart,
      category: BlockCategory.chart,
      component: ChartBlock,
      variations: [ChartBlockVariation.lineChart, ChartBlockVariation.pieChart, ChartBlockVariation.barChart],
    },
    embed: {
      type: BlockType.embed,
      category: BlockCategory.multimedia,
      component: EmbedBlock,
      variations: [EmbedBlockVariation.embed],
    },
    signer: {
      type: BlockType.signer,
      category: BlockCategory.signer,
      component: SignerBlock,
      variations: [SignerBlockVariation.Signer],
    },
  };
}
