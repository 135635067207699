import React, { memo, useState, useMemo, useEffect, ReactNode, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { classnames } from '@core/utils/css';
import { useRouteParam, useHistory } from '@core/router';
import { R } from '@core/utils/r';

export interface TabHeaderItem {
  title: string;
  leftIcon?: string;
  rightIcon?: string;
  leftHTML?: React.ReactNode;
  rightHTML?: React.ReactNode;
  route?: string;
}

export interface TabsHeaderProps {
  tabs: TabHeaderItem[];
  initialIndex?: number;
  pills?: boolean;
  simple?: boolean;
  routeParam?: string;
  route?(tab: string): string;
  isModal?: boolean;
  onChange?(index: number): void;
  index?: number;
  children?: ReactNode;
  right?: ReactNode;
}

export const TabsHeader = memo((props: TabsHeaderProps) => {
  const { tabs, pills = false, simple = false, isModal = false, onChange, route, routeParam } = props;
  let routeTabParam = '';
  if (!isModal && props.routeParam) {
    routeTabParam = useRouteParam(props.routeParam || 'randomRouteParam', true);
  }
  const history = useHistory();
  const [lastRoute, setLastRoute] = useState('');

  const initialIndex = useMemo(() => {
    if (props.initialIndex) {
      return props.initialIndex;
    } else if (props.routeParam && routeTabParam) {
      return tabs.findIndex(t => t.route === routeTabParam) || 0;
    }
    return 0;
  }, [props.initialIndex, props.routeParam, routeTabParam, tabs]);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(initialIndex);

  useEffect(() => {
    if (!R.isUndefined(props.index)) {
      setActiveTabIndex(props.index);
    }
  }, [props.index]);

  useEffect(() => {
    const tab = tabs[activeTabIndex];
    if (route && tab && tab.route && routeParam) {
      const url = route(tab.route);
      if (url !== lastRoute) {
        setLastRoute(url);
        history.replace(url);
      }
    }
  }, [activeTabIndex, history, lastRoute, route, routeParam, tabs]);

  useEffect(() => {
    onChange && onChange(activeTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  const navClasses = useMemo(() => {
    const items = [];
    if (pills) {
      items.push('navtab-bg');
    } else {
      if (!simple) {
        items.push('nav-tabs-custom');
      }
    }
    return items;
  }, [pills, simple]);

  const navBar = (
    <Fragment>
      <Nav pills={pills} tabs={!pills} className={classnames(navClasses)}>
        {tabs.map((tab, index) => (
          <NavItem key={`tab-${index}`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTabIndex === index,
              })}
              onClick={() => {
                if (props.index !== undefined && props.onChange) {
                  props.onChange(index);
                } else {
                  setActiveTabIndex(index);
                }
              }}
            >
              {tab.leftIcon && <i className={tab.leftIcon}></i>}
              {tab.leftHTML}
              <span className={`${tab.leftIcon || tab.rightIcon ? 'd-none d-sm-block' : ''} `}>{tab.title}</span>
              {tab.rightHTML}
              {tab.rightIcon && <i className={tab.rightIcon}></i>}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div className="d-flex align-items-center justify-content-end" style={{ flex: 1 }}>
        {props.right}
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      {props.right ? <div className="d-flex">{navBar}</div> : navBar}
      {!!props.children && (
        <TabContent>
          <TabPane className="py-3">{props.children}</TabPane>
        </TabContent>
      )}
    </Fragment>
  );
});
