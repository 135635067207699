import Noty from 'noty';
import { t } from 'ttag';
import { R } from '@core/utils/r';
import { getEndpointResponseErrorMessage } from '@business/api/endpoint';
import { Theme } from '@core/utils/css';
import { ButtonIntent } from '../Button';

export interface FlashMessageButton {
  text: string;
  intent: ButtonIntent;
  callback: any;
}

export interface MesssageOptions {
  autoHide?: boolean;
  duration?: number;
  detectNetworkStatus?: boolean;
  buttons?: FlashMessageButton[];
  killer?: boolean;
  progressBar?: boolean;
  forceTimeout?: boolean;
  layout?:
    | 'top'
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'center'
    | 'centerLeft'
    | 'centerRight'
    | 'bottom'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
}

function getIntentColor(intent: ButtonIntent) {
  switch (intent) {
    case 'primary':
      return 'btn btn-primary';
    case 'secondary':
      return 'btn btn-secondary';
    case 'success':
      return 'btn btn-success';
    case 'info':
      return 'btn btn-info';
    case 'warning':
      return 'btn btn-warning';
    case 'danger':
      return 'btn btn-danger';
    case 'dark':
      return 'btn btn-dark';
    case 'light':
      return 'btn btn-light';
    case 'link':
      return 'btn btn-link';
  }
}

export function button(text: string, intent: ButtonIntent, callback: any): FlashMessageButton {
  return { text, intent, callback };
}

export type MessageType = 'alert' | 'info' | 'success' | 'danger' | 'warning';

export const FlashMessage = {
  show,
  info,
  success,
  warning,
  danger,
  error,
  button,
};

const ICONS: Record<MessageType, string> = {
  alert: '',
  info: 'fal fa-info-circle',
  success: 'fal fa-check-circle',
  warning: 'fal fa-exclamation-triangle',
  danger: 'fal fa-exclamation-circle',
};

export function show(type: MessageType, text: string, subtext?: string, options?: MesssageOptions) {
  //   if (text) {
  //     text = R.addDot(text);
  //   }

  if (subtext) {
    subtext = R.addDot(subtext);
  }

  if (text && !subtext) {
    subtext = text;
    text = '';

    if (type === 'info') text = t`Information`;
    if (type === 'success') text = t`Success`;
    if (type === 'warning') text = t`Warning`;
    if (type === 'danger') text = t`Error`;
  }

  let html = R.concat([text && `<div class="noty_title">${text}</div>`, subtext && `<div class="noty_text">${subtext}</div>`]).join('');
  const hasButtons = options && options.buttons;

  const icon = ICONS[type];
  const layout = options?.layout ? options?.layout : hasButtons ? 'topRight' : 'bottomLeft';

  html = `
    ${icon ? `<i class="${icon}"></i>` : ''}
    <div class="noty_content" style="font-family: ${Theme.fontFamily};">${html}</div>
  `;

  const noty = new Noty({
    type: type === 'danger' ? 'error' : type,
    text: html,
    timeout: options?.autoHide === false ? false : options?.duration || (hasButtons ? 7500 : 5000),
    progressBar: options?.progressBar === true ? true : !hasButtons,
    closeWith: ['button', 'click'],
    layout: layout,
    killer: options?.killer,
    buttons: hasButtons
      ? options!.buttons!.map(btn => {
          return Noty.button(btn.text, getIntentColor(btn.intent), () => {
            btn.callback();
            noty.close();
          });
        })
      : undefined,
  });

  noty.show();

  return noty;
}

export function info(text: string, subtext?: string, options?: MesssageOptions) {
  return show('info', text, subtext, options);
}

function success(text: string, subtext?: string, options?: MesssageOptions) {
  return show('success', text, subtext, options);
}

function warning(text: string, subtext?: string, options?: MesssageOptions) {
  return show('warning', text, subtext, options);
}

function danger(text: string, subtext?: string, options?: MesssageOptions) {
  return show('danger', text, subtext, options);
}

function error(error: any, text: string, subtext?: string, options?: MesssageOptions) {
  if (error) {
    console.log('[FlashMessage] error:', error, options);
  }

  const online = R.isBoolean(window.navigator?.onLine) ? window.navigator.onLine : true;

  if (!online && (!options || options.detectNetworkStatus !== false)) {
    text = t`Unable to connect to the servers.`;
    subtext = t`Please check your internet connection and try again.`;
  } else {
    error = getEndpointResponseErrorMessage(error);

    if (subtext || error) {
      subtext = R.compact([subtext, error]).join('. ');
    }
  }

  return danger(text, subtext, options);
}
