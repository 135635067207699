import React, { memo } from 'react';
import { StatementBlockForm } from './StatementBlock';

export interface StatementBlockSettingsProps {
  form: StatementBlockForm;
}

export const StatementBlockSettings = memo((props: StatementBlockSettingsProps) => {
  return <div>Statement Bloc settings</div>;
});
