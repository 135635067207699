import { IntegrationAPI } from '@business/api/integration_api';
import { IntegrationResponse } from '@business/entities/integration';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';

export class IntegrationBloc extends BaseBloc {
  private readonly integrationRepo = new Repository<IntegrationResponse>({
    getItemId: i => i.id,
  });

  readonly integrations$ = this.integrationRepo.items$;
  readonly selectItem = this.integrationRepo.selectItem;

  onReset() {
    this.integrationRepo.reset();
  }

  fetchIntegrations = () => {
    return IntegrationAPI.fetchIntegrations().pipe(
      this.integrationRepo.ops.reset(),
      handleMessage({ error: requestMessage('fetch_integrations_error') }),
    );
  };

  fetchIntegration = (id: string) => {
    return IntegrationAPI.fetchIntegration(id).pipe(
      this.integrationRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_integrations_error') }),
    );
  };

  updateIntegration = (id: string, integration: Partial<IntegrationResponse>) => {
    return IntegrationAPI.updateIntegration(id, integration).pipe(
      this.integrationRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_integration'),
      }),
    );
  };
}
