// import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserResponse } from '@business/entities/account';
import { Endpoint, getGrandCentralAuth } from '../endpoint';

export function login(email: string, password: string, remember = false) {
  const data = { email, password, keepalive: remember };

  return Endpoint.post<UserResponse>('@gc/auth/login', { data });
}

export function profile(token: string) {
  return Endpoint.get<UserResponse>('@gc/auth/check', { auth: getGrandCentralAuth(token) }).pipe(map(user => ({ user })));
}

export function reset(email: string) {
  const data = { email };

  return Endpoint.post<void>('@gc/auth/reset', { data });
}

export function setPassword(email: string, password: string, password_confirmation: string, token: string) {
  const data = { email, password, password_confirmation, token };

  return Endpoint.post<void>('@gc/auth/set-password', { data });
}
