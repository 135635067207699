import React, { memo, Component, ExoticComponent, useState, useMemo, useCallback, useEffect } from 'react';
import { Row, Col, NavItem, NavLink, TabContent, TabPane, Card, CardBody } from 'reactstrap';
import { classnames } from '@core/utils/css';
import { R } from '@core/utils/r';
import { Button } from '@core/components/Button';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface WizardMapping {
  title: string;
  component: Component<any> | ExoticComponent<any>;
  props?: any;
  onNext?(): boolean | Observable<boolean>;
}

export interface WizardProps {
  vertical?: boolean;
  steps: WizardMapping[];
  initialIndex?: number;
  onSubmit?(): void;
  submitting?: boolean;
  onTabChange?(index: number): void;
  card?: boolean;
  noPadding?: boolean;
  baseClass?: string;
  hideButton?: boolean;
}

export const Wizard = memo((props: WizardProps) => {
  const {
    initialIndex = 0,
    steps,
    vertical = false,
    onSubmit,
    onTabChange,
    card = false,
    noPadding = false,
    baseClass = 'twitter-bs-wizard',
    hideButton = false,
  } = props;

  const [activeTabIndex, setActiveTabIndex] = useState<number>(initialIndex);

  const navBar = useMemo(() => {
    return (
      <div id="basic-pills-wizard" className={`${baseClass}`}>
        <ul className={`${baseClass}-nav nav nav-pills nav-justified`}>
          {steps.map((tab, index) => (
            <NavItem key={`tab-${index}`}>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({ active: activeTabIndex === index })}
                onClick={() => setActiveTabIndex(index)}
              >
                <span className="step-number mr-2">{index + 1}</span>
                {tab.title}
              </NavLink>
            </NavItem>
          ))}
        </ul>
      </div>
    );
  }, [steps, activeTabIndex, baseClass]);

  const panes = useMemo(
    () => (
      <TabContent activeTab={activeTabIndex} className={`${baseClass}-tab-content`}>
        {steps.map((tab, index) => {
          const TabComponent = tab.component as any;
          return (
            <TabPane tabId={index} className={noPadding ? '' : 'p-3'} key={`pane-${index}`}>
              <TabComponent key={index} {...tab.props} />
            </TabPane>
          );
        })}
      </TabContent>
    ),
    [steps, activeTabIndex, noPadding, baseClass],
  );

  const onNext = useCallback(() => {
    const tab = R.get(steps, activeTabIndex);
    if (tab && tab.onNext) {
      const result = tab.onNext();
      if (!R.isBoolean(result)) {
        result.pipe(tap(res => res && setActiveTabIndex(activeTabIndex + 1))).subscribe();
      } else if (result) {
        setActiveTabIndex(activeTabIndex + 1);
      }
    } else {
      setActiveTabIndex(activeTabIndex + 1);
    }
  }, [steps, activeTabIndex]);

  useEffect(() => onTabChange && onTabChange(activeTabIndex), [onTabChange, activeTabIndex]);

  const footer = useMemo(() => {
    return (
      <div className={`pager wizard ${baseClass}-pager-link d-flex ml-3 mr-3 mb-3`}>
        <div style={{ flex: 1 }} className="d-flex">
          {activeTabIndex !== 0 && <Button onClick={() => setActiveTabIndex(activeTabIndex - 1)}>Previous</Button>}
        </div>
        {activeTabIndex !== steps.length - 1 && <Button onClick={onNext}>Next</Button>}
        {activeTabIndex === steps.length - 1 && (
          <Button loading={props.submitting} onClick={onSubmit}>
            Save
          </Button>
        )}
      </div>
    );
  }, [activeTabIndex, steps.length, onNext, props.submitting, onSubmit, baseClass]);

  if (vertical) {
    return (
      <Row>
        <Col lg={2}>{navBar}</Col>
        <Col lg={10}>
          {panes}
          {footer}
        </Col>
      </Row>
    );
  } else {
    return (
      <React.Fragment>
        {card ? (
          <Card>
            <CardBody style={{ padding: '0.5rem' }}>{navBar}</CardBody>
          </Card>
        ) : (
          navBar
        )}

        {panes}
        {!hideButton && footer}
      </React.Fragment>
    );
  }
});
