import { formGroup, formArray, formControl, FormDataType } from '@core/utils/form';
import { defineProposalLineItemForm } from './Components/ProposalProductsOverlay';
import { Proposal } from '../Blocks/types';
import { FontResponse } from '@business/entities/google';
import { defineBlockAdvanceForm } from '../Blocks/Common/AdvanceSettings';

export function defineProposalBlockForm() {
  return formGroup({
    id: formControl<string>({ initialValue: '0' }),
    title: formControl<string | undefined>({ label: 'Block Title (Optional)', placeholder: 'Block Title (Optional)' }),
    isSection: formControl<boolean>({ initialValue: false }),
    type: formControl<Proposal.BlockType>({ placeholder: 'Block Type' }),
    variation: formControl<string>({ placeholder: 'Block Variation' }),
    menuOrder: formControl<number>({ initialValue: 0 }),
    locked: formControl<boolean>({ initialValue: false }),

    data: formControl<any>({ placeholder: 'Block Data' }),
  });
}

export function defineProposalForm() {
  return formGroup({
    title: formControl<string>({ label: 'Title', initialValue: 'Proposal Template' }),
    font: formControl<FontResponse>({ label: 'Font (Google Font)', placeholder: 'Search Google Fonts...' }),
    primaryColor: formControl<string>({ label: 'Primary Color', initialValue: '#45A8F7' }),
    headingFontSize: formControl<number>({ label: 'Default Heading Font Size', initialValue: 35 }),
    fontSize: formControl<number>({ label: 'Default Paragraph Font Size', initialValue: 20 }),

    blocks: formArray(() => defineProposalBlockForm()),
    background: formControl<string>({ label: 'Background Color', initialValue: '' }),

    products: formArray(() => defineProposalLineItemForm()),
    productsAdvance: defineBlockAdvanceForm(),
    fontWeight: formControl<string>({ label: 'Default Font Weight' }),
  });
}

export type ProposalForm = ReturnType<typeof defineProposalForm>;
export type ProposalBlockForm = ReturnType<typeof defineProposalBlockForm>;

export type ProposalFormData = FormDataType<typeof defineProposalForm>;
export type ProposalBlockFormData = FormDataType<typeof defineProposalBlockForm>;
