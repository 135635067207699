import { openModal } from '@core/components/ModalStack';
import { ProposalSettingsOverlayProps } from './ProposalSettingsOverlay';

export async function openProposalSettingsOverlay(props: ProposalSettingsOverlayProps) {
  const { ProposalSettingsOverlay } = await import('./ProposalSettingsOverlay');

  openModal(ProposalSettingsOverlay, props);
}

export * from './ProposalSettingsOverlay';
