/** @jsx jsx */
import React, { memo, useMemo } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import classNames from 'classnames';
import { formGroup, formControl, FormDataType } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { ListBlockSettings } from './ListBlockSettings';
import { Container } from 'reactstrap';
import { jsx } from '@emotion/react';

import { css } from '@emotion/react';
import { useProposalContext, useProposalDataContext } from '@modules/proposal/ProposalBuilderPage';
import { FormHTMLInline } from '@core/components/form';
import { ProposalService } from '@business/services';

function defineListBlockForm() {
  return formGroup({
    text: formControl<string>({
      initialValue: `<ol><li>Vel magna iaculis arcu cursus parturient blandit suscipit taciti auctor maecenas.</li><li>Penatibus libero risus montes conubia pretium quisque ut fermentum, vulputate augue litora ornare fames turpis convallis imperdiet, curae etiam sed enim dictumst purus sit.</li><li>Tortor parturient gravida habitant dapibus et fusce felis nam nibh litora ridiculus aenean porttitor turpis pharetra.</li><li>Suscipit montes natoque aptent ex gravida orci ultrices volutpat nunc.</li></ol>`,
    }),

    listColor: formControl<string>({
      initialValue: '#FF6602',
    }),
    advance: defineBlockAdvanceForm({ paddingTop: 0, paddingBottom: 0 }),
  });
}

export type ListWidgetFormData = FormDataType<typeof defineListBlockForm>;
export type ListWidgetForm = ReturnType<typeof defineListBlockForm>;

export enum ListBlockVariation {
  numberedList = 'numberedList',
  bulletedList = 'bulletedList',
}

export const ListBlock = memo((props: Proposal.BlockProps<ListBlockVariation>) => {
  const { variation, editable } = props;
  const form = useMemo(defineListBlockForm, []);
  const formValue = useNonNilObservable(form.value$);
  const proposal = useProposalContext();
  const primaryColor = useMemo(() => proposal?.form.value.primaryColor, [proposal]);
  const data = useProposalDataContext();

  const listCss = useMemo(() => {
    if (primaryColor) {
      return css`
        li:before {
          background-color: ${primaryColor};
        }
      `;
    } else {
      return css`
        li:before {
          background-color: #45a8f7;
        }
      `;
    }
  }, [primaryColor]);

  useConfigureBlock({
    props,
    form,
    contentComponent: ListBlockSettings,
    contentProps: { form },
  });
  return (
    <Container>
      <BlockContainer className={classNames('d-flex flex-fill', 'block-list', variation)} advanceForm={formValue.advance}>
        <div css={listCss}>
          <FormHTMLInline control={form.controls.text} readonly={!editable} transformedFieldsValue={ProposalService.getTransformedValues(data)} />
        </div>
      </BlockContainer>
    </Container>
  );
});
