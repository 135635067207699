import { Button, ButtonIntent } from '@core/components/Button';
import React, { memo, ReactNode, useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Breadcrumb, CBreadcrumb, CBreadcrumbItem } from '../Breadcrumb';
export interface HeaderButtons {
  title?: string;
  icon?: string;
  children?: React.ReactNode;
  onClick?(): void;
  intent?: ButtonIntent;
  visible?: boolean;
}
export interface PageHeader {
  children: string | ReactNode;
  extraChildren?: ReactNode;
  buttons?: HeaderButtons[];
  breadcrumbItems?: CBreadcrumbItem[];
}

export const PageHeader = memo((props: PageHeader) => {
  const { buttons = [] } = props;
  const buttonsContent = useMemo(() => {
    return buttons
      .filter(b => b.visible !== false)
      .map((btn, index) => {
        if (btn.title) {
          return (
            <Button key={index} intent={btn.intent} onClick={btn.onClick} extraClassNames="ml-2">
              {btn.title}
            </Button>
          );
        } else if (btn.icon) {
          return (
            <a key={index} className="ml-3" onClick={btn.onClick}>
              <i className={btn.icon}></i>
            </a>
          );
        } else if (btn.children) {
          return btn.children;
        }
      });
  }, [buttons]);

  return (
    <Card className="card_page-header">
      <CardBody>
        <div className="pb-0 page-title-box d-flex align-items-center justify-content-between flex-wrap">
          <div className="page-title-left d-flex flex-column">
            <h4 className="mb-0 font-size-18 mb-1 d-flex flex-row">{props.children}</h4>
            <div className="d-flex flex-row">
              {!!props.breadcrumbItems?.length && <CBreadcrumb items={props.breadcrumbItems} />}
              {!props.breadcrumbItems?.length && <Breadcrumb />}
            </div>
          </div>

          <div className="page-title-right d-flex align-items-center flex-wrap">{buttonsContent}</div>
        </div>
        {props.extraChildren}
      </CardBody>
    </Card>
  );
});
