import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';
import { handleMessage, requestMessage } from '@business/messages';
import { showDeleteConfirmation } from '@modules/common';
import { switchMap, map } from 'rxjs/operators';
import { ChangelogAPI } from '@business/api/changelog_api';
import { ChangelogResponse, ChangelogId, ChangelogRequest } from '@business/entities/changelog';
import { R } from '@core/utils/r';

export class ChangelogBloc extends BaseBloc {
  private readonly changelogRepo = new Repository<ChangelogResponse>({
    getItemId: changelog => changelog.id,
  });

  readonly changelogs$ = this.changelogRepo.items$.pipe(map(logs => R.orderBy(logs, 'created', 'desc')));
  readonly selectChangelog = this.changelogRepo.selectItem;

  onReset() {
    this.changelogRepo.reset();
  }

  fetchChangelogs = () => {
    return ChangelogAPI.fetchChangelogs().pipe(this.changelogRepo.ops.reset(), handleMessage({ error: requestMessage('fetch_changelogs_error') }));
  };

  fetchChangelog = (id: ChangelogId) => {
    return ChangelogAPI.fetchChagelog(id).pipe(
      this.changelogRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_changelogs_error') }),
    );
  };

  createChangelog = (changelog: ChangelogRequest) => {
    return ChangelogAPI.createChangelog(changelog).pipe(
      this.changelogRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_changelog'),
      }),
    );
  };

  updateChangelog = (id: ChangelogId, changelog: ChangelogRequest) => {
    return ChangelogAPI.updateChangelog(id, changelog).pipe(
      this.changelogRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_changelog'),
      }),
    );
  };

  deleteChangelog = (id: ChangelogId) => {
    return showDeleteConfirmation('Delete Changelog', 'Do you really want to remove this changelog?').pipe(
      switchMap(() => ChangelogAPI.deleteChangelog(id)),
      this.changelogRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_changelog'),
      }),
    );
  };
}
