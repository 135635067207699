import React, { memo, useMemo, useCallback, useState } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import classNames from 'classnames';
import { formGroup, formControl, FormDataType, formArray } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable, useObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';
import { Container } from 'reactstrap';
import { FormText } from '@core/components/form/FormText';
import { useDidMount } from '@core/utils/hooks/react';
import { useProposalContext, useProposalDataContext } from '@modules/proposal/ProposalBuilderPage';
import { FormInput } from '@core/components/form';
import { Button } from '@core/components/Button';
import { ProposalService } from '@business/services';
import { SignerBlockSettings } from './SignerBlockSettings';
import { ProposalPreviewPageBloc } from '@modules/proposal/ProposalPreviewPage/ProposalPreviewPageBloc';

function defineSignerBlockItemForm() {
  return formGroup({
    sign: formControl<string>({
      placeholder: 'Type here to create signature...',
    }),
    email: formControl<string>({}),
    canSign: formControl<boolean>({}),
    name: formControl<string>({
      initialValue: 'John Doe',
    }),
    menuOrder: formControl<number>({
      initialValue: 0,
    }),
  });
}

function defineSignerBlockForm() {
  return formGroup({
    items: formArray(() => defineSignerBlockItemForm()),
  });
}
export type SignerWidgetItemFormData = FormDataType<typeof defineSignerBlockItemForm>;
export type SignerWidgetFormData = FormDataType<typeof defineSignerBlockForm>;
export type SignerWidgetForm = ReturnType<typeof defineSignerBlockForm>;

export enum SignerBlockVariation {
  Signer = 'Signer',
}

export const SignerBlock = memo((props: Proposal.BlockProps<SignerBlockVariation>) => {
  const { variation, preview, editable } = props;
  const form = useMemo(defineSignerBlockForm, []);
  const formValue = useNonNilObservable(form.value$);
  const itemValues = useObservable(form.controls.items.items$, []);

  const [signature, setSignature] = useState(true);

  const proposal = useProposalContext();
  const previewPageBloc = proposal && (proposal.bloc as ProposalPreviewPageBloc);

  const proposalData = useProposalDataContext();
  const signee = useMemo(() => {
    if (proposalData?.signee) {
      return proposalData.signee;
    } else {
      return null;
    }
  }, [proposalData]);

  const signUser = useCallback(
    (item: SignerWidgetItemFormData) => {
      if (previewPageBloc) {
        previewPageBloc
          .updateSender(signee.id, { isSigned: 1, signedName: item.sign, email: item.email })
          .pipe(tap(() => setSignature(false)))
          .subscribe();
      }
    },
    [signee, previewPageBloc],
  );

  const isNumberOdd = useCallback((num: number) => num % 2, []);

  useDidMount(() => {
    form.controls.items.push().patchValue({ menuOrder: 0 });
  });
  useConfigureBlock({ props, form, contentComponent: SignerBlockSettings, contentProps: { form } });

  return (
    <BlockContainer className={classNames('block-signer', variation, preview)}>
      <Container>
        <div style={{ display: 'grid ', gridTemplateColumns: '1fr 1fr', columnGap: '1em', rowGap: '2em' }}>
          {itemValues.map((ctrl, i) => {
            const canSign = signee && signee.email == ctrl.controls.email.value && signee.can_sign && !signee.is_signed && signature ? false : true;
            return (
              <div className="signer-centered" key={i} style={{ width: '300px', justifySelf: isNumberOdd(i) ? 'flex-end' : 'flex-start' }}>
                <FormText control={ctrl.controls.name} editable={editable} />
                <div className="border bg-light p-2">
                  <i className="fa fa-signature d-inline"></i>
                  <div className="d-inline">
                    <FormInput control={ctrl.controls.sign} editable={editable} disabled={canSign} />
                  </div>
                  {!canSign && signature && (
                    <div className="d-inline">
                      <Button onClick={() => signUser(ctrl.value)} intent="primary">
                        Submit
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </BlockContainer>
  );
});
