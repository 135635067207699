import React, { memo } from 'react';
import { Proposal } from '../types';
import './preview.scss';
import { R } from '@core/utils/r';

export interface BlockPreviewProps {
  config: Proposal.BlockConfig;
  variation: string;
  onClick?: () => void;
  scale?: number;
  withoutLabel?: boolean;
}

export const BlockPreview = memo((props: BlockPreviewProps) => {
  const { config, variation, scale = 1, withoutLabel = false } = props;
  const { component: BlockComponent } = config;
  return (
    <div className="block-preview" style={{ transform: `scale(${scale})` }} onClick={props.onClick}>
      {withoutLabel !== true && <span className="block-variation">{R.startCase(variation)}</span>}
      <div className="inner">
        <BlockComponent variation={variation} editable={false} preview id={'block' + R.random(1, 9999)} />
      </div>
    </div>
  );
});
