import { ReportCategoryId } from './../../entities/ReportCategory/ReportCategoryResponse';
import { showDeleteConfirmation } from '@modules/common';
import { switchMap, tap, map } from 'rxjs/operators';
import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';
import { handleMessage, requestMessage } from '@business/messages';
import { ReportCategoryResponse } from '@business/entities/ReportCategory/ReportCategoryResponse';
import { ReportCategoryAPI } from '@business/api/ReportCategory';

export class ReportCategoryBloc extends BaseBloc {
  private readonly reportCategoryRepo = new Repository<ReportCategoryResponse>({
    getItemId: category => category.id,
  });

  private readonly categoryDetailRepo = new Repository<ReportCategoryResponse>({
    getItemId: category => category.id,
  });

  readonly items$ = this.reportCategoryRepo.items$;
  readonly dItems$ = this.categoryDetailRepo.items$;
  readonly selectCategory = this.reportCategoryRepo.selectItem;

  onReset() {
    this.reportCategoryRepo.reset();
  }

  fetchCategories = () => {
    return ReportCategoryAPI.fetchCategories().pipe(
      this.reportCategoryRepo.ops.upsertMany(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_category_error') }),
    );
  };

  fetchCategory = (id: ReportCategoryId) => {
    return ReportCategoryAPI.fetchCategory(id).pipe(
      this.reportCategoryRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_category_error') }),
    );
  };

  createCategory = (checklist: Partial<ReportCategoryResponse>) => {
    return ReportCategoryAPI.createCategory(checklist).pipe(
      this.reportCategoryRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_category'),
      }),
    );
  };

  updateCategory = (id: ReportCategoryId, data: Partial<ReportCategoryResponse>) => {
    return ReportCategoryAPI.updateCategory(id, data).pipe(
      this.reportCategoryRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_category'),
      }),
    );
  };

  deleteCategory = (id: ReportCategoryId) => {
    return showDeleteConfirmation('Delete Category', 'Do you really want to remove this category?').pipe(
      switchMap(() => ReportCategoryAPI.deleteCategory(id)),
      this.reportCategoryRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_category'),
      }),
    );
  };

  sortCategories = (ids: ReportCategoryId[]) => {
    return ReportCategoryAPI.sortCategories(ids).pipe(
      this.reportCategoryRepo.ops.upsertMany(item => ({ item })),
      handleMessage({
        error: requestMessage('update_template_section_sort'),
      }),
    );
  };
}
