import React, { memo, ReactElement, useMemo, lazy, ComponentType, ExoticComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { RouteContextProvider, useOptionalRouteMatch } from './RouteContext';
import { getRoutePath } from './utils';

export interface LazyRouteProps<T = any> extends RouteProps {
  absolutePath?: string | string[];
  guard?(): ReactElement | null;
  resolve?(): Promise<{ default: T }>;
  component?: ComponentType | ExoticComponent;
  spinner?: boolean;
  computedPath?: string;
}

export const LazyRoute = memo((props: LazyRouteProps) => {
  const { guard, computedPath, path: relativePath, absolutePath, component, resolve, ...rest } = props;

  const content = props.guard ? props.guard() : null;

  const match = useOptionalRouteMatch();

  const routePath = useMemo(() => {
    if (computedPath) return computedPath;

    return getRoutePath(absolutePath, relativePath as any, match?.path);
  }, [match, computedPath, absolutePath, relativePath]);

  const Component = useMemo(() => {
    if (component) return component;
    if (resolve) return lazy(resolve);
    return () => null;
  }, [component, resolve]);

  if (content) return content;

  return (
    <Route
      {...rest}
      path={routePath}
      render={({ match }) => (
        <RouteContextProvider match={match}>
          <Component />
        </RouteContextProvider>
      )}
    />
  );
});
