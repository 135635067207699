import React, { memo, Fragment } from 'react';
import { ImageWidgetForm, ImageBlockVariation } from './ImageBlock';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { Label, Row, Col } from 'reactstrap';
import { FormSelect, FormColorPicker, FormInput } from '@core/components/form';
import { CommonService } from '@business/services';
import { ImagePickerSettings } from '@modules/proposal/ImagePickerSettings';
export interface ImageBlockSettingsProps {
  form: ImageWidgetForm;
  variation: ImageBlockVariation;
}

export const ImageBlockSettings = memo((props: ImageBlockSettingsProps) => {
  const { form, variation } = props;
  const formValue = useNonNilObservable(form.value$);

  return (
    <div>
      <ImagePickerSettings onSelect={image => form.patchValue({ image })} currentImageUrl={formValue.image} />

      {[ImageBlockVariation.imageCentered, ImageBlockVariation.imageWithText].includes(variation) && (
        <Fragment>
          <div className="btn-group btn-group-toggle mb-4 mt-4" data-toggle="buttons">
            <label className={formValue.size === 'fill' ? 'btn btn-primary active' : 'btn btn-light'}>
              <input type="radio" name="options" id="option1" onClick={() => form.patchValue({ size: 'fill' })} /> Stretch
            </label>
            <label className={formValue.size === 'cover' ? 'btn btn-primary active' : 'btn btn-light'}>
              <input type="radio" name="options" id="option2" onClick={() => form.patchValue({ size: 'cover' })} /> Fill
            </label>
            <label className={formValue.size === 'contain' ? 'btn btn-primary active' : 'btn btn-light'}>
              <input type="radio" name="options" id="option3" onClick={() => form.patchValue({ size: 'contain' })} /> Fit
            </label>
          </div>
          <br />
        </Fragment>
      )}
      {variation === ImageBlockVariation.imageWithText && (
        <div>
          <div className="d-flex flex-column">
            <Label>Image Position</Label>
            <div className="btn-group btn-group-toggle mb-4 mt-2" data-toggle="buttons">
              <label className={formValue.imagePosition === 'image-left' ? 'btn btn-primary active' : 'btn btn-light'}>
                <input type="radio" name="options" id="option1" onClick={() => form.patchValue({ imagePosition: 'image-left' })} /> Image on Left
              </label>
              <label className={formValue.imagePosition === 'image-right' ? 'btn btn-primary active' : 'btn btn-light'}>
                <input type="radio" name="options" id="option2" onClick={() => form.patchValue({ imagePosition: 'image-right' })} /> Image on Right
              </label>
            </div>
          </div>

          <FormSelect
            control={form.controls.imageWidth}
            items={CommonService.selectItems(
              [75, 50, 25],
              v => `${v}%`,
              v => `image-w-${v}`,
            )}
          />
        </div>
      )}

      {variation === ImageBlockVariation.textOnImage && (
        <Fragment>
          <Row>
            <Col>
              <FormColorPicker control={form.controls.backdropColor} />
            </Col>
            <Col>
              <FormInput control={form.controls.backdropOpacity} />
            </Col>
          </Row>
        </Fragment>
      )}
    </div>
  );
});
