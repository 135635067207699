import { AssistantAPI } from '@business/api/assistant_api';
import { AssistantId, AssistantResponse } from '@business/entities/assistant';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { R } from '@core/utils/r';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { map, switchMap } from 'rxjs/operators';

export class AssistantBloc extends BaseBloc {
  private readonly repo = new Repository<AssistantResponse>({
    getItemId: item => item.id,
  });

  readonly items$ = this.repo.items$.pipe(map(items => R.sortBy(items, c => c.name)));
  readonly selectItem = this.repo.selectItem;

  onReset() {
    this.repo.reset();
  }

  fetchAssistants = (search?: string) => {
    this.repo.reset();
    return AssistantAPI.fetchAssistants(search).pipe(this.repo.ops.reset(), handleMessage({ error: requestMessage('fetch_assistant_error') }));
  };

  fetchAssistant = (id: AssistantId) => {
    return AssistantAPI.fetchAssistant(id).pipe(
      this.repo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_assistant_error') }),
    );
  };

  createAssistant = (data: Partial<AssistantResponse>) => {
    return AssistantAPI.createAssistant(data).pipe(
      this.repo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_assistant'),
      }),
    );
  };

  updateAssistant = (id: AssistantId, data: Partial<AssistantResponse>) => {
    return AssistantAPI.updateAssistant(id, data).pipe(
      this.repo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_assistant'),
      }),
    );
  };

  deleteAssistant = (id: AssistantId) => {
    return showDeleteConfirmation('Delete Assistant', 'Do you really want to cancel this assistant?', { btnLabel: 'Yes, Delete' }).pipe(
      switchMap(() => AssistantAPI.deleteAssistant(id)),
      this.repo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_assistant'),
      }),
    );
  };
}
