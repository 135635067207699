export { ProposalBuilderPage as default } from './ProposalBuilderPage';
export * from './ProposalContext';
export * from './ProposalForm';

export enum ProposalEvent {
  blockAdded = 'blockAdded',
  blockRemoved = 'blockRemoved',
  blockUpdated = 'blockUpdated',
  blockOrder = 'blockOrder',
  pageAdded = 'pageAdded',
  pageRemoved = 'pageRemoved',
  pageOrder = 'pageOrder',
}
