import React, { memo, useState, useMemo } from 'react';
import './index.scss';
import { classnames } from '@core/utils/css';
import { useProposalContext } from '../../ProposalContext';
import './index.scss';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { Formatter } from '@core/utils/formatter';
import { Text } from '@modules/common/Text';
import { FormText } from '@core/components/form/FormText';
import { openProposalSettingsOverlay } from '../ProposalSettingsOverlay';
import { toMoment } from '@core/utils/time';
import { Config } from '@config';
import { useHistory } from '@core/router';
import { Button } from '@core/components/Button';
import { openEditProposalOverlay } from '@modules/proposal/EditProposalOverlay';

interface ProposalHeaderProps {
  lastSaved?: number | null;
  canSave: boolean;
  onSave: () => void;
  id: string;
}
export const ProposalHeader = memo((props: ProposalHeaderProps) => {
  const [isOpen, setOpen] = useState(false);
  const { toggleSidebar, form } = useProposalContext()!;
  const formValue = useNonNilObservable(form.value$);
  const total = useMemo(() => formValue.products.reduce((acc, item) => acc + (item.selected ? item.qty * item.rate : 0), 0), [formValue.products]);
  const history = useHistory();

  return (
    <div className={classnames('proposal-header', { open: isOpen })}>
      <div className="bar-handle" onClick={() => history.push('/proposal-templates')}>
        <i className="fa fa-arrow-left"></i>
      </div>
      <div className="bar-handle" onClick={toggleSidebar}>
        <i className="fas fa-bars"></i>
      </div>
      <div className="proposal-title">
        <FormText control={form.controls.title} singleLine editable toolbar={false} />
      </div>
      <div className="flex-fill"></div>
      <div className="proposal-header-right">
        {!Config.IS_ADMIN && <div className="proposal-total mr-4">{Formatter.formatCurrency(total, 'USD')}</div>}
        <div className="proposal-settings-icon" onClick={() => openProposalSettingsOverlay({ form })}>
          <i className="fas fa-cog mr-2"></i>
        </div>
        {!!props.lastSaved && <div className="mr-2 text-muted">Last saved {toMoment(props.lastSaved).format('hh:mma')}</div>}
        <Button onClick={props.onSave} disabled={!props.canSave}>
          Save
        </Button>
        <a href={`/proposal-preview/template/${props.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-secondary ml-2">
          Preview
        </a>
      </div>
    </div>
  );
});
