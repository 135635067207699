import React, { memo } from 'react';
import { Proposal } from '../types';
import { R } from '@core/utils/r';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { useProposalBlockContext } from '@modules/proposal/ProposalBuilderPage';

export const BlockBaseReadonly = memo(() => {
  const { blockForm, blockIndex } = useProposalBlockContext()!;
  const formValue = useNonNilObservable(blockForm.value$);

  const config = Proposal.BLOCK_CONFIGS[formValue.type];
  const handleRef = React.useRef<Proposal.BlockHandle>(null);

  if (!config) return null;

  const { component: BlockComponent } = config;
  return <BlockComponent id={formValue.id} variation={formValue.variation} editable={false} handlesRef={handleRef} />;
});
