import { ProjectServiceResponse, ProjectServiceType } from '@business/entities/project';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { R } from '@core/utils/r';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { map, switchMap } from 'rxjs/operators';
import { SettingsConfig } from '@core/utils/config';
import { of } from 'rxjs';

export class ServiceBloc extends BaseBloc {
  private readonly serviceRepo = new Repository<ProjectServiceResponse>({
    getItemId: service => service.id,
  });

  services = {
    all$: this.serviceRepo.items$.pipe(map(items => R.sortBy(items, item => item.title))),
    active$: this.serviceRepo.items$.pipe(
      map(items =>
        R.pipe(
          items,
          R.ops.filter(s => s.isActive),
          R.ops.sortBy(s => s.title),
        ),
      ),
    ),
    project$: this.serviceRepo.items$.pipe(
      map(items =>
        R.pipe(
          items,
          R.ops.filter(s => s.isActive),
          R.ops.filter(s => s.type === ProjectServiceType.project),
          R.ops.sortBy(s => s.title),
        ),
      ),
    ),
    subscription$: this.serviceRepo.items$.pipe(
      map(items =>
        R.pipe(
          items,
          R.ops.filter(s => s.isActive),
          R.ops.filter(s => s.type === ProjectServiceType.marketing),
          R.ops.sortBy(s => s.title),
        ),
      ),
    ),
    subscriptionRecurring$: this.serviceRepo.items$.pipe(
      map(items =>
        R.pipe(
          items,
          R.ops.filter(s => s.isActive),
          R.ops.filter(s => s.type === ProjectServiceType.marketing || s.type === ProjectServiceType.recurring),
          R.ops.sortBy(s => s.title),
        ),
      ),
    ),
    recurring$: this.serviceRepo.items$.pipe(
      map(items =>
        R.pipe(
          items,
          R.ops.filter(s => s.isActive),
          R.ops.filter(s => s.type === ProjectServiceType.recurring),
          R.ops.sortBy(s => s.title),
        ),
      ),
    ),
  };

  readonly selectService = this.serviceRepo.selectItem;

  onReset() {
    this.serviceRepo.reset();
  }

  fetchServices = () => {
    return of(null);
  };
}
