import React, { Fragment, memo, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { QuoteBlockForm, QuoteBlockVariation } from './QuoteBlock';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';
import { ImagePickerSettings } from '@modules/proposal/ImagePickerSettings';
import { CardTitle } from 'reactstrap';
export interface QuoteBlockSettingsProps {
  form: QuoteBlockForm;
  variation: QuoteBlockVariation;
}

export const QuoteBlockSettings = memo((props: QuoteBlockSettingsProps) => {
  const { form, variation } = props;
  const formValue = useNonNilObservable(form.value$);

  return (
    <div>
      <CardTitle>Image</CardTitle>
      <ImagePickerSettings onSelect={image => form.patchValue({ image })} currentImageUrl={formValue.image} />

      {variation === QuoteBlockVariation.quoteOnImage && (
        <Fragment>
          <hr />
          <CardTitle>Background</CardTitle>
          <ImagePickerSettings onSelect={background => form.patchValue({ background })} currentImageUrl={formValue.background} />
        </Fragment>
      )}
    </div>
  );
});
