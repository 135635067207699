import { FieldProps, FormControl, useFormField } from '@core/utils/form';
import { R } from '@core/utils/r';
import MFormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React, { ChangeEvent, CSSProperties, Fragment, useCallback, useMemo } from 'react';
import { FormGroup, FormGroupProps, Input, InputGroup, InputGroupText, InputProps, Label, UncontrolledTooltip } from 'reactstrap';
import './form_input.scss';
import $ from 'jquery';
import { useDidMount } from '@core/utils/hooks/react';
export interface FormColorStringInputProps<C extends FormControl<any>> extends FieldProps<C> {
  groupProps?: FormGroupProps;
  withoutGroup?: boolean;
  description?: string;
  container?: string;
  onColor?(color: string): void;
}

export type FormColorInputProps<C extends FormControl<any>> = FormColorStringInputProps<C>;

export function FormColorPicker<C extends FormControl<any>>(props: FormColorInputProps<C> & InputProps) {
  const {
    withoutGroup = false,
    isInputGroup = false,
    addonText = null,
    onColor,
    onlyNumbers = false,
    parseToFloat = false,
    onFocusChange,
    icon,
    inputParentStyles,
    ...rest
  } = props;

  const { formControl, fieldState, fieldConfig } = useFormField(props);

  const fieldTouched = (fieldState && fieldState.touched) || formControl.inheritedSubmitted || false;
  const fieldValue = R.toString(fieldState.value) || '';
  const fieldError = (fieldTouched && fieldState.errorMessage) || null;

  const fieldReadonly = fieldConfig.readonly || false;
  const fieldDisabled = fieldConfig.disabled || false;

  const fieldPlaceholder = fieldConfig.placeholder || '';

  const showIcon = useMemo(() => icon && !R.isEmpty(icon), [icon]);
  const formControlId = useMemo(() => `colorpicker-${R.random(100000, 999999)}`, []);

  const handleChange = useCallback(
    (color: string) => {
      formControl.setValue(color);
      onColor && onColor(color);
    },
    [formControl, onColor],
  );

  const handleChangeText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value: any = event.target.value;

      formControl.setValue(value);
      onColor && onColor(value);
    },
    [formControl, onColor],
  );

  useDidMount(() => {
    $('#' + formControlId).spectrum({
      type: 'component',
      allowEmpty: true,
      showInput: true,
      showAlpha: false,
      preferredFormat: 'hex',
      change: value => handleChange(value ? value.toHexString() : ''),
    });
  });

  const handleFocus = useCallback(() => {
    formControl.onFocus();
    onFocusChange?.(true);
  }, [formControl, onFocusChange]);

  const handleBlur = useCallback(() => {
    if (onlyNumbers) {
      formControl.setValue(fieldValue.replace(/\D/g, ''));
    }
    if (parseToFloat) {
      formControl.setValue(fieldValue.replace(/\,/g, ''));
    }
    onFocusChange?.(false);
    formControl.onBlur();
  }, [fieldValue, formControl, onFocusChange, onlyNumbers, parseToFloat]);

  let inputContent = (
    <div className="form-input-field" style={{ ...inputParentStyles }}>
      {showIcon && <i className={`${icon} icon`} style={{ ...props.iconStyles }}></i>}
      <Input
        {...rest}
        onBlur={handleBlur}
        onFocus={handleFocus}
        autoFocus={props.autoFocus}
        className={`form-control ${showIcon && 'pr-4'}`}
        id={formControlId}
        value={fieldValue}
        onChange={handleChangeText}
        type={props.type}
        required={fieldConfig.requiredMask}
        disabled={fieldDisabled}
        placeholder={fieldPlaceholder}
        readOnly={fieldReadonly}
      />
    </div>
  );
  if (isInputGroup && addonText && addonText !== '') {
    inputContent = (
      <InputGroup>
        <InputGroupText>{addonText}</InputGroupText>
        {inputContent}
      </InputGroup>
    );
  }

  const content = (
    <Fragment>
      {fieldConfig.label && (
        <Label for={formControl.uid} style={!!props.flex ? { display: 'flex', flex: props.flex?.[0] || 1 } : {}}>
          {fieldConfig.label}
          {fieldConfig.required && !R.isEmpty(fieldConfig.label) && (
            <span className="text-danger font-size-14" style={{ marginTop: -5, marginLeft: 2 }}>
              *
            </span>
          )}
        </Label>
      )}
      {!!props.flex && (
        <div style={{ flex: props.flex?.[1] || 1, flexDirection: 'row' }}>
          <MFormControl error={!!fieldError} fullWidth>
            {inputContent}
          </MFormControl>
          {!!fieldError && <FormHelperText style={{ color: '#f44336' }}>{fieldError}</FormHelperText>}
        </div>
      )}
      {!props.flex && (
        <MFormControl error={!!fieldError} fullWidth>
          {inputContent}
        </MFormControl>
      )}
      {!props.flex && !!fieldError && <FormHelperText style={{ color: '#f44336' }}>{fieldError}</FormHelperText>}
    </Fragment>
  );

  if (withoutGroup) return content;
  return (
    <FormGroup style={props.formGroupStyle} className={props.flex ? 'd-flex align-items-center' : ''} {...props.groupProps}>
      {content}
    </FormGroup>
  );
}
