import { R } from '@core/utils/r';
import React, { Fragment, memo, ReactElement, ReactNode, useMemo } from 'react';
import { UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';

export interface WithTooltipProps extends Omit<UncontrolledTooltipProps, 'target'> {
  tooltip: ReactNode;
  key?: string;
  children: ReactElement;
}

export const WithTooltip = memo((props: WithTooltipProps) => {
  const { tooltip, key, children, ...rest } = props;

  const inKey = useMemo(() => {
    return key || `tooltip-control-${R.random(1, 99999)}`;
  }, [key]);

  const inChildren = useMemo(() => {
    const inputReactObject = React.Children.only(children);
    const clonedChild = React.cloneElement(inputReactObject, {
      id: inKey,
    });

    return clonedChild;
  }, [children, inKey]);

  return (
    <Fragment>
      {inChildren}
      {!R.isEmpty(props.tooltip) && (
        <UncontrolledTooltip target={inKey} placement="top" {...rest}>
          {props.tooltip}
        </UncontrolledTooltip>
      )}
    </Fragment>
  );
});
