import { FieldProps, FormControl, useFormField } from '@core/utils/form';
import MFormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React, { useCallback, useState } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Label } from 'reactstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormSelectItem } from './FormSelect';
import { R } from '@core/utils/r';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fa fa-search"></i>
    </components.DropdownIndicator>
  );
};

export interface FormAjaxSelectProps<T extends string | number | boolean | any> extends FieldProps<FormControl<T>> {
  onChange?(value: any): void;
  withoutGroup?: boolean;
  initalValue?: FormSelectItem<T>;
  initalValues?: FormSelectItem<T>[];
  autofocus?: boolean;
  multiple?: boolean;
  callback?(search: string): Observable<FormSelectItem<T>[]>;
  noBody?: boolean;
  cacheOptions?: boolean;
  debounce?: number;
}

export function FormAjaxSelect<T extends string | number | boolean | any>(props: FormAjaxSelectProps<T>) {
  const { formControl, fieldState, fieldConfig } = useFormField(props);
  const [value, setValue] = useState<FormSelectItem<T> | null>(props.initalValue || null);
  const [values, setValues] = useState<FormSelectItem<T>[] | null>(props.initalValues || null);
  const { cacheOptions = true, debounce = 500 } = props;

  const fieldTouched = (fieldState && fieldState.touched) || formControl.inheritedSubmitted || false;
  const fieldError = (fieldTouched && fieldState.errorMessage) || null;

  const fieldReadonly = fieldConfig.readonly || false;
  const fieldDisabled = fieldConfig.disabled || false;

  const getOptions = useCallback(
    (search: string, callback: any) => {
      if (props.callback) {
        props
          .callback(search)
          .pipe(tap(result => callback(result)))
          .subscribe();
      } else {
        callback([]);
      }
    },
    [props],
  );

  const debouncedOptions = R.debounce(getOptions, debounce);

  const content = (
    <React.Fragment>
      <Label>{fieldConfig.label}</Label>
      <MFormControl error={!!fieldError} fullWidth>
        <AsyncSelect
          autoFocus={props.autofocus}
          cacheOptions={cacheOptions}
          isClearable
          isMulti={props.multiple}
          loadOptions={debouncedOptions}
          value={props.multiple ? values : value}
          onFocus={() => formControl.onFocus()}
          onBlur={() => formControl.onBlur()}
          placeholder={fieldConfig.placeholder || `Search ${fieldConfig.label || ''}...`}
          onChange={value => {
            if (!value) {
              formControl.setValue(null);
              props.onChange && props.onChange(null);
              setValue(null);
              return;
            }

            if (props.multiple) {
              formControl.setValue((value as any).map((v: any) => v.value));
              setValues(value as FormSelectItem<T>[]);
            } else {
              formControl.setValue((value as FormSelectItem<T>).value);
              props.onChange && props.onChange((value as FormSelectItem<T>).value);
              setValue(value as FormSelectItem<T>);
            }
          }}
          components={{ DropdownIndicator }}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={props.noBody ? undefined : document.body}
          classNamePrefix="select2-selection"
          noOptionsMessage={({ inputValue }) => (!inputValue ? 'Start typing to search...' : 'No options')}
        />
        {!!fieldError && <FormHelperText>{fieldError}</FormHelperText>}
      </MFormControl>
    </React.Fragment>
  );

  if (props.withoutGroup) return content;
  return <div className="form-group">{content}</div>;
}
