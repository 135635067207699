import {
  DashboardFilters,
  DashboardId,
  DashboardInterval,
  DashboardResponse,
  DashboardWidgetId,
  DashboardWidgetResponse,
  WidgetResultResponse,
  DashboardEmailId,
  DashboardEmailResponse,
  DashboardStatus,
} from '@business/entities/dashboard';
import { ReportBuilderFormData } from '@modules/dynamicReports/ReportForm';
import { Endpoint } from '../endpoint';

export function fetchDashboards() {
  return Endpoint.get<DashboardResponse[]>(`@gc/dashboards`);
}

export function fetchDashboard(id: DashboardId, filters?: DashboardFilters, isPublic?: boolean) {
  if (isPublic) {
    return Endpoint.get<DashboardResponse>(`@gc/public/dashboards/dashboard/${id}`, { params: { ...filters } });
  }
  return Endpoint.get<DashboardResponse>(`@gc/dashboards/dashboard/${id}`, { params: { ...filters } });
}

export function createDashboard(data: Partial<DashboardResponse>) {
  return Endpoint.post<DashboardResponse>(`@gc/dashboards`, { data });
}

export function updateDashboard(id: DashboardId, data: Partial<DashboardResponse>) {
  return Endpoint.patch<DashboardResponse>(`@gc/dashboards/${id}`, { data });
}

export function makeDefault(id: DashboardId) {
  return Endpoint.patch<DashboardResponse>(`@gc/dashboards/default/${id}`);
}

export function duplicateDashboard(id: DashboardId, data: Partial<DashboardResponse>) {
  return Endpoint.post<DashboardResponse>(`@gc/dashboards/duplicate/${id}`, { data });
}

export function deleteDashboard(DashboardId: DashboardId) {
  return Endpoint.delete<void>(`@gc/dashboards/${DashboardId}`);
}

/* Widgets */

export function fetchWidget(id: DashboardWidgetId) {
  return Endpoint.get<DashboardWidgetResponse>(`@gc/dashboards/widget/${id}`);
}
export function createWidget(id: DashboardId, settings: ReportBuilderFormData) {
  return Endpoint.post<DashboardResponse>(`@gc/dashboards/widget/${id}`, { data: { settings } });
}

export function updateWidget(id: DashboardWidgetId, settings: Partial<ReportBuilderFormData>) {
  return Endpoint.patch<DashboardResponse>(`@gc/dashboards/widget/${id}`, { data: { settings } });
}

export function updateAllWidgets(id: DashboardId, nWidgets: DashboardWidgetResponse[]) {
  const widgets = nWidgets.map(w => ({ ...w, settings: { ...w.settings, source: w.settings.source.view } }));
  return Endpoint.patch<DashboardResponse>(`@gc/dashboards/bulk-widgets/${id}`, { data: { widgets } });
}

export function deleteWidget(id: DashboardWidgetId) {
  return Endpoint.delete<void>(`@gc/dashboards/widget/${id}`);
}

export function getStatByWidget(widgetId: DashboardWidgetId, interval: DashboardInterval) {
  return Endpoint.post<WidgetResultResponse>(`@gc/widget/result-by-id/${widgetId}`, { data: { interval } });
}

export function getStatByConfig(data: Partial<ReportBuilderFormData>, interval: DashboardInterval) {
  return Endpoint.post<WidgetResultResponse>(`@gc/widget/result-by-data`, { data: { ...data, interval } });
}

export function fetchDashboardPublic(id: DashboardId, dateRange?: string | null) {
  return Endpoint.get<DashboardResponse>(`@gc/public/dashboard/${id}`, { params: dateRange ? { dateRange, isCustom: true } : undefined });
}

export function printDashboard(id: DashboardId, filename: string, dateRange: string) {
  return Endpoint.get<DashboardResponse>(`@gc/dashboards/print/${id}`, { isFile: true, filename, params: { dateRange } });
}

/* Dashboard Emails */

export function fetchDashboardEmails(id: DashboardId) {
  return Endpoint.get<DashboardEmailResponse[]>(`@gc/dashboards/emails/${id}`);
}

export function fetchDashboardEmail(id: DashboardEmailId) {
  return Endpoint.get<DashboardEmailResponse>(`@gc/dashboards/email/${id}`);
}

export function createDashboardEmail(id: DashboardId, data: Partial<DashboardEmailResponse>) {
  return Endpoint.post<DashboardEmailResponse>(`@gc/dashboards/email/${id}`, { data });
}

export function updateDashboardEmail(id: DashboardEmailId, data: Partial<DashboardEmailResponse>) {
  return Endpoint.patch<DashboardEmailResponse>(`@gc/dashboards/email/${id}`, { data });
}

export function deleteDashboardEmail(id: DashboardEmailId) {
  return Endpoint.delete<void>(`@gc/dashboards/email/${id}`);
}

export function toggleDashboardStatus(id: DashboardId, status: DashboardStatus) {
  return Endpoint.post<DashboardResponse>(`@gc/dashboards/toggle-status`, { data: { id, status } });
}
