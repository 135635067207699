import { UserResponse } from '@business/entities/account';
import { FormSelectItem } from '@core/components/form';
import { R } from '@core/utils/r';
import { Subject } from 'rxjs';

// map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): U[]
export type SelectCallbackFn<I, T> = (item: I, index: number) => T;
export function selectItems<I, V extends string | boolean | number = any>(
  items: I[],
  label: keyof I | SelectCallbackFn<I, string>,
  value?: keyof I | SelectCallbackFn<I, V>,
  sort?: boolean,
): FormSelectItem<V>[] {
  const listItem = items.map((item, index) => ({
    label: R.isFunction(label) ? label(item, index) : R.get(item, label),
    value: value ? (R.isFunction(value) ? value(item, index) : R.get(item, value)) : item,
  }));

  if (sort) {
    return R.sortBy(listItem, i => i.label);
  }
  return listItem;
}

export function selectGroupedItems<G, I, V extends string | boolean | number = any>(
  groups: G[],
  groupLabel: keyof G | SelectCallbackFn<G, string>,
  itemsAccessor: SelectCallbackFn<G, I[]>,
  label: keyof I | SelectCallbackFn<I, string>,
  value?: keyof I | SelectCallbackFn<I, V>,
  sort?: boolean,
): FormSelectItem<V>[] {
  const listItems = groups.map((group, index) => {
    const gItems: I[] = R.isFunction(itemsAccessor) ? itemsAccessor(group, index) : R.get(group, itemsAccessor);

    return {
      value: R.isFunction(groupLabel) ? groupLabel(group, index) : R.get(group, groupLabel),
      label: R.isFunction(groupLabel) ? groupLabel(group, index) : R.get(group, groupLabel),
      options: selectItems(gItems, label, value, sort),
    };
  });

  if (sort) {
    return R.sortBy(listItems, i => i.label);
  }
  return listItems;
}

export function getFullName(user: UserResponse) {
  if (!user) return 'Deleted User';
  return R.join(R.compact([user.firstName, user.lastName]), ' ');
}

// export function getShortName(user: UserResponse | CompactUserResponse | CompactContactResponse | ContactResponse) {
//   return R.join(R.compact([user.firstName[0], user.lastName[0]]), '').toUpperCase();
// }

export function isImage(extension: string) {
  extension = R.lowerCase(extension);
  if (extension === 'jpeg' || extension === 'jpg' || extension === 'png' || extension === 'gif' || extension === 'bmp') {
    return true;
  }
  return false;
}

export function copyToClipboard(text: string) {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function stripTags(value: string) {
  return R.isString(value) ? value.replace(/(<([^>]+)>)/gi, '') : value;
}

export function toFormData(model: any, form: FormData | null = null, namespace = ''): FormData {
  const formData = form || new FormData();
  for (const propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || model[propertyName] == undefined) continue;
    const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof File) {
      formData.append(formKey, model[propertyName]);
    } else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element: any, index: number) => {
        if (typeof element != 'object') formData.append(`${formKey}[]`, element);
        else if (element instanceof File) formData.append(`${formKey}[]`, element);
        else {
          const tempFormKey = `${formKey}[${index}]`;
          toFormData(element, formData, tempFormKey);
        }
      });
    } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
      toFormData(model[propertyName], formData, formKey);
    } else {
      formData.append(formKey, model[propertyName].toString());
    }
  }
  return formData;
}

export function readMore(value: string, limit = 100) {
  return R.isString(value) && value.length > limit ? stripTags(value).substring(0, limit) + '...' : value;
}

export function isValidUrl(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

export function YesNoOptions(labels?: { yes?: string; no?: string }) {
  return [
    { label: labels?.yes || 'Yes', value: true },
    { label: labels?.no || 'No', value: false },
  ];
}

export function chooseImage(options?: { multiple?: boolean }) {
  const obs$ = new Subject<FileList>();

  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.multiple = options?.multiple || false;
  input.onchange = e => {
    const files = (e.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      obs$.next(files);
      obs$.complete();
    }
  };
  input.click();

  return obs$;
}
