import '@styles/theme.scss';
import './index.scss';

import React, { Fragment, useMemo } from 'react';
import { useSubscribe } from '@core/utils/hooks/rxjs';
import { createHistory, HistoryProvider } from '@core/router';
import { ModalStack } from '@core/components/ModalStack';
import { Session } from '@modules/auth/session';
import { initBlocs, resetBlocs } from '@business/blocs';
import { AppRouter } from './AppRouter';
import { LoadingBar } from '@core/components/LoadingBar';
import { PageFocusProvider } from '@core/components/PageFocusProvider';
import ErrorBoundary from './ErrorBoundary';
import 'spectrum-colorpicker2/src/spectrum.css';
export default function App() {
  const history = useMemo(() => createHistory(), []);

  useSubscribe(Session.sessionStart$, () => {
    initBlocs();
    history.replace('/');
  });

  useSubscribe(Session.sessionEnd$, () => {
    history.replace('/login');
    resetBlocs();
  });

  return (
    <Fragment>
      <HistoryProvider history={history}>
        <ErrorBoundary>
          <LoadingBar>
            <AppRouter />
            <ModalStack />
          </LoadingBar>
        </ErrorBoundary>
      </HistoryProvider>
    </Fragment>
  );
}
