import { BaseBloc } from '@core/utils/bloc';
import { LoadingState } from '@core/utils/repository/loading_state';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { R } from '@core/utils/r';
import { resolve } from '@core/utils/ioc';
import { Blocs } from '@business/blocs';
import { Dispatcher } from '@core/utils/dispatcher';
import { ProposalEvent } from '.';
import { ProposalTemplateResponse } from '@business/entities/proposal';
import { Config } from '@config';

export class ProposalBuilderPageBloc extends BaseBloc {
  private readonly serviceBloc = resolve(Blocs.service);
  private readonly templateBloc = resolve(Blocs.proposalTemplate);

  constructor(private readonly templateId: string) {
    super();
  }

  dispatcher = new Dispatcher<ProposalEvent>();

  readonly loading = new LoadingState();

  readonly pageVisibility$ = new BehaviorSubject<{ index: number; height: number }[]>([]);
  readonly currentSection$ = new BehaviorSubject(-1);

  readonly services$ = this.serviceBloc.services.active$;
  readonly template$ = this.templateBloc.selectItem(
    Config.IS_ADMIN ? `template-${this.templateId}` : this.templateId,
  ) as Observable<ProposalTemplateResponse | null>;

  onInit() {
    return forkJoin([this.serviceBloc.fetchServices(), this.templateBloc.getItem(this.templateId)]).pipe(this.loading.run());
  }

  onVisibilityChange(isVisible: boolean, index: number, height: number) {
    if (isVisible) {
      this.pageVisibility$.next([...this.pageVisibility$.value.filter(item => item.index !== index), { index, height }]);
    } else {
      this.pageVisibility$.next(this.pageVisibility$.value.filter(item => item.index !== index));
    }
    this.setCurrentPage();
  }

  setCurrentPage = R.debounce(() => {
    const currentValue = this.pageVisibility$.value;
    if (currentValue.length > 0) {
      const max = R.last(R.sortBy(currentValue, item => item.height));
      this.currentSection$.next(max!.index);
    }
  }, 300);

  updateFormData = this.templateBloc.updateFormData;
}
