import React, { memo, useMemo, useCallback } from 'react';
import { formControl, formGroup } from '@core/utils/form';
import { FormInput, FormSelect } from '@core/components/form';
import { openImageFinderModal } from '@modules/ImageFinder';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { CommonService } from '@business/services';
import { R } from '@core/utils/r';
import { Row, Col } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { classnames } from '@core/utils/css';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';

function defineImageForm() {
  return formGroup({
    search: formControl<string>({
      placeholder: 'Search from image library... (press enter)',
      initialValue: '',
    }),
    type: formControl<string>({
      initialValue: 'unsplash',
    }),
  });
}

export interface ImagePickerSettingsProps {
  multiple?: boolean;
  path?: string;
  onSelect(imageUrl: string): void;
  currentImageUrl?: string;
}

export const ImagePickerSettings = memo((props: ImagePickerSettingsProps) => {
  const { multiple = false, path = 'proposal/images', onSelect, currentImageUrl } = props;
  const form = useMemo(() => defineImageForm(), []);
  const formValue = useNonNilObservable(form.value$);
  const isUpload = useMemo(() => formValue.type === 'upload', [formValue]);

  const onDrop = useCallback(
    (files: File[]) => {
      if (!files) return null;
      if (files.length == 0) return null;

      files.forEach(file => {
        UploadAPI.upload(path, file)
          .pipe(tap(res => onSelect(res.fileUrl)))
          .subscribe();
      });
    },
    [onSelect, path],
  );

  // const onLibrarySelect = useCallback(
  //   (url: string) => {
  //     UploadAPI.store(path, url)
  //       .pipe(tap(res => onSelect(res.fileUrl)))
  //       .subscribe();
  //   },
  //   [onSelect, path],
  // );

  return (
    <div className={classnames({ 'mb-4': isUpload })}>
      <Row>
        <Col md="2">
          <FormSelect control={form.controls.type} items={CommonService.selectItems(['unsplash', 'pexels', 'upload'], u => R.upperFirst(u))} />
        </Col>
        <Col md={10}>
          {!isUpload && (
            <FormInput
              type="search"
              control={form.controls.search}
              onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
                if (event.keyCode === 13) {
                  openImageFinderModal({
                    initialSearch: formValue.search || '',
                    source: formValue.type,
                    onSelect: image => onSelect(image),
                  });
                }
              }}
            />
          )}

          {isUpload && (
            <Dropzone onDrop={onDrop} maxFiles={1} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dz-message needsclick">
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Drop files here</h4>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          )}
        </Col>
      </Row>
      {!!currentImageUrl && <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={currentImageUrl} alt="" />}
    </div>
  );
});
