import React, { memo, useMemo, useCallback, DragEvent } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import classNames from 'classnames';
import { formGroup, formControl, FormDataType } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { useProposalContext } from '@modules/proposal/ProposalBuilderPage';
import { R } from '@core/utils/r';
import { of } from 'rxjs';
import { Container } from 'reactstrap';

function defineDividerBlockForm() {
  return formGroup({
    advance: defineBlockAdvanceForm({ paddingTop: 30, paddingBottom: 30 }),
  });
}

export type DividerWidgetFormData = FormDataType<typeof defineDividerBlockForm>;
export type DividerWidgetForm = ReturnType<typeof defineDividerBlockForm>;

export enum DividerBlockVariation {
  divider = 'divider',
  numbersDivider = 'numbersDivider',
}

export const DividerBlock = memo((props: Proposal.BlockProps<DividerBlockVariation>) => {
  const { variation } = props;
  const proposal = useProposalContext();
  const form = useMemo(defineDividerBlockForm, []);
  const formValue = useNonNilObservable(form.value$);
  const proposalFormValue = useNonNilObservable(proposal ? proposal.form.value$ : of(null));
  const dividerNumber = useMemo(() => {
    if (!proposalFormValue) return 1;
    return (
      R.pipe(
        proposalFormValue.blocks,
        R.ops.filter(block => block.type === Proposal.BlockType.divider && block.variation === DividerBlockVariation.numbersDivider),
        R.ops.map(block => block.id),
        R.ops.indexOf(props.id),
      ) + 1
    );
  }, [proposalFormValue, props.id]);

  useConfigureBlock({
    props,
    form,
  });

  return (
    <BlockContainer className={classNames('d-flex flex-fill h-100', 'block-divider', variation)} advanceForm={formValue.advance} >
      <Container>
        <div className="divider" style={{ backgroundColor: proposalFormValue?.primaryColor || '#45A8F7' }}>
          {variation === DividerBlockVariation.numbersDivider && (
            <div style={{ backgroundColor: proposalFormValue?.primaryColor || '#45A8F7' }} className="divider-number">
              {dividerNumber}
            </div>
          )}
        </div>
      </Container>
    </BlockContainer>
  );
});
