export function guadRange(value: number, min: number, max: number) {
  return Math.max(Math.min(value, max), min);
}

export function swap<T>(arr: T[], oldIndex: number, newIndex: number) {
  const newArray = [...arr];
  const temp = arr[newIndex];
  newArray[newIndex] = newArray[oldIndex];
  newArray[oldIndex] = temp;
  return newArray;
}

export function move<T>(arr: T[], oldIndex: number, newIndex: number) {
  const newArray = [...arr];
  newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);
  return newArray;
}

export function moveItems<T>(arr: T[], oldIndex: number, newIndex: number, count: number) {
  const newArray = [...arr];
  const toBeMoved = newArray.splice(oldIndex, count);
  newArray.splice(newIndex > oldIndex ? newIndex - count + 1 : newIndex + 1, 0, ...toBeMoved);
  return newArray;
}

export function replaceAtIndex<T>(arr: T[], index: number, newItem: T) {
  return arr.map((item, i) => (index === i ? newItem : item));
}
