export enum ProjectServiceType {
  project = 'project',
  marketing = 'marketing',
  recurring = 'recurring',
  inactive = 'inactive',
}

export type ProjectServiceId = string;
export interface ProjectServiceResponse {
  id: ProjectServiceId;
  title: string;
  description?: string;
  rate?: number;
  isActive: boolean;
  type: ProjectServiceType;
  isRecuring: boolean;
  classId: string;
  serviceAccountId: string;
  expenseAccountId: string;
  isRollover: boolean;
  supportHours: number;
  taxable: boolean;
  currency?: string;
}
