import { UserAPI } from '@business/api/user';
import { UserId, UserResponse } from '@business/entities/account';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { R } from '@core/utils/r';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { map, switchMap } from 'rxjs/operators';

export class UserBloc extends BaseBloc {
  private readonly usersRepo = new Repository<UserResponse>({
    getItemId: user => user.id,
  });

  readonly users$ = this.usersRepo.items$.pipe(map(users => R.sortBy(users, u => u.firstName)));
  readonly users = this.usersRepo.items;

  readonly selectUser = this.usersRepo.selectItem;

  onReset() {
    this.usersRepo.reset();
  }

  fetchUsers = () => {
    return UserAPI.fetchUsers().pipe(this.usersRepo.ops.reset(), handleMessage({ error: requestMessage('fetch_users_error') }));
  };

  fetchUser = (id: UserId) => {
    return UserAPI.fetchUser(id).pipe(
      this.usersRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_users_error') }),
    );
  };

  createUser = (user: Partial<UserResponse>) => {
    return UserAPI.createUser(user).pipe(
      this.usersRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_user'),
      }),
    );
  };

  updateUser = (id: UserId, user: Partial<UserResponse>) => {
    return UserAPI.updateUser(id, user).pipe(
      this.usersRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_user'),
      }),
    );
  };

  deleteUser = (userId: UserId) => {
    return showDeleteConfirmation('Delete User', 'Do you really want to remove this user?').pipe(
      switchMap(() => UserAPI.deleteUser(userId)),
      this.usersRepo.ops.removeOne(() => userId),
      handleMessage({
        type: requestMessage('delete_user'),
      }),
    );
  };
}
