export interface GraphValueResponse {
  id: string;
  forDateString: string;
  forDate: number;
  value: number;
  user: string;
  graphId: string;
}

export interface GraphResponse {
  id: string;
  title: string;
  description: string;
  type: GraphTypes;
}

export enum GraphTypes {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  yearly = 'yearly',
}
