import { Endpoint } from '@business/api/endpoint';
import { HTMLService } from '@business/services';
import { FormControl, useFormField } from '@core/utils/form';
import { R } from '@core/utils/r';
import JoditEditor, { Jodit } from 'jodit-pro-react';
import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { InputProps } from 'reactstrap';
import { Observable } from 'rxjs';
import { FormInputProps } from './FormInput';
import './form_html.scss';

export function FormHTMLInline<C extends FormControl<any>>(
  props: FormInputProps<C> &
    InputProps & {
      theme?: string;
      readonly?: boolean;
      minHeight?: number;
      media?: (text?: string) => Promise<Observable<string>>;
      autoFocus?: boolean;
      onFocus?: () => void;
      onBlur?: () => void;
      transformedFieldsValue?: Record<string, string>;
    },
) {
  const { autoFocus = false } = props;
  const { formControl, fieldState, fieldConfig } = useFormField(props);

  const fieldValue = R.toString(fieldState.value) || '';
  const [isFocused, setIsFocused] = React.useState(false);
  const [myValue, setValue] = React.useState(fieldValue);

  const fieldReadonly = fieldConfig.readonly || false;

  const fieldPlaceholder = fieldConfig.placeholder || '';
  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  const handleChangeText = useCallback(
    (value: string) => {
      if (isFocused) {
        setValue(value);
        formControl.setValue(value);
      }
    },
    [formControl, isFocused],
  );

  const handleBlur = useCallback(() => {
    formControl.onBlur();
    props.onBlur && props.onBlur();
    formControl.setValue(myValue);
    setTimeout(() => {
      setIsFocused(false);
    }, 250);
  }, [formControl, props, myValue]);

  const handleFocus = useCallback(() => {
    formControl.onFocus();
    props.onFocus && props.onFocus();
    setIsFocused(true);
  }, [formControl, props]);

  const joditRef = useRef<Jodit | null>(null);

  const config: Partial<Jodit['options']> = useMemo(() => {
    const headers = Endpoint.dumpHeaders;
    delete headers['Content-Type'];
    delete headers['Accept'];

    return {
      // ...Jodit.defaultOptions,
      license: 'M1NDC-1DHE1-C05P6-DPFOA',
      disablePlugins: [
        'button-generator',
        'google-maps',
        'google-search',
        'page-break',
        'finder',
        'backup',
        'emoji',
        'keyboard',
        'export-docs',
        'change-case',
        'show-blocks',
        'paste-code',
      ],
      highlightSignature: {
        schema: {
          '\\{\\{([^}]+)\\}\\}': (jodit: Jodit) => {
            const color = '#FFFF0045';

            const span = jodit.createInside.element('span', {
              style: {
                backgroundColor: color,
              },
            });

            return span;
          },
        },
      },
      inline: true,
      toolbar: false,
      toolbarInlineForSelection: true,
      placeholder: fieldPlaceholder,
      readonly: props.readonly,
      enableDragAndDropFileToEditor: true,
      saveModeInStorage: true,
      enter: 'div',
      popup: {
        a: null,
        selection: Jodit.atom([
          'paragraph',
          '|',
          'bold',
          'italic',
          'underline',
          '|',
          'font',
          'fontsize',
          'brush',
          'lineHeight',
          '|',
          'ul',
          'ol',
          '|',
          'indent',
          'outdent',
          'left',
          'center',
          'right',
          '|',
          'link',
          'table',
          '|',
          'image',
          'variables',
        ]),
      },
      uploader: {
        // ...Jodit.defaultOptions.uploader,
        headers,
        url: Endpoint.dumpUrl('@gc/folder/rte-upload'),
        insertImageAsBase64URI: false,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
        format: 'json',
      },

      showToolip: true,

      toolbarAdaptive: false,
      colorPickerDefaultTab: 'color',
      preset: 'inline',
      askBeforePasteHTML: false,
      defaultActionOnPaste: 'insert_clear_html',
      allowResizeX: true,
      buttons: Jodit.atom(['bold', 'italic']),
      controls: {
        variables: {
          name: 'Short Codes',
          list: {
            'h:Your Company': 'h:Your Company',
            company_name: 'Company Name',
            brand_name: 'Brand Name',
            user_name: 'User Name',
            user_email: 'User Email',
            // user_phone: 'User Phone',
            company_address: 'Address',
            company_address2: 'Address 2',
            company_city: 'City',
            company_state: 'State',
            company_country: 'Country',
            company_zip: 'Zip',
            // '|1': '|',
            'h:Your Client': 'h:Your Client',
            org_name: 'Organization Name',
            contact_first_name: 'Contact First Name',
            contact_last_name: 'Contact Last Name',
            contact_name: 'Contact Name',
            contact_email: 'Contact Email',
            contact_address: 'Address',
            contact_address2: 'Address 2',
            contact_city: 'City',
            contact_state: 'State',
            contact_country: 'Country',
            contact_website: 'Website',
            // '|2': '|',
            'h:Proposal': 'h:Proposal',
            // proposal_name: 'Name',
            total: 'Total ($)',
            link: 'Link',
            date_sumitted: 'Date Submitted',
            date_accepted: 'Date Accepted',
          },
          childTemplate: (editor, key, value) => {
            if (value.startsWith('h:')) {
              return `<span style="font-weight: bold; text-align: lefer" class="${key}">${editor.i18n(value.substring(2))}</span>`;
            }
            return `<span style="font-size: 12px; text-align: left" class="${key}">${editor.i18n(value)}</span>`;
          },
          exec: function(editor, _, { control }) {
            const value = control.args && control.args.length && control.args[0];
            if (value.startsWith('h:')) return false;
            if (value.startsWith('|')) return false;

            if (value) {
              (editor as Jodit).s.insertHTML(`{{${value}}}`);
            }
          },
        },
      },
      theme: props.theme || 'default',
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      autofocus: autoFocus,
      events: {
        afterInit(instance: Jodit) {
          // console.log(props.id, 'afterInit', fieldValue);
        },
        focus() {
          handleFocus();
        },
        click() {
          handleFocus();
        },
      },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldPlaceholder, fieldReadonly, props.minHeight, props.theme, autoFocus]);

  const transformedFieldsValue = useMemo(() => {
    if (!props.readonly) return fieldValue;
    return fieldValue.replace(/{{([^}]+)}}/g, (match, p1) => {
      const value = props.transformedFieldsValue && props.transformedFieldsValue[p1];
      return value || match;
    });
  }, [fieldValue, props.readonly, props.transformedFieldsValue]);

  return (
    <div className="jodit-inline" style={{ flex: 1 }}>
      {!!props.readonly && HTMLService.toReact(transformedFieldsValue)}

      {!props.readonly && <JoditEditor ref={joditRef} config={config as any} onBlur={handleBlur} onChange={handleChangeText} value={fieldValue} />}
    </div>
  );
}
