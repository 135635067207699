import { Endpoint } from '../endpoint';
import { PageQuery, Paginate } from '@business/entities/common';
import { GlossaryResponse, GlossaryId, GlossaryRequest, CompactGlossaryResponse, GlossaryDefinitionResponse } from '@business/entities/glossary';
import { R } from '@core/utils/r';
import { GlossaryStepData } from '@modules/course/ManageCoursePage/StepForms/GlossaryStep';
import { GlossaryStepSettingsResponse } from '@business/entities/course';

export function fetchGlossariesPaginated(params: PageQuery) {
  return Endpoint.get<Paginate<CompactGlossaryResponse>>(`@gc/glossary`, { params: R.objectCompact({ ...params }) });
}

export function fetchGlossaries(getAll = false) {
  return Endpoint.get<GlossaryResponse[]>(`@gc/glossary`, { params: { getAll } });
}

export function fetchGlossary(id: GlossaryId) {
  return Endpoint.get<GlossaryResponse>(`@gc/glossary/glossary/${id}`);
}

export function createGlossary(data: GlossaryRequest) {
  return Endpoint.post<GlossaryResponse>(`@gc/glossary`, { data });
}

export function updateGlossary(id: GlossaryId, data: GlossaryRequest) {
  return Endpoint.patch<GlossaryResponse>(`@gc/glossary/${id}`, { data });
}

export function deleteGlossary(TaskId: GlossaryId) {
  return Endpoint.delete<void>(`@gc/glossary/${TaskId}`);
}

export function searchGlossary(search: string) {
  return Endpoint.get<GlossaryResponse[]>(`@gc/glossary/search`, { params: { search } });
}

export function getGlossaryDetail(settings: GlossaryStepData) {
  return Endpoint.get<GlossaryStepSettingsResponse>(`@gc/glossary/definition`, { params: { settings: JSON.stringify(settings) } });
}

export function isWordExist(word: string, id?: GlossaryId) {
  return Endpoint.post<{ status: boolean }>(`@gc/glossary/check-word`, { data: { word, id: id || '0' } });
}

export function fetchGlossaryDefinition(id: string) {
  return Endpoint.get<GlossaryDefinitionResponse>(`@gc/glossary/glossary-definition/${id}`);
}
