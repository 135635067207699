import { UserId, UserResponse } from '@business/entities/account';
import { Endpoint } from '../endpoint';
import { CommonService } from '@business/services';

export function fetchUsers() {
  return Endpoint.get<UserResponse[]>(`@gc/users`);
}

export function fetchUser(id: UserId) {
  return Endpoint.get<UserResponse>(`@gc/users/user/${id}`);
}

export function createUser(user: Partial<UserResponse>) {
  return Endpoint.post<UserResponse>(`@gc/users/create`, { data: { ...user } });
}

export function updateUser(userId: UserId, data: Partial<UserResponse>) {
  const formData = CommonService.toFormData(data);
  return Endpoint.post<UserResponse>(`@gc/users/${userId}`, { formData, hasFiles: true });
}

export function deleteUser(userId: UserId) {
  return Endpoint.delete<void>(`@gc/users/${userId}`);
}
