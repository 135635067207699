import { FieldProps, FormControl, useFormField } from '@core/utils/form';
import { R } from '@core/utils/r';
import MFormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React, { CSSProperties, Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import Select, { SelectComponentsConfig } from 'react-select';
import { Label, UncontrolledTooltip } from 'reactstrap';

export interface FormSelectItem<T extends string | boolean | number | any> {
  value: T;
  label: ReactNode;
  title?: ReactNode;
  delimiter?: boolean;
  options?: FormSelectItem<T>[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface FormSelectBaseProps<T extends string | number | any | string[] | number[] | any[]> {
  onChange?(value: T | null): void;
  withoutGroup?: boolean;
  autofocus?: boolean;
  style?: CSSProperties;
  formGroupStyle?: CSSProperties;
  isClearable?: boolean;
  horizontal?: boolean;
  description?: string;
  container?: string;
  noLabel?: boolean;
}

export interface FormSelectSingleProps<T extends string | number | any> extends FormSelectBaseProps<T>, FieldProps<FormControl<T>> {
  items: FormSelectItem<T>[];
  type?: 'single';
  onAdd?(value: string): void;
  returnKeys?: string;
  components?: SelectComponentsConfig<FormSelectItem<T> | null, boolean, any>;
}

export interface FormSelectMultipleProps<T extends string[] | number[]> extends FormSelectBaseProps<T>, FieldProps<FormControl<T>> {
  items: FormSelectItem<T[0]>[];
  type?: 'multiple';
  onAdd?(value: string): void;
  returnKeys?: string;
  maxSize?: number;
  components?: SelectComponentsConfig<FormSelectItem<T[0]> | null, boolean, any>;
}

export type FormSelectProps<T extends any> = FormSelectSingleProps<T> | FormSelectMultipleProps<any>;

export function FormSelect<T extends any>(props: FormSelectProps<T>) {
  const { formControl, fieldState, fieldConfig } = useFormField(props);
  const [inputValue, setInputValue] = useState('');

  const fieldTouched = (fieldState && fieldState.touched) || formControl.inheritedSubmitted || false;
  const fieldError = (fieldTouched && fieldState.errorMessage) || null;

  const fieldDisabled = fieldConfig.disabled || false;

  const allItems = useMemo(() => {
    return R.flatMap(props.items, item => {
      if (item && item.options) {
        return item.options;
      }
      return [item];
    });
  }, [props.items]);

  const fieldValue = R.isNil(fieldState.value) ? '' : fieldState.value;

  const selectedValue = useMemo(() => {
    if (props.type === 'multiple' && fieldValue) {
      return R.pipe(
        allItems,
        R.ops.compact,
        R.ops.filter(i => (fieldValue as any[]).includes(i.value)),
      );
    } else {
      return R.find(allItems, i => (i ? i.value == fieldValue : null));
    }
  }, [props.type, allItems, fieldValue]);

  const onInputChange = useCallback(value => {
    setInputValue(value);
  }, []);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (props.onAdd) {
        const keyCodes = (props.returnKeys || '').split('').map(c => c.charCodeAt(0).toString());
        keyCodes.push('13');
        if (keyCodes.includes(event.keyCode.toString())) {
          props.onAdd(inputValue);
          setInputValue('');
        }
      }
    },
    [inputValue, props],
  );

  const tooltip = useMemo(() => {
    if (!props.description) return null;
    const uid = formControl.uid.replace('form:', '');
    return (
      <Fragment>
        <i className="fas fa-question-circle ml-2" id={`control-description-${uid}`}></i>
        <UncontrolledTooltip container={props.container} placement="top" target={`control-description-${uid}`}>
          {props.description}
        </UncontrolledTooltip>
      </Fragment>
    );
  }, [formControl.uid, props.container, props.description]);

  const content = (
    <React.Fragment>
      {!props.noLabel && (
        <Label style={props.horizontal ? { marginBottom: 0, marginRight: 10 } : {}}>
          {fieldConfig.label}
          {tooltip}
        </Label>
      )}
      <MFormControl error={!!fieldError} fullWidth>
        <Select
          onKeyDown={onKeyDown}
          onInputChange={onInputChange}
          inputValue={inputValue}
          autoFocus={props.autofocus}
          value={(selectedValue as any) || null}
          isDisabled={fieldDisabled}
          onFocus={() => formControl.onFocus()}
          onBlur={() => formControl.onBlur()}
          placeholder={fieldConfig.placeholder}
          isClearable={props.isClearable}
          components={props.components}
          onChange={value => {
            if (props.isClearable && value === null) {
              formControl.setValue(null);
              props.onChange && props.onChange(null);
            } else {
              if (props.type === 'multiple') {
                if (props.maxSize && value.length > props.maxSize && R.isArray(selectedValue)) {
                  formControl.setValue((selectedValue as any).map((v: any) => v.value));
                } else {
                  formControl.setValue(value.map((v: any) => v.value));
                  props.onChange && props.onChange(value.map((v: any) => v.value));
                }
              } else {
                formControl.setValue(value.value);
                props.onChange && props.onChange(value.value);
              }
            }
          }}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          options={props.items}
          classNamePrefix="select2-selection"
          isSearchable={true}
          className="select2-selection"
          isMulti={props.type === 'multiple'}
          formatOptionLabel={function(data) {
            return <span dangerouslySetInnerHTML={{ __html: data.label }} />;
          }}
        />
        {!!fieldError && <FormHelperText>{fieldError}</FormHelperText>}
      </MFormControl>
    </React.Fragment>
  );

  if (props.withoutGroup) return content;
  return (
    <div style={props.formGroupStyle} className={`${props.horizontal ? 'd-flex align-items-center ' : 'form-group'}`}>
      {content}
    </div>
  );
}
