import React, { memo, PropsWithChildren, Fragment, useMemo } from 'react';
import { css, ClassNames, Global } from '@emotion/react';
import { R } from '@core/utils/r';
import { Theme } from '@core/utils/css';
import { useModalInstance } from '@core/components/ModalStack';
import { createTheme, Slide, ThemeProvider } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';

const styles = {
  global: css`
    .MuiPopover-root {
      .MuiMenuItem-root {
        font-size: 15px;
        font-family: ${Theme.fontFamily};
      }
    }
  `,

  container: css`
    &,
    .MuiButton-label,
    .MuiTab-wrapper {
      font-family: ${Theme.fontFamily};
    }
  `,
};

export interface ModalProps {
  backdropClose?: boolean;
  fullWidth?: DialogProps['fullWidth'];
  // eslint-disable-next-line @typescript-eslint/ban-types
  maxWidth?: DialogProps['maxWidth'] | number | (string & {});
  scroll?: DialogProps['scroll'];
  borderRadius?: number;
  onClose?(): void;
}

export const ModalDrawer = memo((props: PropsWithChildren<ModalProps>) => {
  const modal = useModalInstance();

  const maxWidth = useMemo(() => {
    let maxWidth: string | undefined = undefined;

    if (!props.maxWidth) {
      maxWidth = '790px';
    } else if (R.isNumber(props.maxWidth)) {
      maxWidth = props.maxWidth + 'px';
    } else if (R.isString(props.maxWidth) && (props.maxWidth.endsWith('px') || props.maxWidth.endsWith('%'))) {
      maxWidth = props.maxWidth;
    }

    return maxWidth;
  }, [props.maxWidth]);

  const Transition = useMemo(
    () =>
      React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
        return <Slide direction="left" ref={ref} {...props} />;
      }),
    [],
  );
  return (
    <Fragment>
      <Global styles={styles.global} />

      <ClassNames>
        {({ css }) => (
          <Dialog
            className="drawer-modal_root"
            open
            disablePortal
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
            TransitionComponent={Transition}
            onClose={
              props.backdropClose === false
                ? undefined
                : () => {
                    modal.close();
                    props.onClose && props.onClose();
                  }
            }
            fullWidth={props.fullWidth !== false}
            maxWidth={maxWidth ? false : (props.maxWidth as any)}
            scroll={props.scroll}
            classes={{
              container: css(styles.container),
              paper: css({
                maxWidth: `${maxWidth} !important`,
                borderRadius: props.borderRadius && `${props.borderRadius}px !important`,
              }),
            }}
          >
            {props.children}
          </Dialog>
        )}
      </ClassNames>
    </Fragment>
  );
});
