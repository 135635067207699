import { FieldProps, FormControl, useFormField } from '@core/utils/form';
import { R } from '@core/utils/r';
import MFormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React, { useMemo } from 'react';
import { Label } from 'reactstrap';
import { FormSelectItem, FormSelectBaseProps } from './FormSelect';
import classNames from 'classnames';

export interface FormPillsProps<T extends string | number> extends FormSelectBaseProps<T>, FieldProps<FormControl<T>> {
  items: (FormSelectItem<T> | null)[];
}

export function FormPills<T extends any>(props: FormPillsProps<any>) {
  const { formControl, fieldState, fieldConfig } = useFormField(props);

  const fieldTouched = (fieldState && fieldState.touched) || formControl.inheritedSubmitted || false;
  const fieldError = (fieldTouched && fieldState.errorMessage) || null;

  const fieldValue = R.isNil(fieldState.value) ? '' : fieldState.value;

  const selectedValue = useMemo(() => {
    return R.find(props.items, i => (i ? i.value == fieldValue : null));
  }, [props.items, fieldValue]);

  const content = (
    <React.Fragment>
      <Label style={props.horizontal ? { marginBottom: 0, marginRight: 10 } : {}}>{fieldConfig.label}</Label>
      <MFormControl error={!!fieldError} fullWidth>
        <ul className="nav nav-pills" style={props.style}>
          {R.compact(props.items).map(item => (
            <li key={item.value as string} className="nav-item">
              <a
                className={classNames({ active: item.value === (selectedValue as any).value }, 'nav-link')}
                onClick={() => {
                  formControl.setValue(item.value);
                  props.onChange && props.onChange(item.value);
                }}
                id={item.value as string}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>

        {!!fieldError && <FormHelperText>{fieldError}</FormHelperText>}
      </MFormControl>
    </React.Fragment>
  );

  if (props.withoutGroup) return content;
  return (
    <div className={`${props.horizontal ? 'd-flex align-items-center ' : 'form-group'}`} style={props.style}>
      {content}
    </div>
  );
}
