import React, { memo, useMemo, Fragment } from 'react';
import { BlockAdvanceFormData } from '../Common/AdvanceSettings';
import { R } from '@core/utils/r';
import { GoogleFontLoader } from '@modules/common/GoogleFontLoader';

export interface BlockContainerProps extends React.HTMLProps<HTMLDivElement> {
  advanceForm?: BlockAdvanceFormData;
  excludeStyles?: (keyof BlockAdvanceFormData)[];
}
export const BlockContainer = memo((props: BlockContainerProps) => {
  const { advanceForm, style = {}, excludeStyles, ...rest } = props;
  const fontFamily = useMemo(() => {
    if (advanceForm?.font?.family) {
      return advanceForm.font.family;
    }
    return undefined;
  }, [advanceForm]);

  return (
    <Fragment>
      {!!advanceForm?.font && <GoogleFontLoader font={advanceForm?.font} />}
      <div {...rest} style={{ ...style, ...R.objectCompact(R.omit(R.omit(advanceForm, excludeStyles || []), 'font')), fontFamily }}>
        {props.children}
      </div>
    </Fragment>
  );
});
