import { R } from '@core/utils/r';
import { Endpoint } from '@business/api/endpoint';
import { ChangelogResponse, ChangelogRequest, ChangelogId } from '@business/entities/changelog';

export function fetchChangelogs(options: { search?: string } = {}) {
  const params = R.objectCompact({ search: options.search });
  return Endpoint.get<ChangelogResponse[]>(`@gc/changelogs`, { params });
}

export function createChangelog(data: ChangelogRequest) {
  return Endpoint.post<ChangelogResponse>(`@gc/changelogs`, { data });
}

export function updateChangelog(changelogId: ChangelogId, data: ChangelogRequest) {
  return Endpoint.patch<ChangelogResponse>(`@gc/changelogs/${changelogId}`, { data });
}

export function deleteChangelog(changelogId: ChangelogId) {
  return Endpoint.delete<void>(`@gc/changelogs/${changelogId}`);
}

export function fetchChagelog(changelogId: ChangelogId) {
  return Endpoint.get<ChangelogResponse>(`@gc/changelogs/changelog/${changelogId}`);
}
