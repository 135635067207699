import React from 'react';
import { createRoot } from 'react-dom/client';
import { finalize } from 'rxjs/operators';
import { ignoreError } from '@core/utils/rxjs/operators';
import { Session } from '@modules/auth/session';
import { registerBlocs } from '@business/blocs/register';
import { initBlocs } from '@business/blocs';
import App from './App';

export function boot() {
  registerBlocs();

  restoreSession();
}

function renderApp() {
  const root = createRoot(document.getElementById('application'));

  root.render(<App />);

  initBlocs();
}

function restoreSession() {
  Session.restore()
    .pipe(ignoreError(), finalize(renderApp))
    .subscribe();
}
