import React, { memo, useMemo, useCallback, DragEvent } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import classNames from 'classnames';
import { formGroup, formControl, FormDataType } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { ImageBlockSettings } from './ImageBlockSettings';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';
import { Container } from 'reactstrap';
import { FormHTMLInline } from '@core/components/form';
import { DropableImage } from '@modules/common/DropableImage';
import { ProposalService } from '@business/services';
import { useProposalDataContext } from '@modules/proposal/ProposalBuilderPage';
import { R } from '@core/utils/r';

export enum ImageBlockVariation {
  imageCentered = 'imageCentered',
  imageFullWidth = 'imageFullWidth',
  imageWithText = 'imageWithText',
  textOnImage = 'textOnImage',
}

function initialImageForVariation(variation: ImageBlockVariation) {
  switch (variation) {
    case ImageBlockVariation.imageCentered:
      return 'https://images.unsplash.com/photo-1541584285245-c83a93cce0e8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw0MTYxMTh8MHwxfHNlYXJjaHwxMnx8YmljeWNsZXxlbnwwfHx8fDE2NzkzMzY2NDQ&ixlib=rb-4.0.3&q=80&w=500';
    case ImageBlockVariation.imageFullWidth:
      return 'https://images.riseusercontent.com/f:jpg|png,a:retain,b:fff/assets/rise/assets/block-defaults/ocean.jpg';
    case ImageBlockVariation.imageWithText:
      return 'https://images.unsplash.com/photo-1547924326-032f60c4ada3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw0MTYxMTh8MHwxfHNlYXJjaHwxMnx8YnVzJTIwc3F1YXJlfGVufDB8fHx8MTY3OTMzNjkxNg&ixlib=rb-4.0.3&q=80&w=400';
    case ImageBlockVariation.textOnImage:
      return 'https://images.unsplash.com/photo-1536599018102-9f803c140fc1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw0MTYxMTh8MHwxfHNlYXJjaHw4fHxidWlsZGluZ3MlMjBiYWNrZ3JvdW5kfGVufDB8fHx8MTY3OTMzNjgwOA&ixlib=rb-4.0.3&q=80&w=1200';
  }
}

function defineImageBlockForm(variation: ImageBlockVariation) {
  return formGroup({
    image: formControl<string>({
      initialValue: initialImageForVariation(variation),
    }),
    text: formControl<string>({
      initialValue:
        'Vivamus conubia tellus fames torquent id congue gravida lorem, orci habitant massa mus erat turpis rhoncus, laoreet suscipit convallis felis mauris lectus nisi.',
    }),
    size: formControl<'contain' | 'cover' | 'fill'>({ label: 'Image Size', initialValue: 'cover' }),
    imagePosition: formControl<'image-right' | 'image-left'>({ label: 'Image Position', initialValue: 'image-left' }),
    imageWidth: formControl<string>({ label: 'Image Width', initialValue: 'image-w-50' }),
    backdropColor: formControl<string>({ label: 'Background Overlay Color', initialValue: '#000000' }),
    backdropOpacity: formControl<string>({ label: 'Background Overlay Opacity (%)', initialValue: '30' }),
    advance: defineBlockAdvanceForm(),
  });
}

export type ImageWidgetFormData = FormDataType<typeof defineImageBlockForm>;
export type ImageWidgetForm = ReturnType<typeof defineImageBlockForm>;

export const ImageBlock = memo((props: Proposal.BlockProps<ImageBlockVariation>) => {
  const { variation, preview, editable } = props;
  const form = useMemo(() => defineImageBlockForm(variation), [variation]);
  const formValue = useNonNilObservable(form.value$);
  const data = useProposalDataContext();

  const imgSrc = useMemo(() => {
    const defaultImage = initialImageForVariation(variation);
    const allDefaultImages = [
      'https://images.unsplash.com/photo-1541584285245-c83a93cce0e8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw0MTYxMTh8MHwxfHNlYXJjaHwxMnx8YmljeWNsZXxlbnwwfHx8fDE2NzkzMzY2NDQ&ixlib=rb-4.0.3&q=80&w=500',
      'https://images.riseusercontent.com/f:jpg|png,a:retain,b:fff/assets/rise/assets/block-defaults/ocean.jpg',
      'https://images.unsplash.com/photo-1547924326-032f60c4ada3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw0MTYxMTh8MHwxfHNlYXJjaHwxMnx8YnVzJTIwc3F1YXJlfGVufDB8fHx8MTY3OTMzNjkxNg&ixlib=rb-4.0.3&q=80&w=400',
      'https://images.unsplash.com/photo-1536599018102-9f803c140fc1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw0MTYxMTh8MHwxfHNlYXJjaHw4fHxidWlsZGluZ3MlMjBiYWNrZ3JvdW5kfGVufDB8fHx8MTY3OTMzNjgwOA&ixlib=rb-4.0.3&q=80&w=1200',
    ];

    if (allDefaultImages.indexOf(formValue.image) >= 0) {
      return defaultImage;
    }
    return formValue.image;
  }, [formValue.image, variation]);

  useConfigureBlock({
    props,
    form,
    contentComponent: ImageBlockSettings,
    contentProps: { form, variation },
  });

  const onDropImage = useCallback(
    (file: File) => {
      UploadAPI.upload('proposal/images', file)
        .pipe(tap(res => form.patchValue({ image: res.fileUrl })))
        .subscribe();
    },
    [form],
  );

  const handleDrag = useCallback(
    (e: DragEvent<HTMLImageElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

        if (validImageTypes.includes(file.type)) {
          onDropImage(file);
        }
      }
    },
    [onDropImage],
  );

  const textContent = useMemo(() => {
    const content = (
      <div className="text">
        <FormHTMLInline control={form.controls.text} readonly={!editable} transformedFieldsValue={ProposalService.getTransformedValues(data)} />
      </div>
    );
    if (variation === ImageBlockVariation.textOnImage) {
      return <Container style={{ display: 'flex' }}>{content}</Container>;
    } else if (variation === ImageBlockVariation.imageWithText) {
      return content;
    }
    return null;
  }, [form.controls.text, editable, data, variation]);

  const content = (
    <BlockContainer
      className={classNames('d-flex flex-fill', 'block-image', variation, preview, formValue.imagePosition, formValue.imageWidth)}
      advanceForm={formValue.advance}
    >
      {textContent}
      {variation === ImageBlockVariation.textOnImage && (
        <div
          onDrag={handleDrag}
          onDrop={handleDrag}
          onDragOver={handleDrag}
          className="backdrop"
          style={{ backgroundColor: formValue.backdropColor, opacity: R.toNumber(formValue.backdropOpacity) / 100 }}
        ></div>
      )}
      <DropableImage style={{ objectFit: formValue.size }} src={imgSrc} onDropImage={onDropImage} />
    </BlockContainer>
  );

  return [ImageBlockVariation.imageFullWidth, ImageBlockVariation.textOnImage].includes(variation) ? (
    content
  ) : (
    <div style={{ backgroundColor: formValue.advance.backgroundColor }}>
      <Container>{content}</Container>
    </div>
  );
});
