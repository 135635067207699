import React, { memo, useEffect, useRef, useCallback, useMemo } from 'react';
import { formControl, formGroup, FormDataType } from '@core/utils/form';
import { FormColorPicker, FormInput, FormAjaxSelect, FormSelect } from '@core/components/form';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { Col, Row, Label } from 'reactstrap';
import { UploadAPI } from '@business/api/upload';
import { tap, map } from 'rxjs/operators';
import { R } from '@core/utils/r';
import { Button } from '@core/components/Button';
import { GoogleAPI } from '@business/api/google_api';
import { CommonService, ProposalService } from '@business/services';
import { FontResponse } from '@business/entities/google';
import { openImageFinderModal } from '@modules/ImageFinder';

export function defineBlockAdvanceForm(props?: { paddingTop?: number; paddingBottom?: number }) {
  return formGroup({
    backgroundColor: formControl<string>({
      label: 'Background Color',
    }),
    color: formControl<string>({
      label: 'Text Color',
    }),
    borderColor: formControl<string>({
      label: 'Border Color',
    }),
    borderWidth: formControl<number>({
      label: 'Border width',
      initialValue: 0,
    }),
    borderRadius: formControl<number>({
      label: 'Border radius',
      initialValue: 0,
    }),
    borderStyle: formControl<string>({
      label: 'Border style',
      initialValue: 'solid',
    }),
    paddingTop: formControl<number>({
      label: 'Padding Top',
      initialValue: props?.paddingTop || 30,
    }),
    paddingBottom: formControl<number>({
      label: 'Padding Bottom',
      initialValue: props?.paddingBottom || 30,
    }),
    paddingLeft: formControl<number>({
      label: 'Padding Left',
      initialValue: 0,
    }),
    paddingRight: formControl<number>({
      label: 'Padding Right',
      initialValue: 0,
    }),
    background: formControl<string>({
      label: 'Background Image',
    }),
    font: formControl<FontResponse>({
      label: 'Font',
      placeholder: 'Search Google Fonts...',
    }),
    fontWeight: formControl<string>({
      label: 'Font Weight',
    }),
  });
}

export type BlockAdvanceForm = ReturnType<typeof defineBlockAdvanceForm>;
export type BlockAdvanceFormData = FormDataType<typeof defineBlockAdvanceForm>;

export interface AdvanceSettingsProps {
  form: BlockAdvanceForm;
}

export const AdvanceSettings = memo((props: AdvanceSettingsProps) => {
  const { form } = props;
  const formValue = useNonNilObservable(form.value$);

  useEffect(() => {
    form.patchValue({ borderStyle: formValue.borderWidth > 0 ? 'solid' : 'none' });
  }, [form, formValue.borderWidth]);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback(
    (files: FileList | null) => {
      if (!files) return null;
      if (files.length == 0) return null;
      const file = files[0];
      UploadAPI.upload('proposal/images', file)
        .pipe(tap(res => form.patchValue({ background: `url('${res.fileUrl}') center center / cover no-repeat` })))
        .subscribe();
    },
    [form],
  );

  const onSearch = useCallback((search: string) => {
    return GoogleAPI.fonts(search).pipe(map(fonts => CommonService.selectItems(fonts, 'family')));
  }, []);

  return (
    <div>
      <Row>
        <Col>
          <FormColorPicker control={form.controls.backgroundColor} />
        </Col>
        <Col>
          <FormColorPicker control={form.controls.color} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormAjaxSelect
            control={form.controls.font}
            callback={search => onSearch(search)}
            initalValue={formValue.font ? { label: formValue.font.family, value: formValue.font } : undefined}
          />
        </Col>
        <Col>
          <FormSelect control={form.controls.fontWeight} items={ProposalService.getAllFontWeight()} isClearable />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormInput type="number" control={form.controls.borderWidth} />
        </Col>
        <Col>
          <FormInput type="number" control={form.controls.borderRadius} />
        </Col>
      </Row>
      <FormColorPicker control={form.controls.borderColor} />
      <hr />
      <Row>
        <Col>
          <FormInput type="number" control={form.controls.paddingTop} />
        </Col>
        <Col>
          <FormInput type="number" control={form.controls.paddingBottom} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput type="number" control={form.controls.paddingLeft} />
        </Col>
        <Col>
          <FormInput type="number" control={form.controls.paddingRight} />
        </Col>
      </Row>

      <div className="d-flex justify-content-between">
        <Label>Background Image</Label>
        {!R.isEmpty(formValue.background) && <i onClick={() => form.patchValue({ background: undefined })} className="fa fa-times"></i>}
      </div>
      <div className="mt-2 mb-2">
        <a
          className="mb-2 mt-2 text-primary"
          onClick={() =>
            openImageFinderModal({ onSelect: background => form.patchValue({ background: `url('${background}') center center / cover no-repeat` }) })
          }
        >
          Select Image from Library
        </a>
      </div>
      <Button intent="light" block icon="fas fa-arrow-up" extraClassNames="mb-4 mt-2" onClick={() => inputFileRef.current?.click()}>
        Upload
      </Button>
      <input style={{ display: 'none' }} type="file" ref={inputFileRef} accept={'image/*'} onChange={e => onChange(e.target.files)} />
    </div>
  );
});
