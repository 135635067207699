import { IdeaType } from './../../entities/idea/IdeaResponse';
import { PageQuery, Paginate } from '@business/entities/common';
import { SearchHelpArticleResponse } from '@business/entities/helpArticle';
import { CompactIdeaCategoryResponse, CompactIdeaResponse, IdeaCommentResponse, IdeaId, IdeaResponse } from '@business/entities/idea';
import { R } from '@core/utils/r';
import { Endpoint } from '../endpoint';

export function fetchIdeas(options: { search?: string } = {}) {
  const params = R.objectCompact({ search: options.search, fromAdmin: true });
  return Endpoint.get<CompactIdeaResponse[]>(`@gc/ideas`, { params });
}

export function fetchIdea(ideaId: IdeaId) {
  return Endpoint.get<IdeaResponse>(`@gc/ideas/idea/${ideaId}`);
}

export function createComment(ideaId: IdeaId, data: Partial<IdeaCommentResponse>) {
  return Endpoint.post<IdeaCommentResponse>(`@gc/ideas/comment/${ideaId}`, { data });
}

export function updateComment(id: string, data: Partial<IdeaCommentResponse>) {
  return Endpoint.patch<IdeaCommentResponse>(`@gc/ideas/comment/${id}`, { data });
}

export function deleteIdea(ideaId: IdeaId) {
  return Endpoint.delete<void>(`@gc/ideas/${ideaId}`);
}

export function deleteComment(id: string) {
  return Endpoint.delete<void>(`@gc/ideas/comment/${id}`);
}

export function fetchCategories(params: PageQuery) {
  return Endpoint.get<CompactIdeaCategoryResponse[]>(`@gc/ideas/categories`, { params: R.objectCompact(params) });
}

export function fetchCategory(id: string) {
  return Endpoint.get<CompactIdeaCategoryResponse>(`@gc/ideas/category/${id}`, {});
}

export function createCategory(data: Partial<CompactIdeaCategoryResponse>) {
  return Endpoint.post<void>(`@gc/ideas/category`, { data });
}

export function updateCategory(id: string, data: Partial<CompactIdeaCategoryResponse>) {
  return Endpoint.patch<CompactIdeaCategoryResponse>(`@gc/ideas/category/${id}`, { data });
}

export function deleteCategory(id: string) {
  return Endpoint.delete<void>(`@gc/ideas/category/${id}`);
}

export function mergeIdea(ideaId: IdeaId, mergeIdeaId: IdeaId) {
  return Endpoint.post<CompactIdeaResponse>(`@gc/ideas/merge/${ideaId}`, { data: { mergeIdeaId } });
}

export function searchHelpArticles(search: string) {
  return Endpoint.get<SearchHelpArticleResponse[]>(`@gc/ideas/search-articles`, { params: { search } });
}

export function search(type: IdeaType) {
  const params = R.objectCompact({ type, fromAdmin: true });
  return Endpoint.get<CompactIdeaResponse[]>(`@gc/ideas`, { params });
}
