import React, { memo, useMemo } from 'react';
import { LineItemForm } from '@modules/proposal/ProposalBuilderPage/Components/ProposalProductsOverlay';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { Formatter } from '@core/utils/formatter';
import { TableColumn } from '@modules/common/TableColumn';
import { FormCheckbox, FormInput } from '@core/components/form';
import { DealIntervalResponse } from '@business/entities/deal';

export const PricingHeader = memo((props: { primaryColor?: string }) => {
  return (
    <div className="proposal-table-header" style={{ color: props.primaryColor }}>
      <TableColumn width={50} maxWidth={50}></TableColumn>
      <TableColumn flex={2}>Description</TableColumn>
      <TableColumn maxWidth={100}>Qty</TableColumn>
      <TableColumn maxWidth={125}>Price</TableColumn>
      <TableColumn maxWidth={200}>Subtotal</TableColumn>
    </div>
  );
});

export interface PricingFooterProps {
  total: number;
  primaryColor?: string;
}
export const PricingFooter = memo((props: PricingFooterProps) => {
  const { total } = props;

  return (
    <div className="proposal-table-footer d-flex">
      <TableColumn></TableColumn>
      <TableColumn maxWidth={200} width={200} style={{ fontWeight: 'bold' }}>
        {Formatter.formatCurrency(total, 'USD')}
      </TableColumn>
    </div>
  );
});

export interface LineItemProps {
  form: LineItemForm;
  index: number;
}

export const LineItem = memo((props: LineItemProps) => {
  const { form, index } = props;
  const formValue = useNonNilObservable(form.value$);

  const subtotal = useMemo(() => {
    const value = Formatter.formatCurrency(formValue.qty * formValue.rate, 'USD');
    if (formValue.frequency === DealIntervalResponse.onetime) {
      return value;
    } else {
      return `${value}/${formValue.frequency}`;
    }
  }, [formValue.frequency, formValue.qty, formValue.rate]);

  return (
    <div className="d-flex align-items-center" style={{ padding: '10px 0px' }}>
      <TableColumn width={50} maxWidth={50}>
        {formValue.isOptional && <FormCheckbox style={{ lineHeight: 1 }} noPadding control={form.controls.selected} />}
      </TableColumn>
      <TableColumn>{formValue.description}</TableColumn>
      <TableColumn maxWidth={100}>
        {formValue.flexibleQty ? <FormInput withoutGroup control={form.controls.qty} type="number" /> : formValue.qty}
      </TableColumn>
      <TableColumn maxWidth={125}>{Formatter.formatCurrency(formValue.rate, 'USD')}</TableColumn>
      <TableColumn maxWidth={200}>{subtotal}</TableColumn>
    </div>
  );
});
