import { match } from 'react-router-dom';
import { createContext, useContext } from 'react';
import { R } from '@core/utils/r';
import { createContextProvider } from '@core/utils/react';

export interface RouteContext<Params extends string = string> {
  match: match<Record<Params, string>>;
}

const Context = createContext<RouteContext | null>(null);

export const RouteContextProvider = createContextProvider(Context);

export function useOptionalRouteMatch<Params extends string>() {
  const context = useContext(Context);
  return context ? (context.match as match<Record<Params, string>>) : null;
}

export function useRouteMatch<Params extends string>() {
  const match = useOptionalRouteMatch<Params>();

  if (!match) throw new Error('[useRouteMatch] No route context provided');

  return match;
}

export function useRoutePath() {
  return useRouteMatch().path;
}

export function useRouteParams<Params extends string>() {
  return useRouteMatch<Params>().params;
}

export function useRouteParam(param: string, optional = false) {
  const value = useRouteParams()[param];

  if (R.isNil(value) && !optional) throw new Error('[useRouteParam] param not found: ' + param);

  return value;
}

export function useQueryStringParam(param: string, encodedVar: string | null = null) {
  let q = '';
  if (encodedVar) {
    q = new URLSearchParams(window.location.search).get(encodedVar) || '';
  }
  return new URLSearchParams(encodedVar ? atob(q) : window.location.search).get(param);
}
