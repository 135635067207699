import { BaseResponse } from './../common/BaseResponse';
import { ProposalFormData } from '@modules/proposal/ProposalBuilderPage';
import { DashboardResponse } from '../dashboard/DashboardResponse';

export type FolderId = string;
export type ItemId = string;

export interface DashboardlTemplateFolderBreadcrumbResponse {
  id: string;
  name: string;
}

export interface DashboardTemplateItemsResponse {
  breadcrumbs: DashboardlTemplateFolderBreadcrumbResponse[];
  items: (DashboardTemplateResponse | DashboardResponse)[];
}

export interface DashboardTemplateResponse extends BaseResponse {
  id: FolderId;
  name: string;
  type: DashboardTemplateItemType;
  items: number;
  parent?: DashboardTemplateResponse[];
  breadcrumbs?: DashboardlTemplateFolderBreadcrumbResponse[];
  parentFolderId: number;
  data?: ProposalFormData;
}

export interface ProposalTemplateItemResponse extends BaseResponse {
  id: ItemId;
  name: string;
  image?: string | File;
  type: DashboardTemplateItemType;
  folder?: DashboardTemplateResponse[];
  parentFolderId: FolderId;
}

export enum DashboardTemplateItemStatus {
  publish = 'publish',
  unpublish = 'unpublish',
}

export enum ProposalTemplateItemShareType {
  item = 'item',
  folder = 'folder',
}

export const ProposalTemplateItemStatuses = [DashboardTemplateItemStatus.publish, DashboardTemplateItemStatus.unpublish];

export interface CompactProposalTemplateItemResponse extends BaseResponse {
  status: any;
  id: ItemId;
  name: string;
  type: DashboardTemplateItemType;
  parentFolderId: FolderId;
  filePath: string;
  image?: string | File;
}

export enum DashboardTemplateItemType {
  folder = 'folder',
  template = 'template',
}

export interface CompactProposalTemplateFolderResponse {
  id: FolderId;
  name: string;
}

export const ProposalTemplateItemTypes = [DashboardTemplateItemType.folder, DashboardTemplateItemType.template];

export const ProposalTemplateItemTypeIcon: Record<DashboardTemplateItemType, string> = {
  folder: 'bx bx-folder',
  template: 'bx bx-file',
};

export const ProposalTemplateItemTypeColor: Record<DashboardTemplateItemType, string> = {
  folder: 'primary',
  template: 'secondary',
};

export interface CompactProposalTemplateSearchResponse {
  id: FolderId;
  name: string;
  type: DashboardTemplateItemType;
  parentFolderId: FolderId;
  breadcrumbs?: DashboardlTemplateFolderBreadcrumbResponse[];
}

export interface FolderBreadcrumbResponse {
  id: string;
  name: string;
}

export interface FolderStructureResponse {
  id: string;
  name: string;
  folders: FolderStructureResponse[];
  breadcrumbs?: FolderBreadcrumbResponse[];
}

export interface DashboardTemplateFolderStructureResponse {
  id: string;
  name: string;
  folders: FolderStructureResponse[];
  breadcrumbs?: FolderBreadcrumbResponse[];
}
