import React, { memo, Fragment } from 'react';
import { ChartBlockForm } from './ChartBlock';
import { useObservable } from '@core/utils/hooks/rxjs';
import { FormInput } from '@core/components/form';
import { Row, Col, Button } from 'reactstrap';
import { FormArrayInput, FormArrayInputGroup } from '@core/components/form/FormArrayInput';

export interface ChartBlockSettingsProps {
  form: ChartBlockForm;
}

export const ChartBlockSettings = memo((props: ChartBlockSettingsProps) => {
  const { form } = props;
  return (
    <div>
      <FormInput control={form.controls.heading} />
      <FormArrayInputGroup
        alwaysEditable
        label="Value"
        form={form.controls.values}
        onLabel={value => value?.label}
        onControl={form => (
          <Row key={form.uid}>
            <Col>
              <FormInput withoutGroup control={form.controls.label} />
            </Col>
            <Col>
              <FormInput withoutGroup control={form.controls.value} />
            </Col>
          </Row>
        )}
      />
    </div>
  );
});
