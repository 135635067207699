import React, { memo } from 'react';
import { ProposalBlockFormData, ProposalBlockForm } from '../../ProposalForm';
import { R } from '@core/utils/r';
import { SortableHandle, SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';
import { openBlockSettingsOverlay } from '../BlockSettingsOverlay';

export interface SectionItemProps {
  block: ProposalBlockFormData;
  onClick(): void;
  blockForm: ProposalBlockForm;
  onSettings(): void;
}

export const SectionItem = memo((props: SectionItemProps) => {
  const { block, blockForm } = props;

  const DragHandle = SortableHandle(() => (
    <a>
      <i className="psi-handle fa fa-grip-vertical mr-2" style={{ cursor: 'move' }} />
    </a>
  ));
  return (
    <div className={classNames('psi-section-item', { notSection: !block.isSection })} onClick={props.onClick}>
      <div style={{ width: '100%', padding: 5, paddingTop: 7, paddingBottom: 7 }} className="d-flex flex-row justify-content-between">
        <DragHandle />
        <span className="text-truncate" style={{ flex: 1 }}>
          {block.title || R.startCase(block.variation)}
          {block.locked && <i className="fa fa-lock ml-2 text-muted"></i>}
        </span>
        <a
          onClick={e => {
            e.stopPropagation();
            // openBlockSettingsOverlay({ form: blockForm });
            props.onSettings();
          }}
        >
          <i className="psi-setting fa fa-cog text-muted"></i>
        </a>
      </div>
    </div>
  );
});

export const SortableSectionItem = SortableElement(SectionItem);
