import { IdeaType } from './../../entities/idea/IdeaResponse';
import { HelpArticleAPI } from '@business/api/helpArticle';
import { showDeleteConfirmation, showConfirmation } from '@modules/common';
import { switchMap, tap } from 'rxjs/operators';
import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';
import { handleMessage, requestMessage } from '@business/messages';
import { R } from '@core/utils/r';
import { CompactIdeaResponse, IdeaResponse, IdeaId, ideaCommentVisibility } from '@business/entities/idea';
import { IdeaAPI } from '@business/api/idea_api';
import { map } from 'rxjs/operators';
import { CommonService } from '@business/services';
export class IdeaBloc extends BaseBloc {
  private readonly ideaRepo = new Repository<CompactIdeaResponse | IdeaResponse>({
    getItemId: idea => idea.id,
  });

  readonly ideas$ = this.ideaRepo.items$;
  readonly selectIdea = this.ideaRepo.selectItem;

  onReset() {
    this.ideaRepo.reset();
  }

  fetchIdeas = () => {
    return IdeaAPI.fetchIdeas().pipe(this.ideaRepo.ops.reset(), handleMessage({ error: requestMessage('fetch_ideas_error') }));
  };

  fetchIdea = (id: IdeaId) => {
    return IdeaAPI.fetchIdea(id).pipe(
      this.ideaRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_ideas_error') }),
    );
  };

  deleteIdea = (id: IdeaId) => {
    return showDeleteConfirmation('Delete Idea', 'Do you really want to remove this idea?').pipe(
      switchMap(() => IdeaAPI.deleteIdea(id)),
      this.ideaRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_idea'),
      }),
    );
  };

  createComment = (ideaId: IdeaId, comment: string, resolved: boolean, userName: string, visibility: ideaCommentVisibility) => {
    return IdeaAPI.createComment(ideaId, { comment, resolved, userName, visibility }).pipe(
      switchMap(() => this.fetchIdea(ideaId)),
      handleMessage({
        type: requestMessage('create_idea_comment'),
      }),
    );
  };

  updateComment = (ideaId: IdeaId, id: string, comment: string) => {
    return IdeaAPI.updateComment(id, { comment }).pipe(
      switchMap(() => this.fetchIdea(ideaId)),
      handleMessage({
        type: requestMessage('update_idea_comment'),
      }),
    );
  };

  deleteComment = (ideaId: IdeaId, id: string) => {
    return showDeleteConfirmation('Delete Comment', 'Do you really want to remove this Comment?').pipe(
      switchMap(() => IdeaAPI.deleteComment(id)),
      switchMap(() => this.fetchIdea(ideaId)),
      handleMessage({
        type: requestMessage('delete_idea_comment'),
      }),
    );
  };

  mergeIdea = (ideaId: IdeaId, mergeIdeaId: IdeaId) => {
    return IdeaAPI.mergeIdea(ideaId, mergeIdeaId).pipe(
      this.ideaRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('merge_idea'),
      }),
    );
  };

  searchHelpArticles = (search: string) => {
    return IdeaAPI.searchHelpArticles(search).pipe(map(data => CommonService.selectItems(data, 'title', 'id')));
  };

  search = (type: IdeaType) => {
    return IdeaAPI.search(type).pipe(this.ideaRepo.ops.reset(), handleMessage({ error: requestMessage('fetch_ideas_error') }));
  };
}
