export type PageId = string;
export interface ProposalPageResponse {
  id: PageId;
  title: string;
}

export enum PageSize {
  letter = 'letter',
  legal = 'legal',
  a4 = 'a4',
}
