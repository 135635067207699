import React, { memo, PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';
import { Session } from '@modules/auth/session';
import { LazyRoute, LazyRouteProps } from './LazyRoute';

export const PrivateRoute = memo((props: PropsWithChildren<LazyRouteProps>) => {
  return <LazyRoute {...props} guard={guardPrivateRoute} />;
});

export const PublicRoute = memo((props: PropsWithChildren<LazyRouteProps>) => {
  return <LazyRoute {...props} />;
});

function guardPrivateRoute() {
  return Session.isAuthenticated ? null : <Redirect to="/login" />;
}

function guardPublicRoute() {
  return !Session.isAuthenticated ? null : <Redirect to="/customers" />;
}
