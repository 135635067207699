import { DashboardTemplateAPI } from '@business/api/dashboard_template_api';
import { FolderId, ItemId, ProposalTemplateFolderBreadcrumbResponse } from '@business/entities/proposal';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { R } from '@core/utils/r';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DashboardTemplateItemType, DashboardTemplateResponse } from '@business/entities/dashboardTemplate';
import { DashboardResponse } from '@business/entities/dashboard';

export class DashboardTemplateBloc extends BaseBloc {
  private readonly itemsRepo = new Repository<DashboardResponse | DashboardTemplateResponse>({
    getItemId: item => `${item.type}-${item.id}`,
  });

  readonly items$ = this.itemsRepo.items$;

  readonly selectItem = this.itemsRepo.selectItem;
  readonly breadcrumb$ = new BehaviorSubject<ProposalTemplateFolderBreadcrumbResponse[]>([]);

  readonly getFolderItems = (folderId: FolderId) => {
    folderId = !folderId ? '0' : folderId;
    return this.items$.pipe(
      map(items => {
        return R.sortBy(
          R.filter(items, i => i.parentFolderId === parseInt(folderId)),
          [item => (item.type === DashboardTemplateItemType.folder ? 0 : 1), DashboardTemplateItemType.folder ? 'name' : 'title'],
          ['asc', 'asc'],
        );
      }),
    );
  };

  readonly folders$ = this.items$.pipe(
    map(items =>
      R.sortBy(
        R.filter(items, i => i.type === DashboardTemplateItemType.folder),
        i => i.name.toLowerCase(),
      ),
    ),
  );

  onReset() {
    this.itemsRepo.reset();
  }

  getItems = (parentId?: FolderId, search?: string) => {
    return DashboardTemplateAPI.getItems(parentId, search).pipe(
      map(data => {
        this.breadcrumb$.next([...data.breadcrumbs]);
        return data.items;
      }),
      this.itemsRepo.ops.reset(),
      handleMessage({ error: requestMessage('fetch_files_error') }),
    );
  };

  createFolder = (name: string, parentId?: FolderId) => {
    return DashboardTemplateAPI.createFolder(name, parentId).pipe(
      this.itemsRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_folder'),
      }),
    );
  };

  renameFolder = (id: FolderId, name: string) => {
    return DashboardTemplateAPI.renameFolder(id, name).pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('rename_folder'),
      }),
    );
  };

  deleteFolder = (id: FolderId) => {
    return showDeleteConfirmation('Delete Folder', 'Do you really want to remove this folder?').pipe(
      switchMap(() => DashboardTemplateAPI.deleteFolder(id)),
      this.itemsRepo.ops.removeOne(() => `folder-${id}`),
      handleMessage({
        type: requestMessage('delete_folder'),
      }),
    );
  };

  getItem = (id: ItemId) => {
    return DashboardTemplateAPI.getItem(id, '').pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_proposal_template_error') }),
    );
  };

  getItemPublic = (id: ItemId) => {
    return DashboardTemplateAPI.getItemPublic(id).pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_proposal_template_error') }),
    );
  };

  createItem = (item: Partial<DashboardResponse>, parentId?: FolderId) => {
    return DashboardTemplateAPI.createItem(item, parentId).pipe(
      //   this.itemsRepo.ops.addOne(item => item),
      this.itemsRepo.ops.upsertMany(item => ({ item })),
      handleMessage({
        type: requestMessage('create_folder_item'),
      }),
    );
  };

  duplicateItem = (id: ItemId, title: string) => {
    return DashboardTemplateAPI.duplicateItem(id, title).pipe(
      this.itemsRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('duplicate_proposal_template_item'),
      }),
    );
  };

  updateItem = (id: ItemId, item: Partial<DashboardResponse>) => {
    return DashboardTemplateAPI.updateItem(item, id).pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_folder_item'),
      }),
    );
  };

  updateFormData = (id: ItemId, data: string) => {
    return DashboardTemplateAPI.updateFormData(id, data).pipe(
      this.itemsRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        error: requestMessage('update_proposal_template_error'),
      }),
    );
  };

  deleteItem = (id: ItemId, type: string) => {
    return showDeleteConfirmation('Delete Folder', 'Do you really want to remove this item?').pipe(
      switchMap(() => DashboardTemplateAPI.deleteItem(id)),
      this.itemsRepo.ops.removeOne(() => `${type}-${id}`),
      handleMessage({
        type: requestMessage('delete_folder_item'),
      }),
    );
  };

  folderStructure = (id: FolderId) => {
    return DashboardTemplateAPI.folderStructure(id).pipe(
      handleMessage({
        error: requestMessage('fetch_general_error'),
      }),
    );
  };

  move = (id: string, type: DashboardTemplateItemType, destinationId: string) => {
    return DashboardTemplateAPI.move(id, type, destinationId).pipe(
      handleMessage({
        error: requestMessage('move_folder_error'),
      }),
    );
  };
}
