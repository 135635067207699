import React, { memo, useMemo } from 'react';
import { Redirect as RouterRedirect, RedirectProps as RouterRedirectProps } from 'react-router-dom';
import { useOptionalRouteMatch } from './RouteContext';
import { getRedirectPath } from './utils';

export interface RedirectProps extends Omit<RouterRedirectProps, 'from' | 'to' | 'path'> {
  from?: string;
  to?: string;
  absoluteFrom?: string;
  absoluteTo?: string;
  computedFrom?: string;
  computedTo?: string;
}

export const Redirect = memo((props: RedirectProps) => {
  const { computedFrom, computedTo, absoluteFrom, absoluteTo, from: relativeFrom, to: relativeTo, ...rest } = props;

  const match = useOptionalRouteMatch();

  const from = useMemo(() => {
    if (computedFrom) return computedFrom;

    return getRedirectPath(absoluteFrom, relativeFrom, match?.path);
  }, [match, computedFrom, absoluteFrom, relativeFrom]);

  const to = useMemo(() => {
    if (computedTo) return computedTo;

    return getRedirectPath(absoluteTo, relativeTo, match?.path);
  }, [match, computedTo, absoluteTo, relativeTo]);

  return <RouterRedirect {...rest} from={from} to={to} />;
});
