import React, { memo, useMemo, useCallback, DragEvent } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import classNames from 'classnames';
import { formGroup, formControl, FormDataType, formArray, Validators } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable, useObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import ReactApexChart from 'react-apexcharts';
import ReactEcharts from 'echarts-for-react';
import { of } from 'rxjs';
import { ChartBlockSettings } from './ChartBlockSettings';
import { useProposalContext } from '@modules/proposal/ProposalBuilderPage';
import { Container } from 'reactstrap';
import { getBarChartProps, getLineChartProps, getPieChartProps } from './ChartService';

function defineChartValueForm() {
  return formGroup({
    label: formControl<string>({ placeholder: 'Label' }),
    value: formControl<number>({ placeholder: 'Value', validators: [Validators.number] }),
  });
}

function defineChartWidgetForm() {
  return formGroup({
    heading: formControl<string>({ initialValue: 'Chart Title' }),
    values: formArray(() => defineChartValueForm()),
    advance: defineBlockAdvanceForm(),
  });
}

export type ChartBlockForm = ReturnType<typeof defineChartWidgetForm>;
export type ChartBlockFormData = FormDataType<typeof defineChartWidgetForm>;

export enum ChartBlockVariation {
  lineChart = 'lineChart',
  pieChart = 'pieChart',
  barChart = 'barChart',
}

export const ChartBlock = memo((props: Proposal.BlockProps<ChartBlockVariation>) => {
  const { variation, editable, preview } = props;

  const proposal = useProposalContext();
  const form = useMemo(defineChartWidgetForm, []);
  const formValue = useNonNilObservable(form.value$);
  const primaryColor = useMemo(() => proposal?.form.value.primaryColor || '#45A8F7', [proposal]);

  const values = useMemo(() => formValue.values.map(v => v.value), [formValue.values]);
  const labels = useMemo(() => formValue.values.map(v => v.label), [formValue.values]);

  useConfigureBlock({
    props,
    form,
    contentComponent: ChartBlockSettings,
    contentProps: { form },
  });


  return (
    <Container>
      <BlockContainer className={classNames('block-chart', variation)} advanceForm={formValue.advance} >
        {variation === ChartBlockVariation.barChart && (
          <ReactApexChart {...getBarChartProps(labels, values, primaryColor, formValue.heading)} type="bar" height={preview ? 250 : 400} />
        )}
        {variation === ChartBlockVariation.lineChart && (
          <ReactEcharts
            style={{ flex: 1, width: '100%', height: preview ? 250 : 400 }}
            option={getLineChartProps(labels, values, primaryColor, formValue.heading)}
          />
        )}
        {variation === ChartBlockVariation.pieChart && (
          <ReactEcharts
            style={{ flex: 1, width: '100%', height: preview ? 250 : 400 }}
            option={getPieChartProps(labels, values, primaryColor, formValue.heading)}
          />
        )}
      </BlockContainer>
    </Container>
  );
});
