import { CannedId, CannedResponse, CannedResponseType } from '@business/entities/canned';
import { ChannelId } from '@business/entities/SupportChannel';
import { Endpoint } from '../endpoint';

export function fetchResponses(search: string, channelId?: ChannelId, type?: CannedResponseType) {
  return Endpoint.get<CannedResponse[]>(`@gc/canned`, { params: { search, channelId, type } });
}

export function createCannedResponse(data: Partial<CannedResponse>) {
  return Endpoint.post<CannedResponse>(`@gc/canned`, { data });
}

export function updateCanned(id: CannedId, data: Partial<CannedResponse>) {
  return Endpoint.patch<CannedResponse>(`@gc/canned/${id}`, { data });
}

export function deleteCanned(id: CannedId) {
  return Endpoint.delete<void>(`@gc/canned/${id}`);
}
