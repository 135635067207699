import { BaseBloc } from '@core/utils/bloc';
import { TokenData } from '@core/utils/ioc';
import { CustomerBloc } from './customer_bloc';
import { HelpArticleBloc } from './help_article_bloc';
import { HelpArticleCategoryBloc } from './help_article_category_bloc';
import { IdeaCategoryBloc } from './idea_category_bloc';
import { IntegrationBloc } from './integration_bloc';
import { Blocs } from './tokens';
import { UserBloc } from './user_bloc';
import { IdeaBloc } from './idea_bloc';
import { ChangelogBloc } from './changelog_bloc';
import { ServiceBloc } from './service_bloc';
import { ProposalTemplateBloc } from './proposal_template_bloc';
import { DashboardTemplateBloc } from './dashboard_template_bloc';
import { DynamincReportBloc } from './dynamic_report_bloc';
import { DashboardBloc } from './dashboard_bloc';
import { ReportBloc } from './report_bloc';
import { TeamBloc } from './team_bloc';
import { OrganizationBloc } from './organization_bloc';
import { TagBloc } from './tag_bloc';
import { SupportChannelBloc } from './support_channel_bloc';
import { SupportCategoriesBloc } from './support_categories_bloc';
import { ReportCategoryBloc } from './report_category_bloc';
import { CannedBloc } from './canned_bloc';
import { CustomerClientsBloc } from './customer_clients_bloc/CustomerClientBloc';
import { GlossaryBloc } from './glossary_bloc';
import { AssistantBloc } from './assistant_bloc';

export const BLOCS: [TokenData<BaseBloc>, Constructor<BaseBloc>][] = [
  // [Blocs.websites, WebsitesBloc],
  // [Blocs.rentals, RentalsBloc],
  // [Blocs.domains, DomainsBloc],
  [Blocs.customers, CustomerBloc],
  [Blocs.customerClients, CustomerClientsBloc],
  [Blocs.helpArticle, HelpArticleBloc],
  [Blocs.helpArticleCategory, HelpArticleCategoryBloc],
  [Blocs.user, UserBloc],
  [Blocs.integration, IntegrationBloc],
  [Blocs.ideaCategory, IdeaCategoryBloc],
  [Blocs.idea, IdeaBloc],
  [Blocs.changelog, ChangelogBloc],
  [Blocs.service, ServiceBloc],
  [Blocs.proposalTemplate, ProposalTemplateBloc],
  [Blocs.dashboardTemplate, DashboardTemplateBloc],
  [Blocs.dynamicReport, DynamincReportBloc],
  [Blocs.dashboard, DashboardBloc],
  // [Blocs.report, ReportBloc],
  // [Blocs.team, TeamBloc],
  // [Blocs.organization, OrganizationBloc],
  // [Blocs.tag, TagBloc],
  // [Blocs.supportChannel, SupportChannelBloc],
  // [Blocs.supportCategory, SupportCategoriesBloc],
  [Blocs.reportCategory, ReportCategoryBloc],
  [Blocs.canned, CannedBloc],
  [Blocs.glossary, GlossaryBloc],
  [Blocs.assistant, AssistantBloc],
];
