import React, { memo, useMemo } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import { formGroup, formControl, FormDataType } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { Container } from 'reactstrap';
import { FormHTMLInline } from '@core/components/form';
import { R } from '@core/utils/r';
import { TextBlockSettings } from './TextBlockSettings';
import { Paragraph } from './Paragraph';
import { useProposalDataContext } from '@modules/proposal/ProposalBuilderPage';
import { ProposalService } from '@business/services';

function defineTextWidgetForm() {
  return formGroup({
    heading: formControl<string>({ initialValue: 'Heading' }),
    paragraph: formControl<string>({
      initialValue:
        'Quam nisi convallis imperdiet porta venenatis ultricies litora netus in pellentesque vulputate laoreet, integer varius metus velit conubia leo purus egestas curabitur cubilia.',
    }),
    paragraph2: formControl<string>({
      initialValue:
        'Quam nisi convallis imperdiet porta venenatis ultricies litora netus in pellentesque vulputate laoreet, integer varius metus velit conubia leo purus egestas curabitur cubilia.',
    }),
    paragraph3: formControl<string>({
      initialValue:
        'Quam nisi convallis imperdiet porta venenatis ultricies litora netus in pellentesque vulputate laoreet, integer varius metus velit conubia leo purus egestas curabitur cubilia.',
    }),
    paragraph4: formControl<string>({
      initialValue:
        'Quam nisi convallis imperdiet porta venenatis ultricies litora netus in pellentesque vulputate laoreet, integer varius metus velit conubia leo purus egestas curabitur cubilia.',
    }),
    columnsWidths: formControl<string>({
      initialValue: '1',
      label: 'Columns Widths (ex: 1 2 1)',
    }),
    column1Background: formControl<string>({
      label: 'Column 1 Background',
    }),
    column2Background: formControl<string>({
      label: 'Column 2 Background',
    }),
    column3Background: formControl<string>({
      label: 'Column 3 Background',
    }),
    column4Background: formControl<string>({
      label: 'Column 4 Background',
    }),
    advance: defineBlockAdvanceForm(),
  });
}

export type TextBlockForm = ReturnType<typeof defineTextWidgetForm>;
export type TextBlockFormData = FormDataType<typeof defineTextWidgetForm>;

export enum TextBlockVariation {
  paragraph = 'paragraph',
  paragraphWithHeading = 'paragraphWithHeading',
  paragraphWithSubheading = 'paragraphWithSubheading',
  heading = 'heading',
  subheading = 'subheading',
  twoColumn = 'twoColumn',
  threeColumn = 'threeColumn',
  fourColumn = 'fourColumn',
}

export const TextBlock = memo((props: Proposal.BlockProps<TextBlockVariation>) => {
  const { variation, editable } = props;
  const form = useMemo(defineTextWidgetForm, []);
  const formValue = useNonNilObservable(form.value$);
  const data = useProposalDataContext();

  useConfigureBlock({
    props,
    form,
    contentProps: { form },
    contentComponent: TextBlockSettings,
    onlyAdvance: true,
  });

  const hasHeading = useMemo(() => [TextBlockVariation.heading, TextBlockVariation.paragraphWithHeading].includes(variation), [variation]);
  const hasSubheading = useMemo(() => [TextBlockVariation.subheading, TextBlockVariation.paragraphWithSubheading].includes(variation), [variation]);
  const hasParagraph = useMemo(
    () =>
      [
        TextBlockVariation.paragraph,
        TextBlockVariation.paragraphWithHeading,
        TextBlockVariation.paragraphWithSubheading,
        TextBlockVariation.twoColumn,
        TextBlockVariation.threeColumn,
        TextBlockVariation.fourColumn,
      ].includes(variation),
    [variation],
  );

  const heading = useMemo(() => {
    if (!hasHeading && !hasSubheading) return null;
    const content = (
      <FormHTMLInline control={form.controls.heading} readonly={!editable} transformedFieldsValue={ProposalService.getTransformedValues(data)} />
    );
    return hasHeading ? <h1>{content}</h1> : <h3>{content}</h3>;
  }, [data, editable, form.controls.heading, hasHeading, hasSubheading]);

  const columns = useMemo(() => {
    if (variation === TextBlockVariation.twoColumn) return 2;
    if (variation === TextBlockVariation.threeColumn) return 3;
    if (variation === TextBlockVariation.fourColumn) return 4;
    return 1;
  }, [variation]);

  const columnsWidths = useMemo(() => {
    const widths = formValue.columnsWidths.split(' ');
    return R.times(columns).map(i => widths[i] || 1);
  }, [columns, formValue.columnsWidths]);

  return (
    <BlockContainer
      className="block-container d-flex flex-fill block-text"
      advanceForm={formValue.advance}
      excludeStyles={['borderRadius', 'borderWidth', 'borderColor', 'borderStyle', 'paddingTop', 'paddingBottom', 'paddingRight', 'paddingLeft']}
      style={{ padding: props.preview ? '30px 20px' : '0px' }}
    >
      <Container
        style={R.objectCompact(
          R.pick(formValue.advance, ['borderRadius', 'borderWidth', 'borderStyle', 'paddingTop', 'paddingBottom', 'paddingLeft', 'paddingRight']),
        )}
      >
        <div style={{ padding: '0 15px' }}>{heading}</div>
        {hasParagraph && (
          <div
            style={{
              fontSize: props.preview ? 14 : 'inherit',
              display: 'grid',
              gridTemplateColumns: R.times(columns)
                .map(i => `${columnsWidths[i]}fr`)
                .join(' '),
              columnGap: '1rem',
              rowGap: '2rem',
            }}
            className={`cols-${columns}`}
          >
            <Paragraph
              style={{ backgroundColor: formValue.column1Background, padding: '30px 15px' }}
              control={form.controls.paragraph}
              editable={editable}
            />
            {columns > 1 && (
              <Paragraph
                style={{ backgroundColor: formValue.column2Background, padding: '30px 15px' }}
                control={form.controls.paragraph2}
                editable={editable}
              />
            )}
            {columns > 2 && (
              <Paragraph
                style={{ backgroundColor: formValue.column3Background, padding: '30px 15px' }}
                control={form.controls.paragraph3}
                editable={editable}
              />
            )}
            {columns > 3 && (
              <Paragraph
                style={{ backgroundColor: formValue.column4Background, padding: '30px 15px' }}
                control={form.controls.paragraph4}
                editable={editable}
              />
            )}
          </div>
        )}
      </Container>
    </BlockContainer>
  );
});
