import React, { memo, useMemo, ComponentType } from 'react';
import * as Router from 'react-router-dom';
import { useOptionalRouteMatch } from './RouteContext';
import { getLinkPath } from './utils';

export const Link = createRouterLink<Router.LinkProps>(Router.Link);

export const NavLink = createRouterLink<Router.NavLinkProps>(Router.NavLink, { activeClassName: 'active' });

function createRouterLink<P>(component: ComponentType<P>, defaultProps?: Partial<P>) {
  return memo((props: Omit<P, 'to'> & { to: string }) => {
    const { to: path, ...rest } = { ...defaultProps, ...props };

    const match = useOptionalRouteMatch();

    const to = useMemo(() => getLinkPath(path, match?.url), [match, path]);

    const Component = component as any;

    return <Component {...rest} to={to} />;
  });
}
