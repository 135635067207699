import React, { memo, useMemo, useCallback, DragEvent } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import classNames from 'classnames';
import { formGroup, formControl, FormDataType, formArray } from '@core/utils/form';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable, useObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { useProposalContext } from '@modules/proposal/ProposalBuilderPage';
import { R } from '@core/utils/r';
import { of } from 'rxjs';
import { Formatter } from '@core/utils/formatter';
import { LineItem, PricingHeader, PricingFooter } from './LineItem';
import { defineProposalLineItemForm, openProposalProductsOverlay } from '@modules/proposal/ProposalBuilderPage/Components/ProposalProductsOverlay';
import { Container } from 'reactstrap';
import { useDidMount } from '@core/utils/hooks/react';
import { Config } from '@config';
import { DealIntervalResponse } from '@business/entities/deal';

function getDummyProduct(description: string, qty: number, rate: number) {
  return {
    id: R.random(1, 9999),
    qty,
    rate,
    selected: true,
    description,
    taxable: false,
    isOptional: false,
    service: '',
    classId: '',
    flexibleQty: false,
    frequency: DealIntervalResponse.onetime,
    salesReps: [],
  };
}

export enum PricingBlockVariation {
  pricing = 'pricing',
}

export const PricingBlock = memo((props: Proposal.BlockProps<PricingBlockVariation>) => {
  const { variation } = props;
  const proposal = useProposalContext();
  const form = useMemo(() => {
    if (proposal) return proposal.form;
    return formGroup({
      products: formArray(() => defineProposalLineItemForm()),
      productsAdvance: defineBlockAdvanceForm(),
    });
  }, [proposal]);

  const formValue = useNonNilObservable(form.value$);
  const lineItems = useMemo(() => formValue.products, [formValue.products]);
  const lineItemForms = useObservable(form.controls.products.items$, []);

  const primaryColor = useMemo(() => proposal?.form.value.primaryColor, [proposal]);

  useDidMount(() => {
    if (Config.IS_ADMIN) {
      form.patchValue({
        products: [
          getDummyProduct('LinkedIn Ads Set-up', 1, 500),
          getDummyProduct('Creation of a Banner Video for your Website', 1, 2500),
          getDummyProduct('PPC and Management cost - 10% of Ad Spend', 1, 999),
          getDummyProduct('Creation of a Business Card Design', 1, 1200),
        ],
      });
    }
  });

  const total = useMemo(() => formValue.products.reduce((acc, item) => acc + (item.selected ? item.qty * item.rate : 0), 0), [formValue.products]);

  useConfigureBlock({
    props,
    onEdit: () =>
      openProposalProductsOverlay({
        initialItems: Config.IS_ADMIN ? [] : formValue.products,
        advanceForm: formValue.productsAdvance,
        onSubmit: (products, productsAdvance) => {
          if (Config.IS_ADMIN) {
            form.patchValue({ productsAdvance });
          } else {
            form.patchValue({ products, productsAdvance });
          }
        },
      }),
  });


  return (
    <BlockContainer advanceForm={formValue.productsAdvance} className={classNames('d-flex flex-fill', 'block-pricing', variation)} >
      <Container>
        <PricingHeader primaryColor={primaryColor} />
        {lineItemForms.map((item, index) => (
          <LineItem key={item.uid} form={item} index={index} />
        ))}
        <PricingFooter total={total} primaryColor={primaryColor} />
      </Container>
    </BlockContainer>
  );
});
