import React, { memo, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import './index.scss';
import { defineProposalForm } from './ProposalForm';
import { useNonNilObservable, useObservable } from '@core/utils/hooks/rxjs';
import { useDidMount } from '@core/utils/hooks/react';
import { R } from '@core/utils/r';
import { Storage } from '@core/utils/storage';
import { useBloc } from '@core/utils/bloc';
import { ProposalBuilderPageBloc } from './ProposalBuilderPageBloc';
import { ProposalContext } from './ProposalContext';
import { useLazyEffect } from '@core/utils/react';
import { useMainPageConfig } from '@modules/dashboard/DashboardPage/MainPageContext';
import { ProposalBlocs, ProposalBlocsHandle } from './Components/ProposalPage';
import { ProposalSections } from './Components/SectionBar';
import { ProposalHeader } from './Components/ProposalHeader';
import { toMoment } from '@core/utils/time';
import { useRouteParam } from '@core/router';
import { useLoadingState } from '@core/utils/repository/loading_state';
import { TopLoadingBar } from '@core/components/LoadingBar';
import { Proposal } from '../Blocks/types';
import { GoogleFontLoader } from '@modules/common/GoogleFontLoader';
import { css } from '@emotion/react';
import { TextBlockVariation } from '../Blocks/TextBlock';

export const ProposalBuilderPage = memo(() => {
  const templateId = useRouteParam('id');
  const bloc = useBloc(ProposalBuilderPageBloc, templateId);
  const template = useObservable(bloc.template$);
  const loading = useLoadingState(bloc.loading);
  const form = useMemo(() => defineProposalForm(), []);
  const formValue = useNonNilObservable(form.value$);
  const currentSection = useNonNilObservable(bloc.currentSection$);
  const [scale, setScale] = useState(1);
  const [sidebar, setSidebar] = useState(true);
  const blockRefs = useMemo(() => R.times(formValue.blocks.length).map(() => React.createRef<HTMLDivElement>()), [formValue.blocks.length]);
  const [moveTo, setMoveto] = React.useState<number | null>(null);
  const [lastSaved, setLastSaved] = useState<number | null>(null);
  const [canSave, setCanSave] = useState(false);
  const [isInit, setInit] = useState(false);
  const blockHandlesRef = useRef<ProposalBlocsHandle>(null);

  useEffect(() => {
    if (formValue) {
      setCanSave(true);
    }
  }, [formValue]);

  useLazyEffect(() => {
    if (moveTo != null && blockRefs[moveTo] && blockRefs[moveTo].current) {
      window.scrollTo({ top: blockRefs[moveTo].current!.offsetTop, behavior: 'smooth' });
      setMoveto(null);
    }
  }, [moveTo, blockRefs]);

  const toggleSidebar = useCallback(() => setSidebar(s => !s), []);

  useMainPageConfig(() => ({ footer: false, toolbar: false, header: false }));

  const onSave = useCallback(() => {
    if (loading.loaded) {
      setLastSaved(toMoment().unix());

      bloc.updateFormData(templateId, JSON.stringify(formValue)).subscribe();
      // Storage.set('proposal', JSON.stringify(formValue));
      setCanSave(false);
    }
  }, [templateId, formValue, bloc, loading]);

  useLazyEffect(
    () => {
      if (formValue) onSave();
    },
    [formValue, onSave],
    5000,
  );

  useEffect(() => {
    if (loading.loaded && template && !isInit) {
      setInit(true);
      const data = template.data;
      if (data) {
        form.patchValue(data);
      } else {
        form.patchValue({
          title: template.name,
          blocks: [
            {
              id: 'section-title-0',
              locked: false,
              type: Proposal.BlockType.text,
              data: { title: 'Section Title' },
              variation: TextBlockVariation.paragraphWithHeading,
              menuOrder: 0,
              isSection: true,
              title: undefined,
            },
          ],
        });
      }
    }
  }, [loading, template, isInit, form]);

  if (loading.loading) return <TopLoadingBar />;

  return (
    <div id="proposal-builder" style={{ margin: 0 }}>
      <ProposalContext.Provider value={{ form, currentSection, bloc, scale, setScale, sidebar, toggleSidebar }}>
        <ProposalHeader id={templateId} lastSaved={lastSaved} onSave={onSave} canSave={canSave} />
        <ProposalSections moveTo={setMoveto} handleRef={blockHandlesRef} />
        {!!formValue.font && <GoogleFontLoader font={formValue.font} />}
        <div
          css={css(`
          h1 {
            font-size: ${formValue.headingFontSize + 'px'};
          }
        `)}
          id="proposal-container"
          style={{
            transform: `scale(${scale})`,
            marginLeft: sidebar ? 250 : 0,
            fontSize: formValue.fontSize + 'px',
            fontFamily: formValue.font?.family,
            fontWeight: formValue.fontWeight,
          }}
        >
          <ProposalBlocs handlesRef={blockHandlesRef} blockRefs={blockRefs} moveTo={setMoveto} />
        </div>
      </ProposalContext.Provider>
    </div>
  );
});
