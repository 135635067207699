import React, { memo, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { ListWidgetForm } from './ListBlock';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';
import { FormColorPicker, FormInput } from '@core/components/form';
export interface ListBlockSettingsProps {
  form: ListWidgetForm;
}

export const ListBlockSettings = memo((props: ListBlockSettingsProps) => {
  const { form } = props;
  const formValue = useNonNilObservable(form.value$);

  return (
    <div>
      <FormColorPicker control={form.controls.listColor} />
    </div>
  );
});
