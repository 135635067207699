import React, { memo } from 'react';
import { FormHTMLInline } from '@core/components/form';
import { FormControl } from '@core/utils/form';
import { useProposalDataContext } from '@modules/proposal/ProposalBuilderPage';
import { ProposalService } from '@business/services';

export interface ParagraphProps extends React.HTMLProps<HTMLDivElement> {
  control: FormControl<string>;
  editable?: boolean;
}

export const Paragraph = memo((props: ParagraphProps) => {
  const { control, editable, ...rest } = props;
  const data = useProposalDataContext();
  return (
    <div {...rest}>
      <FormHTMLInline control={control} multipleLines readonly={!editable} transformedFieldsValue={ProposalService.getTransformedValues(data)} />
    </div>
  );
});
