import { PageSize } from '@business/entities/proposal';
import { Proposal } from '@modules/proposal/Blocks/types';
import { ProposalTemplateItemStatus } from '@business/entities/proposal';
import { CommonService } from '../common_service';
import { ProposalDataType } from '@modules/proposal/ProposalBuilderPage';
import { Formatter } from '@core/utils/formatter';
import { toMoment } from '@core/utils/time';

export function getHeight(width: number, pageSize: PageSize, isLandscape = false) {
  if (isLandscape) {
    switch (pageSize) {
      case PageSize.a4:
        return Math.round((width * 8.5) / 11);
      case PageSize.legal:
        return Math.round((width * 8.5) / 14);
      case PageSize.letter:
        return Math.round((width * 8.5) / 11);
    }
  }
  switch (pageSize) {
    case PageSize.a4:
      return Math.round((width * 11) / 8.5);
    case PageSize.legal:
      return Math.round((width * 14) / 8.5);
    case PageSize.letter:
      return Math.round((width * 11) / 8.5);
  }
}

export function allBlockCategories(bottom = false) {
  if (bottom) {
    return [
      Proposal.BlockCategory.text,
      Proposal.BlockCategory.image,
      Proposal.BlockCategory.multimedia,
      Proposal.BlockCategory.list,
      Proposal.BlockCategory.divider,
      Proposal.BlockCategory.pricing,
    ];
  }
  return [
    Proposal.BlockCategory.text,
    Proposal.BlockCategory.image,
    Proposal.BlockCategory.multimedia,
    Proposal.BlockCategory.list,
    Proposal.BlockCategory.divider,
    Proposal.BlockCategory.pricing,
    Proposal.BlockCategory.statement,
    Proposal.BlockCategory.quote,
    Proposal.BlockCategory.gallery,
    Proposal.BlockCategory.chart,
    Proposal.BlockCategory.signer,
  ];
}

export function getStatusLabel(status: ProposalTemplateItemStatus, listing = false) {
  switch (status) {
    case ProposalTemplateItemStatus.publish:
      return listing ? 'Published' : 'Publish ';
    case ProposalTemplateItemStatus.unpublish:
      return listing ? 'Unpublished' : 'Unpublish';
  }
}

export function getTransformedValues(data: ProposalDataType | null): Record<string, string> {
  if (!data) return {};
  return {
    company_name: data.company?.name || 'Company Name',
    brand_name: data.brand?.name || 'Brand Name',
    user_name: data.user ? CommonService.getFullName(data.user) : 'User Name',
    user_email: data.user?.email || 'your@company.com',
    company_address: 'Address',
    company_address2: 'Address 2',
    company_city: 'City',
    company_state: 'State',
    company_country: 'Country',
    company_zip: 'Zip',
    org_name: data.contact?.organization.name || 'Organization Name',
    contact_first_name: data.contact?.firstName || 'Contact First Name',
    contact_last_name: data.contact?.lastName || 'Contact Last Name',
    contact_name: data.contact ? CommonService.getFullName(data.contact) : 'Contact Name',
    contact_email: data.contact?.email || 'Contact Email',
    contact_address: data.contact?.phone || 'Contact Phone',
    contact_address2: 'Address 2',
    contact_city: 'City',
    contact_state: 'State',
    contact_country: 'Country',
    contact_website: 'Website',
    total: Formatter.formatCurrency(data.total, 'USD'),
    link: data.link,
    date_sumitted: Formatter.formatTime(data.dateSumitted),
    date_accepted: data.dateAccepted ? Formatter.formatTime(data.dateAccepted) : '',
  };
}

export function getProposalData(): ProposalDataType {
  const company = {
    id: '0',
    name: 'Your Company',
  };
  const brand = {
    id: '0',
    name: 'Your Brand',
  };

  const contact = {
    id: '0',
    firstName: 'John',
    lastName: 'Doe',
    title: '',
    name: 'John Doe',
    email: 'jon@example.com',
    phone: '(123) 456-7890',
    organization: {
      name: 'ABC Company',
    } as any,
  } as any;

  const user = {
    id: '0',
    firstName: 'Rick',
    lastName: 'Smith',
    email: 'user@company.com',
  };
  return { company, user, brand, contact, total: 0, link: 'https://admin.grandcentr.al/', dateSumitted: toMoment().unix() };
}

export function getAllFontWeight() {
  // list of all font weights from 100 to 100 with their labels
  return [
    { label: 'Thin', value: '100' },
    { label: 'Extra Light', value: '200' },
    { label: 'Light', value: '300' },
    { label: 'Normal', value: '400' },
    { label: 'Medium', value: '500' },
    { label: 'Semi Bold', value: '600' },
    { label: 'Bold', value: '700' },
    { label: 'Extra Bold', value: '800' },
    { label: 'Black', value: '900' },
  ];
}
