import { FieldProps, FormControl, useFormField } from '@core/utils/form';
import { TimeInput, toMoment } from '@core/utils/time';
import FormHelperText from '@mui/material/FormHelperText';

import moment from 'moment';
import React, { Fragment, useCallback } from 'react';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormGroup, Label } from 'reactstrap';
import './form_date.scss';

export interface FormDateInputProps extends FieldProps<FormControl<number> | FormControl<number | null>> {
  options?: Partial<SingleDatePickerShape>;
  minDate?: TimeInput;
  maxDate?: TimeInput;
  onChange?(date: number): void;
  appendToBody?: boolean;
  anchorDirection?: 'left' | 'right';
  withoutGroup?: boolean;
  asterisk?: boolean;
}

export function FormDateInput(props: FormDateInputProps) {
  const { formControl, fieldState, fieldConfig } = useFormField(props);

  const fieldTouched = (fieldState && fieldState.touched) || formControl.inheritedSubmitted || false;
  const fieldValue = fieldState.value ? toMoment(fieldState.value) : null;
  const fieldError = (fieldTouched && fieldState.errorMessage) || null;

  const fieldReadonly = fieldConfig.readonly || false;
  const fieldDisabled = fieldConfig.disabled || false;

  const fieldPlaceholder = fieldConfig.placeholder || 'mm/dd/yyyy';

  const handleChange = useCallback(
    (date: moment.Moment | null) => {
      if (date) {
        formControl.setValue(date.unix());
        props.onChange && props.onChange(date.unix());
      }
    },
    [formControl, props],
  );

  const handleFocus = useCallback(
    (isFocused: boolean) => {
      if (isFocused) {
        formControl.onFocus();
      } else {
        formControl.onBlur();
      }
    },
    [formControl],
  );

  const isDayBlocked = useCallback(
    (m: moment.Moment) => {
      if (props.minDate) {
        return m.isBefore(
          toMoment(props.minDate)
            .startOf('day')
            .subtract(1, 'h'),
        );
      }
      if (props.maxDate) {
        return m.isAfter(
          toMoment(props.minDate)
            .endOf('day')
            .add(1, 'h'),
        );
      }
      return false;
    },
    [props.maxDate, props.minDate],
  );

  const content = (
    <Fragment>
      {fieldConfig.label && (
        <Label for={formControl.uid}>
          {fieldConfig.label} {fieldConfig.required && props.asterisk && <span className="text-danger">*</span>}
        </Label>
      )}

      <SingleDatePicker
        numberOfMonths={1}
        transitionDuration={0}
        inputIconPosition="after"
        showDefaultInputIcon
        isOutsideRange={() => false}
        hideKeyboardShortcutsPanel
        isDayBlocked={isDayBlocked}
        {...props.options}
        readOnly={fieldReadonly}
        disabled={fieldDisabled}
        placeholder={fieldPlaceholder}
        date={fieldValue}
        onDateChange={handleChange}
        focused={formControl.focused}
        onFocusChange={({ focused }) => handleFocus(focused)}
        id={formControl.uid}
        appendToBody={props.appendToBody}
        anchorDirection={props.anchorDirection}
      />
      {!!fieldError && <FormHelperText style={{ color: '#f44336' }}>{fieldError}</FormHelperText>}
    </Fragment>
  );

  if (props.withoutGroup) {
    return <div className="theme_date-picker">{content}</div>;
  }

  return <FormGroup className="theme_date-picker">{content}</FormGroup>;
}
