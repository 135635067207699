import { Subject, BehaviorSubject, of, throwError } from 'rxjs';
import { tap, switchMap, finalize } from 'rxjs/operators';
import { Storage } from '@core/utils/storage';
import { tapError } from '@core/utils/rxjs/operators';
import { AuthAPI } from '@business/api/auth';
import { UserResponse } from '@business/entities/account';

const KEY_TOKEN = 'auth:token';

class SessionManager {
  private readonly _token$ = new BehaviorSubject<string | null>(null);
  private readonly _user$ = new BehaviorSubject<UserResponse | null>(null);

  private readonly _sessionStart$ = new Subject<void>();
  private readonly _sessionEnd$ = new Subject<void>();
  private readonly _loggingOut$ = new Subject<boolean>();

  readonly sessionStart$ = this._sessionStart$.asObservable();
  readonly sessionEnd$ = this._sessionEnd$.asObservable();
  readonly loggingOut$ = this._loggingOut$.asObservable();

  readonly user$ = this._user$.asObservable();

  get token() {
    return this._token$.value;
  }

  get user() {
    return this._user$.value;
  }

  get isAuthenticated() {
    return !!this._token$.value;
  }

  login(data: { email: string; password: string }, remember: boolean) {
    return AuthAPI.login(data.email, data.password, remember).pipe(
      switchMap(result => {
        const token = result.token;
        if (!token) throw new Error('Invalid token');

        return this.restoreByToken(token, remember);
      }),
    );
  }

  logout() {
    return of(null).pipe(
      switchMap(() => {
        this._loggingOut$.next(true);

        return this._endSession();
      }),
      finalize(() => {
        this._loggingOut$.next(false);
      }),
    );
  }

  restore() {
    return of(null).pipe(
      switchMap(() => {
        const token = Storage.get<string>(KEY_TOKEN);
        if (!token) return throwError(new Error('No token'));

        return this.restoreByToken(token, true);
      }),
    );
  }

  restoreByToken(token: string, remember: boolean) {
    return AuthAPI.profile(token).pipe(
      switchMap(({ user }) => {
        if (remember) {
          Storage.set(KEY_TOKEN, token);
        }

        return this._startSession(token, user);
      }),
      tapError(error => {
        console.log('[Session.restoreByToken] error:', error);

        // TODO: show notification
      }),
    );
  }

  private _startSession(token: string, user: UserResponse) {
    return of(null).pipe(
      tap(() => {
        this._token$.next(token);
        this._user$.next(user);

        this._sessionStart$.next();
      }),
    );
  }

  private _endSession() {
    return of(null).pipe(
      tap(() => {
        this._token$.next(null);
        this._user$.next(null);

        Storage.remove(KEY_TOKEN);

        this._sessionEnd$.next();
      }),
    );
  }
}

export const Session = new SessionManager();
