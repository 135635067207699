import React, { memo, useMemo, useState, Fragment } from 'react';
import { Proposal } from '../types';
import { BlockContainer } from '../BlockContainer';
import './index.scss';
import { formGroup, formControl, FormDataType } from '@core/utils/form';
import classNames from 'classnames';
import { defineBlockAdvanceForm } from '../Common/AdvanceSettings';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { useConfigureBlock } from '@modules/proposal/hooks';
import { EmbedBlockSettings } from './EmbedBlockSettings';
import { HTMLService } from '@business/services';
import { Container } from 'reactstrap';

function defineEmbedBlockForm() {
  return formGroup({
    embedCode: formControl<string>({
      initialValue: 'Embed you code here',
    }),
    fullWdith: formControl<boolean>({
      initialValue: true,
      label: 'Full Width',
    }),
    advance: defineBlockAdvanceForm(),
  });
}

export type EmbedWidgetFormData = FormDataType<typeof defineEmbedBlockForm>;
export type EmbedWidgetForm = ReturnType<typeof defineEmbedBlockForm>;

export enum EmbedBlockVariation {
  embed = 'embed',
}

export const EmbedBlock = memo((props: Proposal.BlockProps<EmbedBlockVariation>) => {
  const { variation } = props;
  const form = useMemo(defineEmbedBlockForm, []);
  const formValue = useNonNilObservable(form.value$);

  useConfigureBlock({
    props,
    form,
    contentComponent: EmbedBlockSettings,
    contentProps: { form },
  });

  const content = useMemo(() => {
    if (formValue.fullWdith) return HTMLService.toReact(formValue.embedCode);
    return <Container>{HTMLService.toReact(formValue.embedCode)}</Container>;
  }, [formValue]);

  return (
    <BlockContainer className={classNames('d-flex flex-fill', 'block-embed', variation)} advanceForm={formValue.advance} >
      {content}
    </BlockContainer>
  );
});
