
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { switchMap } from 'rxjs/operators';
import { GlossaryResponse, GlossaryId, GlossaryRequest } from '@business/entities/glossary';
import { GlossaryAPI } from '@business/api/glossary';
import { PageQuery } from '@business/entities/common';

type TaskId = string;

export class GlossaryBloc extends BaseBloc {
  private readonly glossaryRepo = new Repository<GlossaryResponse>({
    getItemId: glossary => glossary.id,
  });

  readonly items$ = this.glossaryRepo.items$;
  readonly selectItem = this.glossaryRepo.selectItem;

  onReset() {
    this.glossaryRepo.reset();
  }

  fetchGlossaries = () => {
    return GlossaryAPI.fetchGlossaries().pipe(this.glossaryRepo.ops.reset(), handleMessage({ error: requestMessage('fetch_help_articles_error') }));
  };

  fetchGlossariesPaginated = (query: PageQuery) => {
    return GlossaryAPI.fetchGlossariesPaginated(query);
  };

  fetchGlossary = (id: TaskId) => {
    return GlossaryAPI.fetchGlossary(id).pipe(
      this.glossaryRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_glossaries_error') }),
    );
  };

  createGlossary = (task: GlossaryRequest) => {
    return GlossaryAPI.createGlossary(task).pipe(
      this.glossaryRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_glossary'),
      }),
    );
  };

  updateGlossary = (id: GlossaryId, data: GlossaryRequest) => {
    return GlossaryAPI.updateGlossary(id, data).pipe(
      this.glossaryRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_glossary'),
      }),
    );
  };

  deleteGlossary = (id: GlossaryId) => {
    return showDeleteConfirmation('Delete Glossary', 'Do you really want to remove this glossary?').pipe(
      switchMap(() => GlossaryAPI.deleteGlossary(id)),
      this.glossaryRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_glossary'),
      }),
    );
  };

  searchGlossary = (search: string) => {
    return GlossaryAPI.searchGlossary(search).pipe(
      this.glossaryRepo.ops.reset(),
      handleMessage({ error: requestMessage('fetch_help_articles_error') }),
    );
  };
}
