import { IdeaAPI } from '@business/api/idea_api';
import { PageQuery } from '@business/entities/common';
import { CompactIdeaCategoryResponse } from '@business/entities/idea';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { R } from '@core/utils/r';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { map, switchMap } from 'rxjs/operators';

export class IdeaCategoryBloc extends BaseBloc {
  private readonly categoryRepo = new Repository<CompactIdeaCategoryResponse>({
    getItemId: item => item.id,
  });

  readonly items$ = this.categoryRepo.items$.pipe(map(categories => R.sortBy(categories, c => c.title)));
  readonly selectItem = this.categoryRepo.selectItem;

  onReset() {
    this.categoryRepo.reset();
  }

  fetchCategories = (query: PageQuery) => {
    return IdeaAPI.fetchCategories(query).pipe(
      this.categoryRepo.ops.reset(),
      handleMessage({ error: requestMessage('fetch_help_article_categories_error') }),
    );
  };

  fetchCategory = (id: string) => {
    return IdeaAPI.fetchCategory(id).pipe(
      this.categoryRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_help_article_categories_error') }),
    );
  };

  createCategory = (data: Partial<CompactIdeaCategoryResponse>) => {
    return IdeaAPI.createCategory(data).pipe(
      handleMessage({
        type: requestMessage('create_help_article_category'),
      }),
    );
  };

  updateCategory = (id: string, data: Partial<CompactIdeaCategoryResponse>) => {
    return IdeaAPI.updateCategory(id, data).pipe(
      this.categoryRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_help_article_category'),
      }),
    );
  };

  deleteCategory = (id: string) => {
    return showDeleteConfirmation('Delete Category', 'Do you really want to remove this category?').pipe(
      switchMap(() => IdeaAPI.deleteCategory(id)),
      this.categoryRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_help_article_category'),
      }),
    );
  };
}
