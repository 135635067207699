import { createContext, useContext, useRef, useEffect } from 'react';

export interface MainPageContextType {
  footer: boolean;
  toolbar: boolean;
  header: boolean;
  setConfig?: React.Dispatch<React.SetStateAction<MainPageContextType>>;
}
export const MainPageContext = createContext<MainPageContextType>({ footer: true, toolbar: true, header: true });

export function useMainPageContext() {
  return useContext(MainPageContext);
}

export function useMainPageConfig(fn: () => Partial<MainPageContextType>) {
  const oldConfig = useMainPageContext();
  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => {
    if (fnRef.current) {
      const config = fnRef.current();
      oldConfig.setConfig && oldConfig.setConfig({ ...oldConfig, ...config });

      return () => oldConfig.setConfig && oldConfig.setConfig({ ...oldConfig, footer: true, toolbar: true, header: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
