import { BehaviorSubject } from 'rxjs';

export const changed$ = new BehaviorSubject<any | null>(null);

export const get = () => {
  return changed$.value;
};

export const set = (settings: any) => {
  changed$.next(settings);
};

export const clear = () => {
  changed$.next(null);
};

export const current = () => {
  return changed$.value;
};
