import { GraphTypes } from '@business/entities/graph';

export function getTypes(type?: GraphTypes) {
  if (type == GraphTypes.daily) {
    return [GraphTypes.daily, GraphTypes.weekly, GraphTypes.monthly, GraphTypes.quarterly, GraphTypes.yearly];
  } else if (type == GraphTypes.weekly) {
    return [GraphTypes.weekly, GraphTypes.monthly, GraphTypes.quarterly, GraphTypes.yearly];
  } else if (type == GraphTypes.monthly) {
    return [GraphTypes.monthly, GraphTypes.quarterly, GraphTypes.yearly];
  } else if (type == GraphTypes.quarterly) {
    return [GraphTypes.quarterly, GraphTypes.yearly];
  } else if (type == GraphTypes.yearly) {
    return [GraphTypes.yearly];
  }
  return [GraphTypes.daily, GraphTypes.weekly, GraphTypes.monthly, GraphTypes.quarterly, GraphTypes.yearly];
}
