import React, { createContext, useContext, memo, useState, useEffect, useCallback } from 'react';
import { R } from '@core/utils/r';

const PageFocusContext = createContext<boolean>(true);

export function usePageFocus() {
  return useContext(PageFocusContext);
}

export const PageFocusProvider = memo(({ children }: any) => {
  const [isFocused, setFocused] = useState(true);

  const handleActivity = useCallback((forcedFlag: any) => {
    if (R.isBoolean(forcedFlag)) {
      return forcedFlag ? setFocused(true) : setFocused(false);
    }

    return document.hidden ? setFocused(false) : setFocused(true);
  }, []);

  useEffect(() => {
    const handleActivityFalse = () => setFocused(false);
    const handleActivityTrue = () => setFocused(true);

    document.addEventListener('visibilitychange', handleActivity);
    document.addEventListener('blur', handleActivityFalse);
    // window.addEventListener('blur', handleActivityFalse);
    // window.addEventListener('focus', handleActivityTrue);
    document.addEventListener('focus', handleActivityTrue);

    return () => {
      //   window.removeEventListener('blur', handleActivity);
      document.removeEventListener('blur', handleActivityFalse);
      //   window.removeEventListener('focus', handleActivityFalse);
      document.removeEventListener('focus', handleActivityTrue);
      document.removeEventListener('visibilitychange', handleActivityTrue);
    };
  }, [handleActivity]);
  return <PageFocusContext.Provider value={isFocused}>{children}</PageFocusContext.Provider>;
});
