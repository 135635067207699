import { PageQuery, Paginate } from '@business/entities/common';
import {
  CreateCustomerRequest,
  CustomerId,
  EditCustomerRequest,
  CustomerResponse,
  VendastaAccount,
  VendastaUserData,
  CreateCustomerClientRequest,
  CustomerClientResponse,
} from '@business/entities/customer';
import { R } from '@core/utils/r';
import { Endpoint } from '../endpoint';

export function fetchCustomers(params: PageQuery) {
  return Endpoint.get<Paginate<CustomerResponse>>(`@gc/customers`, { params: R.objectCompact(params) });
}

export function fetchCustomer(id: CustomerId) {
  return Endpoint.get<CustomerResponse>(`@gc/customers/customer/${id}`, {});
}

export function createCustomer(data: CreateCustomerRequest) {
  return Endpoint.post<void>(`@gc/customers`, { data });
}

export function updateCustomer(id: CustomerId, data: Partial<EditCustomerRequest>) {
  return Endpoint.patch<CustomerResponse>(`@gc/customers/${id}`, { data });
}

export function updateLogo(id: CustomerId, file: File) {
  const formData = new FormData();
  formData.append('file', file);

  return Endpoint.post<CustomerResponse>(`@gc/customers/logo/${id}`, { formData, hasFiles: true });
}

export function deleteCustomer(id: CustomerId) {
  return Endpoint.delete<void>(`@gc/customers/${id}`);
}

export function fetchVendastaAccounts() {
  return Endpoint.get<VendastaAccount[]>(`@gc/customers/vendasta`);
}

export function fetchVendastaUsers(accountId: string) {
  return Endpoint.get<VendastaUserData[]>(`@gc/customers/vendasta-users`, { params: { accountId } });
}

export function fetchCustomerClients(params: PageQuery, customerId: CustomerId) {
  return Endpoint.get<Paginate<CustomerResponse>>(`@gc/customers/clients/${customerId}`, { params: R.objectCompact(params) });
}

export function createCustomerClients(data: CreateCustomerClientRequest, id: CustomerId) {
  return Endpoint.post<CustomerClientResponse>(`@gc/customers/clients/${id}`, { data });
}

export function fetchAvailableCustomers(customerId: CustomerId) {
  return Endpoint.get<CustomerResponse[]>(`@gc/customers/available/${customerId}`);
}

export function deleteCustomerClient(id: CustomerId) {
  return Endpoint.delete<void>(`@gc/customers/client/${id}`);
}
