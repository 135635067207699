import { DynamicReportAPI } from '@business/api/dynamic_report_api';
import { DynamicReportResponse, ReportCategoryId } from '@business/entities/ReportCategory/ReportCategoryResponse';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { switchMap } from 'rxjs/operators';

export class DynamincReportBloc extends BaseBloc {
  private readonly reportRepo = new Repository<DynamicReportResponse>({
    getItemId: category => category.id,
  });

  readonly items$ = this.reportRepo.items$;
  readonly selectReport = this.reportRepo.selectItem;

  onReset() {
    this.reportRepo.reset();
  }

  fetchReports = (categoryId: ReportCategoryId) => {
    return DynamicReportAPI.fetchReports(categoryId).pipe(
      //   this.reportRepo.ops.upsertMany(item => ({ item })),
      this.reportRepo.ops.reset(),
      handleMessage({ error: requestMessage('fetch_report_error') }),
    );
  };

  fetchReport = (id: ReportCategoryId) => {
    return DynamicReportAPI.fetchReport(id).pipe(
      this.reportRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_report_error') }),
    );
  };

  createReport = (categoryId: ReportCategoryId, data: Partial<DynamicReportResponse>) => {
    return DynamicReportAPI.createReport(categoryId, data).pipe(
      this.reportRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_report'),
      }),
    );
  };

  updateReport = (id: ReportCategoryId, data: Partial<DynamicReportResponse>) => {
    return DynamicReportAPI.updateReport(id, data).pipe(
      this.reportRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_report'),
      }),
    );
  };

  updateReportStatus = (id: ReportCategoryId, data: { published: boolean }) => {
    return DynamicReportAPI.updateReportStatus(id, data).pipe(
      this.reportRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_report'),
      }),
    );
  };

  copyReport = (id: ReportCategoryId, title: string) => {
    return DynamicReportAPI.copyReport(id, title).pipe(
      this.reportRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('copy_report'),
      }),
    );
  };

  deleteReport = (id: ReportCategoryId) => {
    return showDeleteConfirmation('Delete Report', 'Do you really want to remove this report?').pipe(
      switchMap(() => DynamicReportAPI.deleteReport(id)),
      this.reportRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_report'),
      }),
    );
  };

  sortReports = (categoryId: ReportCategoryId, ids: ReportCategoryId[]) => {
    return DynamicReportAPI.sortReports(ids).pipe(
      this.reportRepo.ops.upsertMany(item => ({ item })),
      handleMessage({
        error: requestMessage('update_template_section_sort'),
      }),
    );
  };
}
