import { ActivityModule, LogResponse } from '@business/entities/log';
import { LogService } from '@business/services';
import { R } from '@core/utils/r';
import { HTMLService } from '../html_service';

export function getLogColor(type: 'create' | 'update' | 'delete') {
  if (type === 'create') {
    return 'success';
  } else if (type === 'update') {
    return 'info';
  } else if (type === 'delete') {
    return 'danger';
  }
}

export function modules() {
  return R.sortBy(
    [
      ActivityModule.ACCOUNT,
      ActivityModule.PROJECTS,
      ActivityModule.CONTACT,
      ActivityModule.BILLING,
      ActivityModule.TICKETING,
      ActivityModule.SUBSCRIPTIONS,
      ActivityModule.ORDER,
    ],
    m => m,
  );
}
export function getIcon(type: string) {
  const icon = LogService.getIconSwitch(type);
  if (icon) {
    return icon;
  }

  if (R.includes(['card.processed', 'card.failed', 'card.pending', 'card.succeeded', 'card.captured'], type)) {
    return 'bx bx-credit-card';
  }
}

export function getIconSwitch(type: string) {
  switch (type) {
    case 'order.created':
      return 'bx bx-cart';
    case 'order.updated':
      return 'bx bx-cart';
    case 'organization.created':
      return 'bx bx-building';
    case 'organization.deleted':
      return 'bx bx-building';
    case 'organization.updated':
      return 'bx bx-building';
    case 'deal.created':
      return 'bx bx-dollar-circle';
    case 'deal.updated':
      return 'bx bx-dollar-circle';
    case 'form.created':
      return 'bx bx-file';
    case 'form.updated':
      return 'bx bx-file';
    case 'paymentmethod.created':
      return 'bx bx-credit-card';
    case 'paymentmethod.deleted':
      return 'bx bx-credit-card';
    case 'project.created':
      return 'fas fa-project-diagram';
    case 'project.updated':
      return 'fas fa-project-diagram';
    case 'project.deleted':
      return 'fas fa-project-diagram';
    case 'notes.created':
      return 'bx bx-envelope';
    case 'email.created':
      return 'bx bx-envelope';
    case 'project.charged':
      return 'bx bx-credit-card';
    case 'card.processed':
      return 'bx bx-credit-card';
    case 'project.invoiced':
      return 'bx bx-send';
    case 'subscription.created':
      return 'bx bx-repeat';
    case 'subscription.updated':
      return 'bx bx-repeat';
    case 'subscription.canceled':
      return 'bx bx-repeat';
    case 'subscription.updated':
      return 'bx bx-repeat';
    case 'ticket.created':
      return 'bx bx-support';
    case 'ticket.updated':
      return 'bx bx-support';
    case 'timelog.created':
      return 'bx bx-time';
    case 'user.logged':
      return 'bx bx-user';
    case 'call.create':
      return 'bx bx-phone-call';
    case 'marketing_setup_checklist.completed':
      return 'bx bx-check-circle';
    case 'marketing_monthly_checklist.completed':
      return 'bx bx-check-circle';
    case 'marketing_monthly_checklist.feedback.happy':
      return 'far fa-smile';
    case 'marketing_monthly_checklist.feedback.neutral':
      return 'far fa-frown';
    case 'marketing_monthly_checklist.feedback.sad':
      return 'far fa-meh';
    case 'files.created':
      return 'bx bx-file';

    default:
      break;
  }
}
export function getJSX(text: string, item: LogResponse) {
  // TODO: Revise this function
  let newText = text;
  item.bolds.forEach(bold => {
    if (!R.isEmpty(R.toString(bold).trim())) {
      newText = R.replaceAll(newText, bold, `<span style="font-weight: 500">${bold}</span>`);
    }
  });
  item.links.forEach(link => {
    if (!R.isEmpty(R.toString(link.text).trim())) {
      newText = R.replaceAll(newText, link.text, `<a href="${link.link}">${link.text}</a>`);
    }
  });
  return HTMLService.toReact(newText);
}
