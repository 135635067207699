import React, { memo, useMemo, useCallback } from 'react';
import { classnames } from '@core/utils/css';
import { useProposalContext } from '../../ProposalContext';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { Proposal } from '@modules/proposal/Blocks/types';
import { SortableSectionItem } from './SectionItem';
import { SortableList } from '@modules/common/Sortable';
import './index.scss';
import { SortEnd } from 'react-sortable-hoc';
import { R } from '@core/utils/r';
import ScrollBar from 'react-perfect-scrollbar';
import { ProposalBlocsHandle } from '../ProposalPage';
interface ProposalSectionsProps {
  moveTo(index: number): void;
  handleRef: React.RefObject<ProposalBlocsHandle>;
}
export const ProposalSections = memo((props: ProposalSectionsProps) => {
  const { moveTo, handleRef } = props;
  const { sidebar, form } = useProposalContext()!;
  const formValue = useNonNilObservable(form.value$);

  const onSortEnd = useCallback(
    (sort: SortEnd) => {
      const oldIndex = sort.oldIndex;
      const newIndex = sort.newIndex;
      if (oldIndex === newIndex) {
        return;
      }

      const item = formValue.blocks[oldIndex]!;
      if (item.isSection) {
        const nextSection = formValue.blocks.find(b => b.menuOrder > item.menuOrder && b.isSection);
        const applicableBlocks = formValue.blocks.filter(b => b.menuOrder >= item.menuOrder && b.menuOrder < (nextSection?.menuOrder || 999999));
        let newItems = [...formValue.blocks];

        const itemOnNewIndex = newItems[newIndex];

        const movedAtSection = formValue.blocks.find(
          b => (itemOnNewIndex.isSection ? b.menuOrder >= newIndex : b.menuOrder > newIndex) && b.isSection,
        );

        let actualMoveIndex = newIndex;
        if (movedAtSection) {
          actualMoveIndex = movedAtSection.menuOrder - 1;
          if (actualMoveIndex < -1) {
            actualMoveIndex = 0;
          }
        } else {
          actualMoveIndex = newItems.length - 1;
        }
        newItems = R.moveItems(newItems, oldIndex, actualMoveIndex, applicableBlocks.length);
        newItems = newItems.map((b, i) => ({ ...b, menuOrder: i }));

        form.patchValue({
          blocks: newItems,
        });
      } else {
        let newItems = [...formValue.blocks];
        newItems = R.move(newItems, oldIndex, newIndex);
        newItems = newItems.map((b, i) => ({ ...b, menuOrder: i }));
        form.patchValue({
          blocks: newItems,
        });
      }
      moveTo(newIndex);
    },
    [formValue.blocks, form, moveTo],
  );

  return (
    <div className={classnames('proposal-sections', { open: sidebar })}>
      <ScrollBar style={{ height: '100%', overflow: 'hidden' }}>
        <SortableList lockAxis="y" distance={2} onSortEnd={sort => onSortEnd(sort)} useDragHandle={true} helperClass="z5000">
          {formValue.blocks.map((bloc, index) => {
            return (
              <SortableSectionItem
                index={index}
                key={index}
                block={bloc}
                blockForm={form.controls.blocks.items[index]}
                onClick={() => moveTo(bloc.menuOrder)}
                onSettings={() => handleRef.current?.openSettings(index)}
              />
            );
          })}
        </SortableList>
      </ScrollBar>
    </div>
  );
});
