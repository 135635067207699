import { BaseResponse } from './../common/BaseResponse';
import { ProposalFormData } from '@modules/proposal/ProposalBuilderPage';

export type FolderId = string;
export type ItemId = string;

export interface ProposalTemplateFolderBreadcrumbResponse {
  id: string;
  name: string;
}

export interface ProposalTemplateItemsResponse {
  breadcrumbs: ProposalTemplateFolderBreadcrumbResponse[];
  items: (ProposalTemplateResponse | ProposalTemplateItemResponse)[];
}

export interface ProposalTemplateResponse extends BaseResponse {
  id: FolderId;
  name: string;
  type: ProposalTemplateItemType;
  items: number;
  parent?: ProposalTemplateResponse[];
  breadcrumbs?: ProposalTemplateFolderBreadcrumbResponse[];
  parentFolderId: FolderId;
  data?: ProposalFormData;
  liveData?: ProposalFormData;
}

export interface ProposalTemplateItemResponse extends BaseResponse {
  id: ItemId;
  name: string;
  image?: string | File;
  type: ProposalTemplateItemType;
  folder?: ProposalTemplateResponse[];
  parentFolderId: FolderId;
}

export enum ProposalTemplateItemStatus {
  publish = 'publish',
  unpublish = 'unpublish',
}

export enum ProposalTemplateItemShareType {
  item = 'item',
  folder = 'folder',
}

export const ProposalTemplateItemStatuses = [ProposalTemplateItemStatus.publish, ProposalTemplateItemStatus.unpublish];

export interface CompactProposalTemplateItemResponse extends BaseResponse {
  status: any;
  id: ItemId;
  name: string;
  type: ProposalTemplateItemType;
  parentFolderId: FolderId;
  filePath: string;
  image?: string | File;
}

export enum ProposalTemplateItemType {
  folder = 'folder',
  template = 'template',
}

export interface CompactProposalTemplateFolderResponse {
  id: FolderId;
  name: string;
}

export const ProposalTemplateItemTypes = [ProposalTemplateItemType.folder, ProposalTemplateItemType.template];

export const ProposalTemplateItemTypeIcon: Record<ProposalTemplateItemType, string> = {
  folder: 'bx bx-folder',
  template: 'bx bx-file',
};

export const ProposalTemplateItemTypeColor: Record<ProposalTemplateItemType, string> = {
  folder: 'primary',
  template: 'secondary',
};

export interface CompactProposalTemplateSearchResponse {
  id: FolderId;
  name: string;
  type: ProposalTemplateItemType;
  parentFolderId: FolderId;
  breadcrumbs?: ProposalTemplateFolderBreadcrumbResponse[];
}

export interface FolderBreadcrumbResponse {
  id: string;
  name: string;
}

export interface FolderStructureResponse {
  id: string;
  name: string;
  folders: FolderStructureResponse[];
  breadcrumbs?: FolderBreadcrumbResponse[];
}

export interface ProposalTemplateFolderStructureResponse {
  id: string;
  name: string;
  folders: FolderStructureResponse[];
  breadcrumbs?: FolderBreadcrumbResponse[];
}
