import { HelpArticleResponse } from '@business/entities/helpArticle';
import { IntegrationResponse } from '@business/entities/integration';
import { Endpoint } from '../endpoint';

export function fetchIntegrations() {
  return Endpoint.get<IntegrationResponse[]>(`@gc/integrations`, {});
}

export function fetchIntegration(id: string) {
  return Endpoint.get<IntegrationResponse>(`@gc/integrations/integration/${id}`, {});
}

export function updateIntegration(id: string, data: Partial<IntegrationResponse>) {
  return Endpoint.patch<IntegrationResponse>(`@gc/integrations/${id}`, { data });
}

export function fetchAllHelpArticles() {
  return Endpoint.get<HelpArticleResponse[]>(`@gc/integrations/articles`, {});
}
