import { configureForm } from './configure';
import { resolveMessage } from './form_messages';

export * from './form_builder';
export * from './validation';
export * from './hooks';
export * from './configure';

configureForm({
  resolveValidationMessage: resolveMessage,
});
