import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Styles } from '@core/utils/css';
import { Config } from '@config';

export default class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    if (!Config.IS_DEV) {
      // Bugsnag.notify(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div className="account-pages my-5 pt-5">
            <Container>
              <Row>
                <Col lg="12">
                  <div className="text-center mb-5">
                    <h1 className="display-2 font-weight-medium">
                      5<i className="bx bx-buoy bx-spin text-primary display-3" />0
                    </h1>
                    <h4 className="text-uppercase">Internal Server Error</h4>
                    <div className="mt-5 text-center">
                      <a className="btn btn-primary waves-effect waves-light" href="/">
                        Back to Home
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="8" xl="6">
                  <div>
                    <img src={Styles.assets('images/error-img.png')} alt="" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}
