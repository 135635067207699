import { FieldProps, FormControl, useFormField } from '@core/utils/form';
import { R } from '@core/utils/r';
import React, { CSSProperties, memo, useMemo, ReactNode } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export type FormCheckboxProps = FieldProps<FormControl<boolean>> & {
  onChange?(value: boolean): void;
  noPadding?: boolean;
  link?: string | null;
  description?: string;
  container?: string;
  style?: CSSProperties;
  children?: ReactNode;
  noClasses?: boolean;
};

export const FormCheckbox = memo((props: FormCheckboxProps) => {
  const { formControl, fieldState, fieldConfig } = useFormField(props);

  const fieldValue = fieldState.value || false;

  const fieldReadonly = fieldConfig.readonly || false;
  const fieldDisabled = fieldConfig.disabled || false;
  const fieldTouched = (fieldState && fieldState.touched) || formControl.inheritedSubmitted || false;
  const fieldError = (fieldTouched && fieldState.errorMessage) || null;

  const hasLink = useMemo(() => {
    return !R.isEmpty(props.link);
  }, [props.link]);

  const uid = useMemo(() => R.random(), []);
  const classes = useMemo(() => (props.noClasses ? '' : `custom-control custom-checkbox custom-checkbox-primary ${props.noPadding ? '' : 'mb-3'}`), [
    props.noPadding,
    props.noClasses,
  ]);

  return (
    <React.Fragment>
      <div className={classes} style={props.style}>
        <input
          id={formControl.uid}
          type="checkbox"
          className={props.noClasses ? '' : 'custom-control-input'}
          checked={fieldValue}
          onChange={event => {
            formControl.setValue(event.target.checked);
            props.onChange && props.onChange(event.target.checked);
          }}
          onFocus={() => formControl.onFocus()}
          onBlur={() => formControl.onBlur()}
          disabled={fieldDisabled}
          readOnly={fieldReadonly}
        />

        <label className={`custom-control-label ${fieldError ? 'text-danger' : ''}`} htmlFor={formControl.uid}>
          {!hasLink && fieldConfig.label}
          {props.children}
        </label>

        {!!props.description && (
          <span className="text-primary ml-2 hover-pointer" id={`tt-${uid}`}>
            <i className="fa fa-question-circle font-size-16" />
            <UncontrolledTooltip container={props.container} placement="top" target={`tt-${uid}`}>
              {props.description}
            </UncontrolledTooltip>
          </span>
        )}
      </div>

      {hasLink && (
        <a href={props.link!} className="1custom-control-label" target="_blank" rel="noopener noreferrer">
          {fieldConfig.label}
        </a>
      )}
    </React.Fragment>
  );
});

export interface FormCheckboxGrupedProps<V = any> {
  value: V;
  label?: React.ReactNode;
  disabled?: boolean;
  onPress?(value: V): void;
  noPadding?: boolean;
}

function FormCheckboxComponent<V = any>(props: FormCheckboxGrupedProps<V>) {
  const context = React.useContext(Context);
  const { formControl, fieldState, fieldConfig } = useFormField(context && context.props!);

  const onPress = React.useCallback(() => {
    const currentValue = (fieldState.value || []) as string[];
    const newValue = !currentValue.includes(R.toString(props.value))
      ? [...currentValue, props.value]
      : currentValue.filter(v => v !== R.toString(props.value));
    formControl.setValue(newValue);
    if (props.onPress) props.onPress(props.value);
  }, [formControl, props, fieldState.value]);

  const active = fieldState && fieldState.value && (fieldState.value as string[]).includes(R.toString(props.value));

  const disabled = fieldConfig.disabled || props.disabled || false;
  const error = !!fieldState.errors;

  return (
    <React.Fragment>
      <div className={`custom-control custom-checkbox custom-checkbox-primary ${props.noPadding ? '' : 'mb-3'}`}>
        <input
          id={`${formControl.uid}-${props.value}`}
          type="checkbox"
          className="custom-control-input"
          checked={active}
          onChange={onPress}
          onFocus={() => formControl.onFocus()}
          onBlur={() => formControl.onBlur()}
          disabled={disabled}
        />

        <label className="custom-control-label" htmlFor={`${formControl.uid}-${props.value}`}>
          {props.label}
        </label>
      </div>
    </React.Fragment>
  );
}

interface ContextData<T extends any> {
  props: FormCheckboxGroupProps<T> | null;
}

const Context = React.createContext<ContextData<any>>({ props: null });

export interface FormCheckboxGroupProps<V = any> extends FieldProps<FormControl<V>> {
  children?: React.ReactNode;
}

function FormCheckboxGroupComponent<V = any>(props: FormCheckboxGroupProps<V>) {
  return <Context.Provider value={{ props }}>{props.children}</Context.Provider>;
}

export class FormCheckboxGruped<V = any> extends React.PureComponent<FormCheckboxGrupedProps<V>> {
  public render() {
    return <FormCheckboxComponent {...this.props} />;
  }
}

export class FormCheckboxGroup<V = any> extends React.PureComponent<FormCheckboxGroupProps<V>> {
  public render() {
    return <FormCheckboxGroupComponent {...this.props} />;
  }
}
