import { CannedResponseAPI } from '@business/api/canned';
import { CannedId, CannedResponse, CannedResponseType } from '@business/entities/canned';
import { ChannelId } from '@business/entities/SupportChannel';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { switchMap } from 'rxjs/operators';

export class CannedBloc extends BaseBloc {
  private readonly cannedRepo = new Repository<CannedResponse>({
    getItemId: response => response.id,
  });

  readonly responses$ = this.cannedRepo.items$;

  onReset() {
    this.cannedRepo.reset();
  }

  fetchResponses = (search: string, channelId?: ChannelId, type?: CannedResponseType) => {
    this.cannedRepo.reset();
    return CannedResponseAPI.fetchResponses(search, channelId, type).pipe(
      this.cannedRepo.ops.reset(),
      handleMessage({ error: requestMessage('fetch_canned_response_error') }),
    );
  };

  createCanned = (data: Partial<CannedResponse>) => {
    return CannedResponseAPI.createCannedResponse(data).pipe(
      this.cannedRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('create_canned'),
      }),
    );
  };

  updateCanned = (id: CannedId, data: Partial<CannedResponse>) => {
    return CannedResponseAPI.updateCanned(id, data).pipe(
      this.cannedRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_canned'),
      }),
    );
  };

  deleteCaned = (id: CannedId) => {
    return showDeleteConfirmation('Delete Canned Response', 'Do you really want to remove this canned response?').pipe(
      switchMap(() => CannedResponseAPI.deleteCanned(id)),
      this.cannedRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_canned'),
      }),
    );
  };
}
