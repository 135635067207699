import { BehaviorSubject } from 'rxjs';
import { Storage } from '../storage';

const APP_CONFIG_KEY = 'config:app';

export interface Data {
  companyId: string | null;
  appId: string | null;
}

const defaultConfig: Data = { companyId: null, appId: null };

export const changed$ = new BehaviorSubject<Data>(defaultConfig);

export const get = () => {
  const config = Storage.get<Data>(APP_CONFIG_KEY);
  if (config) {
    changed$.next(config);
  }
  return config || defaultConfig;
};

export const set = (newConfig: Partial<Data>) => {
  const config = Storage.get<Data>(APP_CONFIG_KEY);
  const updated: Data = { ...(config ? config : defaultConfig), ...newConfig };
  changed$.next(updated);
  Storage.set(APP_CONFIG_KEY, updated);
};

export const clear = () => {
  changed$.next(defaultConfig);
  return Storage.remove(APP_CONFIG_KEY);
};

export const current = () => {
  return changed$.value;
};
