import React, { memo } from 'react';

export interface TableColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  flex?: number;
  maxWidth?: number;
  width?: number;
}

export const TableColumn = memo((props: TableColumnProps) => {
  const { flex = 1, maxWidth, children, width, style = {}, ...rest } = props;
  return (
    <div {...rest} style={{ ...style, maxWidth, width, flex, padding: '0px 5px 0px 5px' }}>
      {children}
    </div>
  );
});
