import React, { memo, useCallback, useState, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { SignerWidgetForm } from './SignerBlock';
import { useNonNilObservable } from '@core/utils/hooks/rxjs';
import { UploadAPI } from '@business/api/upload';
import { tap } from 'rxjs/operators';
import { Card, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import classnames from 'classnames';
import { openGenericInputModal } from '@modules/common/GenericInputModal';
import { LoadingIndicator } from '@core/components/LoadingIndicator';
import { SortableList } from '@modules/common/Sortable';
import { SortableElement, SortEnd } from 'react-sortable-hoc';
import { R } from '@core/utils/r';
import { CommonService } from '@business/services';
import { MoreMenu, MoreMenuItem } from '@modules/common/MoreMenu';
import { FormCheckbox } from '@core/components/form';
import { ImagePickerSettings } from '@modules/proposal/ImagePickerSettings';
import { openImageFinderModal } from '@modules/ImageFinder';
import { Button } from '@core/components/Button';
export interface SignerBlockSettingsProps {
  form: SignerWidgetForm;
}

export const SignerBlockSettings = memo((props: SignerBlockSettingsProps) => {
  const { form } = props;
  const [hovered, setHovered] = useState(-1);
  const formValue = useNonNilObservable(form.value$);
  const itemValues = useNonNilObservable(form.controls.items.items$);

  const onSortEnd = useCallback(
    (sort: SortEnd) => {
      const oldIndex = sort.oldIndex;
      const newIndex = sort.newIndex;
      const ids = itemValues.map(s => s);
      const sorted = R.move(ids, oldIndex, newIndex);
      form.patchValue({
        items: sorted.map((q, i) => {
          return {
            ...q.value,
            menuOrder: i,
          };
        }),
      });
    },
    [form, itemValues],
  );

  const updateSignerName = useCallback(
    (index: number, name: string) => {
      form.patchValue({
        items: itemValues.map((q, i) => {
          if (index === i)
            return {
              ...q.value,
              name: name,
            };
          else return q.value;
        }),
      });
    },
    [form, itemValues],
  );

  const editSignerName = useCallback(
    (index: number, name: string) => {
      openGenericInputModal({
        title: 'Update Signer Name',
        label: 'Signer Name',
        button: 'OK',
        initialValue: name,
        onSubmit: name => updateSignerName(index, name),
      });
    },
    [updateSignerName],
  );

  const removeSigner = useCallback(
    (index: number) => {
      const filteredFiles = formValue.items.filter((_, i) => i !== index);
      form.patchValue({ items: filteredFiles });
    },
    [form, formValue.items],
  );

  const addOne = useCallback(() => {
    form.controls.items.push().patchValue({ menuOrder: itemValues.length });
  }, [form, itemValues.length]);

  return (
    <div>
      <Button onClick={() => addOne()} intent="primary">
        <i className="fa fa-plus" /> Add Signer
      </Button>
      <SortableList lockAxis="y" distance={2} onSortEnd={sort => onSortEnd(sort)}>
        {formValue.items.map((obj, i) => (
          <SortableGalleryItem
            key={i}
            index={i}
            ind={i}
            item={obj}
            editSignerName={editSignerName}
            removeSigner={removeSigner}
            isHover={i === hovered}
          />
        ))}
      </SortableList>
    </div>
  );
});

interface MediaFileLineItemProps {
  item: any;
  ind: number;
  isHover?: boolean;
  removeSigner(index: number): void;
  editSignerName(index: number, text: string): void;
}

export const MediaFileLineItem = memo((props: MediaFileLineItemProps) => {
  const { ind, item, removeSigner, editSignerName } = props;
  const [isHover, setHover] = useState(false);

  const menuItems: MoreMenuItem[] = useMemo(
    () => [
      { icon: 'fa fa-pencil-alt', title: 'Edit Alt Tag', onClick: () => editSignerName(ind, item.name) },
      { icon: 'fa fa-trash-alt', className: 'text-danger', title: 'Remove', onClick: () => removeSigner(ind) },
    ],
    [editSignerName, ind, item.name, removeSigner],
  );

  return (
    <>
      <div className="mt-1" key={'file'} style={{ cursor: 'move' }}>
        <Card
          className={classnames(isHover || props.isHover ? 'search-item-hover' : '')}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className="d-flex flex-row align-items-center m-3">
            <div className="d-flex flex-row flex-fill" style={{ padding: '0.25rem' }}>
              <div className="mr-4 align-items-center text-muted justify-content-center d-flex">
                {isHover && <i className="fa fa-ellipsis-v hover-pointer" style={{ fontSize: 22 }}></i>}
              </div>
              <div style={{ width: '95%', marginRight: 10 }}>
                <h4>{item.name}</h4>
              </div>
            </div>
            <div className="d-flex">
              <MoreMenu items={menuItems} vertical />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
});

export const SortableGalleryItem = SortableElement(MediaFileLineItem);
