import React, { memo, CSSProperties } from 'react';

export interface LoadingIndicatorProps {
  style?: CSSProperties;
}

export const LoadingIndicator = memo((props: LoadingIndicatorProps) => {
  return (
    <div className="three-bounce-loader" style={props.style}>
      <div className="one"></div>
      <div className="two"></div>
      <div className="three"></div>
    </div>
  );
});
